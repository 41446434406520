import React from 'react';

function Icon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={'1021'}
      height={'653'}
      viewBox="0 0 1021 653"
      {...props}
    >
      <defs>
        <clipPath id="clip-path">
          <path
            fill="#fff"
            stroke="#707070"
            strokeWidth="1"
            d="M0 0H1021V653H0z"
            data-name="Rectangle 62"
            transform="translate(828.001 9)"
          ></path>
        </clipPath>
        <clipPath id="clip-path-2">
          <path
            fill="none"
            d="M2368.749 746.034s-10.654 60.53-13.41 83.323c-1.13 9.344-6.169 45.674-7.146 62.542.895 2.954 9.248 3.763 9.4 2.02 1.226-14.431 23.4-39.088 22.1-51.373-.292-2.74-1.539-9.678-3.868-13.511 11.493-22.475 23.058-52.068 23.058-52.068l-20.864-44.821z"
            data-name="Path 56215"
            transform="translate(-2348.193 -732.146)"
          ></path>
        </clipPath>
        <radialGradient
          id="radial-gradient"
          cx="-0.18"
          cy="0.499"
          r="0.367"
          gradientTransform="matrix(3.989 .214 -1.36 1.523 -7.944 7.088)"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#224f73"></stop>
          <stop offset="1" stopColor="#2e6c9e"></stop>
        </radialGradient>
        <radialGradient
          id="radial-gradient-2"
          cx="-0.363"
          cy="0.508"
          r="0.363"
          gradientTransform="matrix(5.521 0 0 1.49 1.639 5.713)"
          xlinkHref="#radial-gradient"
        ></radialGradient>
        <radialGradient
          id="radial-gradient-3"
          cx="0.5"
          cy="0.5"
          r="0.637"
          gradientTransform="matrix(.667 0 0 1 .167 0)"
          xlinkHref="#radial-gradient"
        ></radialGradient>
        <radialGradient
          id="radial-gradient-4"
          cx="0.5"
          cy="0.5"
          r="0.632"
          gradientTransform="matrix(.675 0 0 1 .162 0)"
          xlinkHref="#radial-gradient"
        ></radialGradient>
      </defs>
      <g clipPath="url(#clip-path)" transform="translate(-828.001 -9)">
        <g data-name="Group 2614">
          <g transform="translate(1234.319 8.532)">
            <path
              fill="#ede8e6"
              d="M1408.38 96.153c30.562-24.052 89.641-42.977 103.106-35.827s9.248 15.818 16.166 16.86 41.8-15.322 53.32-8.72c10.61 6.083 3.72 24.474-2.829 27.687z"
              data-name="Path 55938"
              transform="translate(-1408.38 -28.615)"
            ></path>
            <path
              fill="#ede8e6"
              d="M1953.62 172.658h317.415c22.221-8.69 31.41-48.119 6.907-53.867-12.363-2.9-68.056 24.611-79.374 18.67-9.373-4.919 2.012-40.825-24.788-40.825-37.832 0-93.786 55.782-118.709 55.782s-31.323-12.625-52.321-13.264-49.13 33.504-49.13 33.504z"
              data-name="Path 55939"
              transform="translate(-1594.618 -41.54)"
            ></path>
            <path
              fill="#f5f0ee"
              d="M1759.226 80.5h281.991c19.741-7.72 27.9-42.749 6.136-47.855-10.983-2.576-60.461 21.864-70.516 16.586-8.327-4.37 1.787-36.269-22.021-36.269-33.61 0-83.32 49.557-105.461 49.557s-27.826-11.216-46.482-11.784-43.647 29.765-43.647 29.765z"
              data-name="Path 55940"
              transform="translate(-1528.219 -12.958)"
            ></path>
            <path
              fill="#f5f0ee"
              d="M2489.438 229.654h177.686c12.439-4.864 17.583-26.937 3.866-30.154-6.921-1.624-38.1 13.776-44.433 10.451-5.247-2.754 1.127-22.853-13.876-22.853-21.178 0-52.5 31.226-66.453 31.226s-17.534-7.068-29.289-7.425-27.501 18.755-27.501 18.755z"
              data-name="Path 55941"
              transform="translate(-1777.638 -72.439)"
            ></path>
            <path
              fill="#ede8e6"
              d="M1766.143 323.74h277.967c11.186-14.1-12.656-54.17-45.756-55.582S1893.271 307.1 1871.445 307.1s-43.889-12.143-62.278-12.7-43.024 29.34-43.024 29.34z"
              data-name="Path 55942"
              transform="translate(-1530.581 -100.115)"
            ></path>
            <path
              fill="#f5f0ee"
              d="M2416.473 66.1h151.684c6.1-7.694-6.906-29.56-24.968-30.331s-57.344 21.251-69.253 21.251-23.95-6.626-33.985-6.931-23.478 16.011-23.478 16.011z"
              data-name="Path 55943"
              transform="translate(-1752.715 -20.743)"
            ></path>
            <path
              fill="#f5f0ee"
              d="M2227.917 333.15H2379.6c6.1-7.694-6.906-29.56-24.968-30.331s-57.344 21.251-69.254 21.251-23.95-6.626-33.985-6.931-23.476 16.011-23.476 16.011z"
              data-name="Path 55944"
              transform="translate(-1688.31 -111.96)"
            ></path>
          </g>
          <g transform="translate(849.456 35.312)">
            <g data-name="Group 2474" transform="translate(224.348 106.878)">
              <path
                fill="#bddbe6"
                d="M1253.083 216.25c-49.39-5.75-111.923 72.541-79.589 181.586 19.044 64.221 78 75.832 111.207 56.64 38.447-22.217 57.364-105.993 31.453-143.881-12.609-18.437-27.961-18.442-33.548-37.984-3.323-11.621 2.594-52.621-29.523-56.361z"
                data-name="Path 55945"
                transform="translate(-1164.597 -215.953)"
              ></path>
              <g data-name="Group 2469" transform="translate(75.118 55.415)">
                <path
                  fill="#f7f6f1"
                  d="M1285.31 616.167a1.677 1.677 0 01-1.509-2.4c19.485-40.6 11.893-102.812 3.854-168.682-5.979-49-12.162-99.668-7.1-143.486a1.675 1.675 0 113.328.384c-5.014 43.422 1.145 93.89 7.1 142.7 8.1 66.369 15.749 129.057-4.16 170.538a1.675 1.675 0 01-1.513.946z"
                  data-name="Path 55946"
                  transform="translate(-1278.684 -300.115)"
                ></path>
              </g>
              <g data-name="Group 2470" transform="translate(26.919 84.967)">
                <path
                  fill="#f7f6f1"
                  d="M1256.7 389.4a1.67 1.67 0 01-1.068-.385l-49.544-41.052a1.675 1.675 0 012.138-2.58l49.544 41.052a1.675 1.675 0 01-1.07 2.965z"
                  data-name="Path 55947"
                  transform="translate(-1205.48 -344.997)"
                ></path>
              </g>
              <g data-name="Group 2471" transform="translate(82.839 132.46)">
                <path
                  fill="#f7f6f1"
                  d="M1292.085 469.385a1.675 1.675 0 01-1.061-2.973l59.909-48.906a1.675 1.675 0 012.119 2.6l-59.91 48.906a1.667 1.667 0 01-1.057.373z"
                  data-name="Path 55948"
                  transform="translate(-1290.409 -417.128)"
                ></path>
              </g>
              <g data-name="Group 2472" transform="translate(49.351 203.501)">
                <path
                  fill="#f7f6f1"
                  d="M1277.754 538.134a1.67 1.67 0 01-.433-.058l-36.528-9.759a1.675 1.675 0 01.865-3.237l36.528 9.759a1.676 1.676 0 01-.432 3.294z"
                  data-name="Path 55949"
                  transform="translate(-1239.549 -525.023)"
                ></path>
              </g>
              <g data-name="Group 2473" transform="translate(121.569 106.46)">
                <path
                  fill="#f7f6f1"
                  d="M1354.191 421.6a1.675 1.675 0 01-1.668-1.54l-3.286-40.606a1.675 1.675 0 013.34-.27l3.286 40.605a1.675 1.675 0 01-1.534 1.8c-.047.011-.092.011-.138.011z"
                  data-name="Path 55950"
                  transform="translate(-1349.232 -377.641)"
                ></path>
              </g>
            </g>
            <g data-name="Group 2480" transform="translate(1005.908 136.07)">
              <path
                fill="#bddbe6"
                d="M2440.09 260.586c-49.39-5.75-111.923 72.541-79.589 181.586 19.043 64.221 78 75.832 111.207 56.64 38.447-22.217 57.364-105.993 31.453-143.881-12.609-18.437-27.961-18.442-33.548-37.984-3.323-11.621 2.598-52.621-29.523-56.361z"
                data-name="Path 55951"
                transform="translate(-2351.604 -260.289)"
              ></path>
              <g data-name="Group 2475" transform="translate(75.118 55.415)">
                <path
                  fill="#f7f6f1"
                  d="M2472.316 660.5a1.677 1.677 0 01-1.509-2.4c19.485-40.6 11.892-102.812 3.854-168.681-5.979-49-12.162-99.669-7.1-143.486a1.675 1.675 0 013.329.384c-5.014 43.422 1.145 93.89 7.1 142.7 8.1 66.369 15.75 129.057-4.159 170.538a1.677 1.677 0 01-1.515.945z"
                  data-name="Path 55952"
                  transform="translate(-2465.691 -344.451)"
                ></path>
              </g>
              <g data-name="Group 2476" transform="translate(26.919 84.967)">
                <path
                  fill="#f7f6f1"
                  d="M2443.706 433.736a1.67 1.67 0 01-1.068-.385l-49.544-41.051a1.675 1.675 0 012.138-2.58l49.544 41.052a1.675 1.675 0 01-1.07 2.965z"
                  data-name="Path 55953"
                  transform="translate(-2392.487 -389.333)"
                ></path>
              </g>
              <g data-name="Group 2477" transform="translate(82.838 132.46)">
                <path
                  fill="#f7f6f1"
                  d="M2479.092 513.721a1.675 1.675 0 01-1.061-2.973l59.909-48.906a1.675 1.675 0 012.119 2.6l-59.909 48.905a1.668 1.668 0 01-1.058.374z"
                  data-name="Path 55954"
                  transform="translate(-2477.416 -461.464)"
                ></path>
              </g>
              <g data-name="Group 2478" transform="translate(49.351 203.501)">
                <path
                  fill="#f7f6f1"
                  d="M2464.761 582.47a1.67 1.67 0 01-.433-.058l-36.528-9.759a1.675 1.675 0 01.865-3.237l36.529 9.759a1.676 1.676 0 01-.432 3.294z"
                  data-name="Path 55955"
                  transform="translate(-2426.556 -569.359)"
                ></path>
              </g>
              <g data-name="Group 2479" transform="translate(121.569 106.46)">
                <path
                  fill="#f7f6f1"
                  d="M2541.2 465.934a1.675 1.675 0 01-1.668-1.54l-3.286-40.606a1.675 1.675 0 013.34-.27l3.285 40.605a1.674 1.674 0 01-1.534 1.8.729.729 0 01-.137.011z"
                  data-name="Path 55956"
                  transform="translate(-2536.239 -421.977)"
                ></path>
              </g>
            </g>
            <g data-name="Group 2485" transform="translate(126.172 180.953)">
              <path
                fill="#bccacd"
                d="M1085.536 328.928c25.71 3.937 49.835 39.31 48.019 82.809s-9.376 46.6-9.084 64.2c.415 24.941 19.465 44.2 17.26 82.019-1.639 28.121-20.893 58.656-55.413 62.7s-66.193-24.779-69.947-53.121c-6.813-51.434 28.16-72.751 28.16-94.862s-12.823-44.613-9.084-91.065c2.435-30.247 21.471-57.063 50.089-52.68z"
                data-name="Path 55957"
                transform="translate(-1015.49 -328.456)"
              ></path>
              <g data-name="Group 2481" transform="translate(53.717 43.516)">
                <path
                  fill="#f7f6f1"
                  d="M1106.564 731a1.677 1.677 0 01-1.6-1.2c-13.267-44.246-7.078-112.156-1.094-177.829 5.546-60.853 10.784-118.332 1.078-155.329a1.675 1.675 0 013.241-.85c9.855 37.565 4.591 95.329-.982 156.483-5.956 65.356-12.114 132.938.966 176.562a1.677 1.677 0 01-1.605 2.157z"
                  data-name="Path 55958"
                  transform="translate(-1097.073 -394.547)"
                ></path>
              </g>
              <g data-name="Group 2482" transform="translate(35.664 65.501)">
                <path
                  fill="#f7f6f1"
                  d="M1102.642 468.067a1.671 1.671 0 01-1.277-.59l-31.31-36.779a1.676 1.676 0 012.552-2.172l31.31 36.779a1.676 1.676 0 01-1.275 2.761z"
                  data-name="Path 55959"
                  transform="translate(-1069.656 -427.937)"
                ></path>
              </g>
              <g data-name="Group 2483" transform="translate(55.045 217.365)">
                <path
                  fill="#ede8e6"
                  d="M1102.11 693.235a3.016 3.016 0 01-1.424-5.676l53.547-28.619a3.015 3.015 0 012.843 5.319l-53.547 28.619a3 3 0 01-1.419.357z"
                  data-name="Path 55960"
                  transform="translate(-1099.091 -658.583)"
                ></path>
              </g>
              <g data-name="Group 2484" transform="translate(21.355 197.752)">
                <path
                  fill="#f7f6f1"
                  d="M1084.628 654.939a1.673 1.673 0 01-.912-.271l-35.03-22.792a1.675 1.675 0 011.827-2.809l35.03 22.792a1.675 1.675 0 01-.915 3.08z"
                  data-name="Path 55961"
                  transform="translate(-1047.924 -628.796)"
                ></path>
              </g>
            </g>
            <g data-name="Group 2490" transform="translate(647.336 126.949)">
              <path
                fill="#bccacd"
                d="M1877.06 246.908c25.711 3.938 49.836 39.31 48.019 82.809s-9.376 46.6-9.084 64.2c.415 24.94 19.464 44.2 17.26 82.019-1.64 28.121-20.893 58.656-55.413 62.7s-66.193-24.779-69.947-53.121c-6.813-51.435 28.16-72.751 28.16-94.862s-12.824-44.614-9.084-91.065c2.436-30.247 21.472-57.063 50.089-52.68z"
                data-name="Path 55962"
                transform="translate(-1807.015 -246.436)"
              ></path>
              <g data-name="Group 2486" transform="translate(53.717 43.516)">
                <path
                  fill="#f7f6f1"
                  d="M1898.089 648.981a1.677 1.677 0 01-1.6-1.2c-13.267-44.246-7.078-112.156-1.094-177.829 5.546-60.853 10.784-118.332 1.078-155.329a1.675 1.675 0 013.241-.85c9.855 37.565 4.591 95.328-.982 156.483-5.956 65.356-12.115 132.937.966 176.562a1.679 1.679 0 01-1.605 2.157z"
                  data-name="Path 55963"
                  transform="translate(-1888.598 -312.528)"
                ></path>
              </g>
              <g data-name="Group 2487" transform="translate(35.664 65.501)">
                <path
                  fill="#f7f6f1"
                  d="M1894.167 386.047a1.673 1.673 0 01-1.277-.589l-31.31-36.779a1.675 1.675 0 112.552-2.171l31.31 36.779a1.675 1.675 0 01-1.275 2.761z"
                  data-name="Path 55964"
                  transform="translate(-1861.181 -345.917)"
                ></path>
              </g>
              <g data-name="Group 2488" transform="translate(55.045 217.366)">
                <path
                  fill="#ede8e6"
                  d="M1893.635 611.215a3.016 3.016 0 01-1.424-5.676l53.547-28.618a3.015 3.015 0 012.843 5.319l-53.548 28.619a3 3 0 01-1.418.356z"
                  data-name="Path 55965"
                  transform="translate(-1890.616 -576.564)"
                ></path>
              </g>
              <g data-name="Group 2489" transform="translate(21.355 197.753)">
                <path
                  fill="#f7f6f1"
                  d="M1876.153 572.919a1.673 1.673 0 01-.912-.271l-35.03-22.792a1.675 1.675 0 011.827-2.809l35.03 22.792a1.675 1.675 0 01-.915 3.079z"
                  data-name="Path 55966"
                  transform="translate(-1839.449 -546.777)"
                ></path>
              </g>
            </g>
            <g data-name="Group 2501" transform="translate(0 69.833)">
              <path
                fill="#97c4de"
                d="M916.667 163.274c-45.719 18.287-33.48 69.383-44.356 91.134-7.925 15.85-48.447 41.671-48.447 80.334 0 34.4 34.087 64.531 37.191 81.674s-32.625 34.315-32.625 79.33c0 39.758 95.653 60.01 95.653 60.01s79.63-47.342 81.709-81.008-20.954-55.229-26.839-66.18 26.267-43.217 26.834-73.826c.512-27.616-36.115-52.019-39.439-64.693-2.434-9.28 5.81-35.624 5.19-62.285-.738-31.885-21.504-57.837-54.871-44.49z"
                data-name="Path 55967"
                transform="translate(-823.864 -159.69)"
              ></path>
              <g data-name="Group 2491" transform="translate(89.754 64.745)">
                <path
                  fill="#f7f6f1"
                  d="M976.619 679.3a1.675 1.675 0 01-1.668-1.541c-1.38-17.219-4.857-67.364-5.981-88.329-.234-4.354-.76-11.305-1.427-20.1-4.507-59.508-15.062-198.864 2.074-309.886a1.675 1.675 0 013.311.512c-17.078 110.641-6.543 249.728-2.044 309.121.668 8.82 1.2 15.786 1.431 20.178 1.123 20.943 4.6 71.037 5.975 88.239a1.677 1.677 0 01-1.537 1.8c-.044.006-.088.006-.134.006z"
                  data-name="Path 55968"
                  transform="translate(-960.179 -258.022)"
                ></path>
                <path
                  fill="#97c4de"
                  d="M977.858 694.083a1.675 1.675 0 01-1.668-1.541c-1.38-17.219-6.1-82.146-7.22-103.111a372.87 372.87 0 00-.352-5.574c-.254-3.69 3.128-3.4 3.427 1 .1 1.483.2 3.056.271 4.391 1.123 20.943 5.835 85.819 7.214 103.021a1.677 1.677 0 01-1.537 1.8.53.53 0 01-.135.014z"
                  data-name="Path 56402"
                  transform="translate(-960.179 -253.022)"
                ></path>
              </g>
              <g data-name="Group 2492" transform="translate(90.118 153.124)">
                <path
                  fill="#f7f6f1"
                  d="M962.409 421.956a1.676 1.676 0 01-.73-3.184l54.535-26.356a1.675 1.675 0 111.458 3.017l-54.535 26.356a1.673 1.673 0 01-.728.167z"
                  data-name="Path 55969"
                  transform="translate(-960.732 -392.249)"
                ></path>
              </g>
              <g data-name="Group 2493" transform="translate(17.11 173.377)">
                <path
                  fill="#f7f6f1"
                  d="M924.532 479.75a1.664 1.664 0 01-.988-.323l-73.008-53.391a1.675 1.675 0 011.977-2.7l73.008 53.392a1.675 1.675 0 01-.99 3.027z"
                  data-name="Path 55970"
                  transform="translate(-849.85 -423.008)"
                ></path>
              </g>
              <g data-name="Group 2494" transform="translate(47.32 158.647)">
                <path
                  fill="#f7f6f1"
                  d="M897.408 440.81a1.675 1.675 0 01-1.676-1.675v-36.822a1.675 1.675 0 013.351 0v36.822a1.675 1.675 0 01-1.675 1.675z"
                  data-name="Path 55971"
                  transform="translate(-895.732 -400.638)"
                ></path>
              </g>
              <g data-name="Group 2495" transform="translate(126.589 306.602)">
                <path
                  fill="#f7f6f1"
                  d="M1047.587 650.879a1.673 1.673 0 01-1-.331l-29.79-22.182a1.675 1.675 0 112-2.687l29.791 22.182a1.675 1.675 0 01-1 3.018z"
                  data-name="Path 55972"
                  transform="translate(-1016.123 -625.346)"
                ></path>
              </g>
              <g data-name="Group 2496" transform="translate(28.842 329.746)">
                <path
                  fill="#f7f6f1"
                  d="M936.546 694.1a1.669 1.669 0 01-.687-.149l-67.2-30.251a1.675 1.675 0 011.375-3.055l67.2 30.252a1.675 1.675 0 01-.689 3.2z"
                  data-name="Path 55973"
                  transform="translate(-867.668 -660.498)"
                ></path>
              </g>
              <g data-name="Group 2497" transform="translate(50.345 299.273)">
                <path
                  fill="#f7f6f1"
                  d="M909.4 661.049a1.675 1.675 0 01-1.649-1.395l-7.4-43.484a1.675 1.675 0 113.3-.561l7.4 43.483a1.675 1.675 0 01-1.371 1.932 1.723 1.723 0 01-.28.025z"
                  data-name="Path 55974"
                  transform="translate(-900.326 -614.215)"
                ></path>
              </g>
              <g data-name="Group 2498" transform="translate(31.94 340.915)">
                <path
                  fill="#f7f6f1"
                  d="M874.051 690.016a1.675 1.675 0 01-.863-3.113l15.38-9.206a1.676 1.676 0 011.721 2.875l-15.38 9.206a1.665 1.665 0 01-.858.238z"
                  data-name="Path 55975"
                  transform="translate(-872.374 -677.46)"
                ></path>
              </g>
              <g data-name="Group 2499" transform="translate(95.2 78.212)">
                <path
                  fill="#f7f6f1"
                  d="M970.127 298.99a1.675 1.675 0 01-1.026-3l22.186-17.164a1.675 1.675 0 112.05 2.65l-22.186 17.164a1.665 1.665 0 01-1.024.35z"
                  data-name="Path 55976"
                  transform="translate(-968.451 -278.475)"
                ></path>
              </g>
              <g data-name="Group 2500" transform="translate(93.42 288.476)">
                <path
                  fill="#f7f6f1"
                  d="M967.424 633.707a1.676 1.676 0 01-.771-3.164l62.96-32.543a1.675 1.675 0 111.538 2.976l-62.959 32.538a1.671 1.671 0 01-.768.193z"
                  data-name="Path 55977"
                  transform="translate(-965.747 -597.818)"
                ></path>
              </g>
            </g>
            <g data-name="Group 2503" transform="translate(810.595)">
              <path
                fill="#ede8e6"
                d="M2101.183 77.165c-52.045 60.471-19.015 164.251-21.411 202.484s-42.255 82.57-15.735 122.114 68.46 50.328 80.443 57.518 71.9-40.208 76.691-89.606-51.527-99.824-51.527-127.336 45.688-115.632 17.974-165.176c-19.426-34.725-62.472-27.841-86.435.002z"
                data-name="Path 55978"
                transform="translate(-2054.969 -53.631)"
              ></path>
              <g data-name="Group 2502" transform="translate(63.013 56.95)">
                <path
                  fill="#f7f6f1"
                  d="M2153.092 650.4a2.477 2.477 0 01-.563-.066 2.419 2.419 0 01-1.792-2.914c.255-1.069 25.47-107.56 24.013-158.528-.4-14.013-1.986-34.049-3.994-59.415-5.4-68.24-13.563-171.363-8.9-287.034a2.418 2.418 0 014.833.195c-4.651 115.382 3.5 218.334 8.889 286.458 2.013 25.434 3.6 45.523 4.007 59.658 1.475 51.6-23.887 158.712-24.143 159.788a2.419 2.419 0 01-2.35 1.858z"
                  data-name="Path 55979"
                  transform="translate(-2150.671 -140.125)"
                ></path>
              </g>
            </g>
          </g>
          <g transform="translate(943.539 103.905)">
            <g data-name="Group 2510" transform="translate(0 232.733)">
              <g data-name="Group 2504" transform="translate(26.392 21.854)">
                <path
                  fill="#f7f6f1"
                  d="M0 0H254.121V248.387H0z"
                  data-name="Rectangle 10"
                  transform="translate(1.557 1.556)"
                ></path>
                <path
                  fill="#97c4de"
                  d="M1196.915 546.023l-.185-1.557h-115.452c-.035.517-.07 1.037-.1 1.557h-3.356l-.107 1.555h173.1c.1-.134.193-.271.287-.408.15-.212.3-.426.448-.641.115-.166.23-.338.346-.506zm64.043 105.713v10.231h1.557v-10.23zm0 0v10.231h1.557v-10.23zm-64.043-105.713l-.185-1.557h-115.452c-.035.517-.07 1.037-.1 1.557h-3.356l-.107 1.555h173.1c.1-.134.193-.271.287-.408.15-.212.3-.426.448-.641.115-.166.23-.338.346-.506zm-190.078-1.557v251.5h257.233v-251.5zm254.121 248.387h-251.009V547.578h251.009z"
                  data-name="Path 55980"
                  transform="translate(-1006.837 -544.466)"
                ></path>
              </g>
              <g
                fill="#bddbe6"
                data-name="Group 2505"
                transform="translate(59.773 49.064)"
              >
                <path d="M0 0H36.509V45.362H0z" data-name="Rectangle 11"></path>
                <path
                  d="M0 0H36.509V45.362H0z"
                  data-name="Rectangle 12"
                  transform="translate(56.123)"
                ></path>
                <path
                  d="M0 0H36.502V45.362H0z"
                  data-name="Rectangle 13"
                  transform="translate(112.245)"
                ></path>
                <path
                  d="M0 0H36.509V45.362H0z"
                  data-name="Rectangle 14"
                  transform="translate(0 70.337)"
                ></path>
                <path
                  d="M0 0H36.509V45.362H0z"
                  data-name="Rectangle 15"
                  transform="translate(56.123 70.337)"
                ></path>
                <path
                  d="M0 0H36.502V45.362H0z"
                  data-name="Rectangle 16"
                  transform="translate(112.245 70.337)"
                ></path>
                <path
                  d="M0 0H68.175V51.331H0z"
                  data-name="Rectangle 17"
                  transform="translate(0 140.668)"
                ></path>
                <path
                  d="M0 0H64.394V51.331H0z"
                  data-name="Rectangle 18"
                  transform="translate(84.353 140.668)"
                ></path>
                <path
                  d="M0 0H21.354V191.999H0z"
                  data-name="Rectangle 19"
                  transform="translate(176.179)"
                ></path>
              </g>
              <g
                fill="#a0c8e3"
                data-name="Group 2506"
                transform="translate(59.773 49.064)"
              >
                <path
                  d="M1071.858 585.791l-14.323 14.323v-14.323z"
                  data-name="Path 55981"
                  transform="translate(-1057.535 -585.791)"
                ></path>
                <path
                  d="M1057.535 799.432h10.607l-10.607 10.607z"
                  data-name="Path 55982"
                  transform="translate(-1057.535 -658.765)"
                ></path>
                <path
                  d="M1083.1 751.3l19.678-19.678v18.744l-.934.934z"
                  data-name="Path 55983"
                  transform="translate(-1066.266 -635.604)"
                ></path>
                <path
                  d="M1148.843 692.617h18.744l-24.815 24.815v-18.744z"
                  data-name="Path 55984"
                  transform="translate(-1086.649 -622.279)"
                ></path>
                <path
                  d="M1189.923 651.852l5.463-5.463v5.463z"
                  data-name="Path 55985"
                  transform="translate(-1102.755 -606.489)"
                ></path>
                <path
                  d="M1264.511 585.791v2.527l-36.5 36.5v-18.742l20.286-20.285z"
                  data-name="Path 55986"
                  transform="translate(-1115.764 -585.791)"
                ></path>
                <path
                  d="M1250.546 799.432h4.133v14.611l-36.72 36.72h-18.737z"
                  data-name="Path 55987"
                  transform="translate(-1105.931 -658.765)"
                ></path>
                <path
                  d="M1346.463 719.063v18.744l-21.354 21.354v-18.744z"
                  data-name="Path 55988"
                  transform="translate(-1148.93 -631.313)"
                ></path>
                <path
                  d="M1082.126 692.617h11.3l-35.891 35.883v-11.3z"
                  data-name="Path 55989"
                  transform="translate(-1057.535 -622.279)"
                ></path>
                <path
                  d="M1179.281 589.279v11.3l-31.762 31.762h-4.747v-6.557z"
                  data-name="Path 55990"
                  transform="translate(-1086.649 -586.982)"
                ></path>
                <path
                  d="M1057.535 692.617h7.318l-7.318 7.318z"
                  data-name="Path 55991"
                  transform="translate(-1057.535 -622.279)"
                ></path>
                <path
                  d="M1087.019 585.791h7.024v41.147l-4.216 4.216h-32.293v-15.878z"
                  data-name="Path 55992"
                  transform="translate(-1057.535 -585.791)"
                ></path>
                <path
                  d="M1164.3 585.791l-21.533 21.533v-21.533z"
                  data-name="Path 55993"
                  transform="translate(-1086.649 -585.791)"
                ></path>
                <path
                  d="M1268.7 649.648l9.711-9.711v9.711z"
                  data-name="Path 55994"
                  transform="translate(-1129.662 -604.286)"
                ></path>
                <path
                  d="M1101.266 799.432h24.444v23.728l-27.6 27.6h-40.571v-7.6z"
                  data-name="Path 55995"
                  transform="translate(-1057.535 -658.765)"
                ></path>
                <path
                  d="M1185.648 799.432h7.549l-7.549 7.549z"
                  data-name="Path 55996"
                  transform="translate(-1101.294 -658.765)"
                ></path>
                <path
                  d="M1161.873 749.1l23.932-23.932V749.1z"
                  data-name="Path 55997"
                  transform="translate(-1093.174 -633.397)"
                ></path>
                <path
                  d="M1264.511 692.617V706.1l-31.877 31.877h-4.625v-43.544l1.817-1.817z"
                  data-name="Path 55998"
                  transform="translate(-1115.764 -622.279)"
                ></path>
                <path
                  d="M1346.463 585.791v35.037l-21.354 21.354v-48.171l8.22-8.22z"
                  data-name="Path 55999"
                  transform="translate(-1148.93 -585.791)"
                ></path>
                <path
                  d="M1264.667 870.977l12.366-12.366v12.366z"
                  data-name="Path 56000"
                  transform="translate(-1128.285 -678.979)"
                ></path>
                <path
                  d="M1346.463 784.519v48.171l-12.98 12.98h-8.374v-39.8z"
                  data-name="Path 56001"
                  transform="translate(-1148.93 -653.671)"
                ></path>
              </g>
              <g
                fill="#d6e6f0"
                data-name="Group 2507"
                transform="translate(55.084 44.375)"
              >
                <path
                  d="M1050.413 578.67v54.742h45.887V578.67zm4.691 50.052v-45.361h36.5v45.361z"
                  data-name="Path 56002"
                  transform="translate(-1050.413 -578.67)"
                ></path>
                <path
                  d="M1135.65 578.67v54.742h45.886V578.67zm4.691 50.052v-45.361h36.505v45.361z"
                  data-name="Path 56003"
                  transform="translate(-1079.527 -578.67)"
                ></path>
                <path
                  d="M1220.884 578.67v54.742h45.886V578.67zm41.2 50.052h-36.5v-45.361h36.5z"
                  data-name="Path 56004"
                  transform="translate(-1108.641 -578.67)"
                ></path>
                <path
                  d="M1050.413 685.487v54.745h45.887v-54.745zm4.691 4.691h36.5v45.364h-36.5z"
                  data-name="Path 56005"
                  transform="translate(-1050.413 -615.156)"
                ></path>
                <path
                  d="M1135.65 685.487v54.745h45.886v-54.745zm41.2 4.691v45.364h-36.505v-45.364z"
                  data-name="Path 56006"
                  transform="translate(-1079.527 -615.156)"
                ></path>
                <path
                  d="M1220.884 685.487v54.745h45.886v-54.745zm41.2 50.054h-36.5v-45.363h36.5z"
                  data-name="Path 56007"
                  transform="translate(-1108.641 -615.156)"
                ></path>
                <path
                  d="M1050.413 792.309v60.714h77.556v-60.714zm72.864 56.024H1055.1V797h68.174z"
                  data-name="Path 56008"
                  transform="translate(-1050.413 -651.643)"
                ></path>
                <path
                  d="M1178.521 792.309v60.714h73.779v-60.714zm69.088 56.024h-64.4V797h64.4z"
                  data-name="Path 56009"
                  transform="translate(-1094.171 -651.643)"
                ></path>
                <path
                  d="M1317.984 578.67v201.381h30.74V578.67zm26.049 196.69h-21.358v-192h21.358z"
                  data-name="Path 56010"
                  transform="translate(-1141.807 -578.67)"
                ></path>
              </g>
              <g data-name="Group 2509" transform="translate(17.769 6.602)">
                <path
                  fill="#f7f6f1"
                  d="M1005.324 535.586l-6.351-11.232h260.4v11.232z"
                  data-name="Path 56011"
                  transform="translate(-995.528 -522.344)"
                ></path>
                <g data-name="Group 2508">
                  <path
                    fill="#89b9dc"
                    d="M1192.251 536.51l-7.361-1.967h7.124c-.064-.539-.128-1.08-.2-1.619l-.045-.391H1073.7q-.061 1-.126 2.01h3.356l-.128 2.011h40.4c.013-.167.027-.33.043-.494.011-.126.024-.254.037-.381s.027-.279.045-.418c.024-.241.054-.482.083-.718h13.424c.15.207.3.41.456.614.078.1.158.212.238.317.1.14.209.279.317.415a28.291 28.291 0 00.511.665h59.9c-.002-.014-.002-.03-.005-.044zm0 0l-7.361-1.967h7.124c-.064-.539-.128-1.08-.2-1.619l-.045-.391H1073.7q-.061 1-.126 2.01h3.356l-.128 2.011h40.4c.013-.167.027-.33.043-.494.011-.126.024-.254.037-.381s.027-.279.045-.418c.024-.241.054-.482.083-.718h13.424c.15.207.3.41.456.614.078.1.158.212.238.317.1.14.209.279.317.415a28.291 28.291 0 00.511.665h59.9c-.002-.014-.002-.03-.005-.044zm-198.51-15.21l.056.1 8.567 15.153H1259.6V521.3zm10.968 11.231l-4.077-7.21h254.944v7.21z"
                    data-name="Path 56012"
                    transform="translate(-993.741 -521.302)"
                  ></path>
                </g>
              </g>
              <path
                fill="#89b9dc"
                d="M1250.379 511.275H966.754l6.11 6.7h277.516z"
                data-name="Path 56013"
                transform="translate(-966.754 -511.275)"
              ></path>
            </g>
            <g data-name="Group 2521" transform="translate(589.374 194.609)">
              <path
                fill="#d6e6f0"
                d="M0 0H623.587V288.24H0z"
                data-name="Rectangle 20"
                transform="translate(0 24.432)"
              ></path>
              <g
                fill="#89b9dc"
                data-name="Group 2511"
                transform="translate(27.084 55.618)"
              >
                <path
                  d="M0 0H43.825V54.46H0z"
                  data-name="Rectangle 21"
                  transform="translate(193.32)"
                ></path>
                <path
                  d="M0 0H43.825V54.46H0z"
                  data-name="Rectangle 22"
                  transform="translate(125.947)"
                ></path>
                <path
                  d="M0 0H43.825V54.46H0z"
                  data-name="Rectangle 23"
                  transform="translate(58.567)"
                ></path>
                <path
                  d="M0 0H43.825V54.46H0z"
                  data-name="Rectangle 24"
                  transform="translate(193.32 84.434)"
                ></path>
                <path
                  d="M0 0H43.825V54.46H0z"
                  data-name="Rectangle 25"
                  transform="translate(125.947 84.434)"
                ></path>
                <path
                  d="M0 0H43.825V54.46H0z"
                  data-name="Rectangle 26"
                  transform="translate(58.567 84.434)"
                ></path>
                <path
                  d="M0 0H81.84V61.63H0z"
                  data-name="Rectangle 27"
                  transform="translate(155.304 168.874)"
                ></path>
                <path
                  d="M0 0H77.317V61.63H0z"
                  data-name="Rectangle 28"
                  transform="translate(58.567 168.874)"
                ></path>
                <path
                  d="M0 0H43.825V54.46H0z"
                  data-name="Rectangle 29"
                  transform="translate(328.594)"
                ></path>
                <path
                  d="M0 0H43.825V54.46H0z"
                  data-name="Rectangle 30"
                  transform="translate(395.974)"
                ></path>
                <path
                  d="M0 0H43.825V54.46H0z"
                  data-name="Rectangle 31"
                  transform="translate(261.222)"
                ></path>
                <path
                  d="M0 0H43.825V54.46H0z"
                  data-name="Rectangle 32"
                  transform="translate(395.974 84.434)"
                ></path>
                <path
                  d="M0 0H43.825V54.46H0z"
                  data-name="Rectangle 33"
                  transform="translate(328.594 84.434)"
                ></path>
                <path
                  d="M0 0H43.825V54.46H0z"
                  data-name="Rectangle 34"
                  transform="translate(261.222 84.434)"
                ></path>
                <path
                  d="M0 0H81.847V61.63H0z"
                  data-name="Rectangle 35"
                  transform="translate(357.952 168.874)"
                ></path>
                <path
                  d="M0 0H77.31V61.63H0z"
                  data-name="Rectangle 36"
                  transform="translate(261.222 168.874)"
                ></path>
                <path
                  d="M0 0H43.825V54.46H0z"
                  data-name="Rectangle 37"
                  transform="translate(531.249)"
                ></path>
                <path
                  d="M0 0H43.825V54.46H0z"
                  data-name="Rectangle 38"
                  transform="translate(463.869)"
                ></path>
                <path
                  d="M0 0H43.825V54.46H0z"
                  data-name="Rectangle 39"
                  transform="translate(531.249 84.434)"
                ></path>
                <path
                  d="M0 0H43.825V54.46H0z"
                  data-name="Rectangle 40"
                  transform="translate(463.869 84.434)"
                ></path>
                <path
                  d="M0 0H35.896V61.631H0z"
                  data-name="Rectangle 41"
                  transform="translate(560.606 168.873)"
                ></path>
                <path
                  d="M0 0H77.31V61.63H0z"
                  data-name="Rectangle 42"
                  transform="translate(463.869 168.874)"
                ></path>
                <path
                  d="M0 0H25.638V230.504H0z"
                  data-name="Rectangle 43"
                ></path>
              </g>
              <g
                fill="#a0c8e3"
                data-name="Group 2512"
                transform="translate(27.084 55.618)"
              >
                <path
                  d="M1928.649 537.845v8.075l-25.638 25.638v-19.634l14.079-14.079z"
                  data-name="Path 56014"
                  transform="translate(-1903.01 -537.845)"
                ></path>
                <path
                  d="M1903.01 763.815l25.638-25.638V757.8l-25.638 25.638z"
                  data-name="Path 56015"
                  transform="translate(-1903.01 -606.273)"
                ></path>
                <path
                  d="M2026.13 666.08l-34.169 34.169v-19.627l14.539-14.542z"
                  data-name="Path 56016"
                  transform="translate(-1933.393 -581.647)"
                ></path>
                <path
                  d="M2138.119 537.845v7.4l-43.825 43.825v-19.636l31.589-31.589z"
                  data-name="Path 56017"
                  transform="translate(-1968.347 -537.845)"
                ></path>
                <path
                  d="M2035.215 862.592l48.838-48.838v19.634l-29.2 29.2z"
                  data-name="Path 56018"
                  transform="translate(-1948.168 -632.088)"
                ></path>
                <path
                  d="M2138.882 794.325h13.007l-13.007 13.007z"
                  data-name="Path 56019"
                  transform="translate(-1983.577 -625.451)"
                ></path>
                <path
                  d="M2236.409 666.08h4.034v15.6l-38.86 38.86h-4.966v-14.669z"
                  data-name="Path 56020"
                  transform="translate(-2003.298 -581.647)"
                ></path>
                <path
                  d="M2344.537 556.826v19.634l-22.328 22.328h-19.634z"
                  data-name="Path 56021"
                  transform="translate(-2039.49 -544.329)"
                ></path>
                <path
                  d="M2410.653 537.845l-8.584 8.584v-8.584z"
                  data-name="Path 56022"
                  transform="translate(-2073.474 -537.845)"
                ></path>
                <path
                  d="M1903.01 703.982l25.638-25.638v11.834l-25.638 25.638z"
                  data-name="Path 56023"
                  transform="translate(-1903.01 -585.836)"
                ></path>
                <path
                  d="M2038.442 561.772v11.841l-26.871 26.865h-11.834z"
                  data-name="Path 56024"
                  transform="translate(-1936.049 -546.018)"
                ></path>
                <path
                  d="M2098.335 537.845l-4.041 4.041v-4.041z"
                  data-name="Path 56025"
                  transform="translate(-1968.347 -537.845)"
                ></path>
                <path
                  d="M1903.01 599.868l25.638-25.638v50.459l-25.638 25.638z"
                  data-name="Path 56026"
                  transform="translate(-1903.01 -550.273)"
                ></path>
                <path
                  d="M2033.447 537.845l-41.487 41.486v-41.486z"
                  data-name="Path 56027"
                  transform="translate(-1933.393 -537.845)"
                ></path>
                <path
                  d="M1903.01 846.333l25.638-25.638v44.267h-25.638z"
                  data-name="Path 56028"
                  transform="translate(-1903.01 -634.458)"
                ></path>
                <path
                  d="M1991.961 794.325h34.886l-34.886 34.886z"
                  data-name="Path 56029"
                  transform="translate(-1933.393 -625.451)"
                ></path>
                <path
                  d="M2013.852 733.534l29.411-29.411v29.411z"
                  data-name="Path 56030"
                  transform="translate(-1940.871 -594.641)"
                ></path>
                <path
                  d="M2142.087 614.147l12.357-12.357v12.357z"
                  data-name="Path 56031"
                  transform="translate(-1984.672 -559.687)"
                ></path>
                <path
                  d="M2095.789 666.08h42.331v8.12l-43.825 43.825v-50.45z"
                  data-name="Path 56032"
                  transform="translate(-1968.347 -581.647)"
                ></path>
                <path
                  d="M2240.443 537.845v25.183l-29.277 29.272h-14.548v-35.9l18.555-18.555z"
                  data-name="Path 56033"
                  transform="translate(-2003.298 -537.845)"
                ></path>
                <path
                  d="M2300.851 537.845l-1.105 1.106v-1.106z"
                  data-name="Path 56034"
                  transform="translate(-2038.523 -537.845)"
                ></path>
                <path
                  d="M2103.77 886.008l3.7-3.7v3.7z"
                  data-name="Path 56035"
                  transform="translate(-1971.584 -655.504)"
                ></path>
                <path
                  d="M2242.9 741.867l13.348-13.349v13.349z"
                  data-name="Path 56036"
                  transform="translate(-2019.108 -602.974)"
                ></path>
                <path
                  d="M2177.393 794.325h43.329v7.13l-54.5 54.5h-27.34v-23.118z"
                  data-name="Path 56037"
                  transform="translate(-1983.577 -625.451)"
                ></path>
                <path
                  d="M2316.779 666.08h26.791v23.668l-30.792 30.792h-13.034v-37.426z"
                  data-name="Path 56038"
                  transform="translate(-2038.523 -581.647)"
                ></path>
                <path
                  d="M2402.068 666.08h.121l-.121.121z"
                  data-name="Path 56039"
                  transform="translate(-2073.474 -581.647)"
                ></path>
                <path
                  d="M2445.894 537.845v40.729l-13.73 13.731h-30.095V571.94l34.1-34.1z"
                  data-name="Path 56040"
                  transform="translate(-2073.474 -537.845)"
                ></path>
                <path
                  d="M2521.577 537.845L2504.4 555.02v-17.175z"
                  data-name="Path 56041"
                  transform="translate(-2108.428 -537.845)"
                ></path>
              </g>
              <g
                fill="#a0c8e3"
                data-name="Group 2513"
                transform="translate(244.22 55.618)"
              >
                <path
                  d="M2252.8 857.537v19.627l-.382.382h-19.627z"
                  data-name="Path 56042"
                  transform="translate(-2232.788 -647.043)"
                ></path>
                <path
                  d="M2336.916 794.325l-37.171 37.175v-19.631l17.543-17.543z"
                  data-name="Path 56043"
                  transform="translate(-2255.659 -625.451)"
                ></path>
                <path
                  d="M2436.673 666.08h9.221v10.413l-43.825 43.825v-19.627z"
                  data-name="Path 56044"
                  transform="translate(-2290.609 -581.647)"
                ></path>
                <path
                  d="M2548.227 549.743v19.634l-26.992 26.992H2504.4v-2.8z"
                  data-name="Path 56045"
                  transform="translate(-2325.563 -541.909)"
                ></path>
                <path
                  d="M2610.917 537.845l-3.4 3.4v-3.4z"
                  data-name="Path 56046"
                  transform="translate(-2360.785 -537.845)"
                ></path>
                <path
                  d="M2512.285 794.325h17.584v2.051l-59.579 59.579h-19.634z"
                  data-name="Path 56047"
                  transform="translate(-2307.205 -625.451)"
                ></path>
                <path
                  d="M2650.312 666.08h1.032v18.6l-35.864 35.86h-7.961v-11.667z"
                  data-name="Path 56048"
                  transform="translate(-2360.785 -581.647)"
                ></path>
                <path
                  d="M2756.473 562.179v19.628l-18.81 18.81h-19.627z"
                  data-name="Path 56049"
                  transform="translate(-2398.534 -546.157)"
                ></path>
                <path
                  d="M2714.26 856.672v19.634l-.945.945h-19.634z"
                  data-name="Path 56050"
                  transform="translate(-2390.216 -646.747)"
                ></path>
                <path
                  d="M2790.336 794.324v5.364l-35.9 35.9V815.95l2.067-2.067 19.556-19.559z"
                  data-name="Path 56051"
                  transform="translate(-2410.969 -625.451)"
                ></path>
                <path
                  d="M2408.168 861.6v11.841l-5.5 5.495h-11.841z"
                  data-name="Path 56052"
                  transform="translate(-2286.771 -648.43)"
                ></path>
                <path
                  d="M2483.371 794.325l-36.715 36.715V819.2l24.875-24.874z"
                  data-name="Path 56053"
                  transform="translate(-2305.839 -625.451)"
                ></path>
                <path
                  d="M2529.968 734.789l26.992-26.989v11.841l-15.151 15.151z"
                  data-name="Path 56054"
                  transform="translate(-2334.296 -595.896)"
                ></path>
                <path
                  d="M2622.751 666.08l-15.232 15.232v-11.834l3.4-3.4z"
                  data-name="Path 56055"
                  transform="translate(-2360.785 -581.647)"
                ></path>
                <path
                  d="M2658.2 615.134l10.454-10.454v10.454z"
                  data-name="Path 56056"
                  transform="translate(-2378.097 -560.674)"
                ></path>
                <path
                  d="M2742.139 537.845l-32.286 32.286V558.3l20.452-20.452z"
                  data-name="Path 56057"
                  transform="translate(-2395.74 -537.845)"
                ></path>
                <path
                  d="M2352.8 794.325h24.258v26.2l-35.429 35.429h-41.882v-8.577z"
                  data-name="Path 56058"
                  transform="translate(-2255.659 -625.451)"
                ></path>
                <path
                  d="M2446.656 794.325h6.781l-6.781 6.781z"
                  data-name="Path 56059"
                  transform="translate(-2305.839 -625.451)"
                ></path>
                <path
                  d="M2425.853 734.181l28.165-28.165v28.165z"
                  data-name="Path 56060"
                  transform="translate(-2298.733 -595.288)"
                ></path>
                <path
                  d="M2554.088 614.793l11.111-11.11v11.11z"
                  data-name="Path 56061"
                  transform="translate(-2342.535 -560.333)"
                ></path>
                <path
                  d="M2507.143 666.08h41.084v9.375L2504.4 719.28v-50.459z"
                  data-name="Path 56062"
                  transform="translate(-2325.563 -581.647)"
                ></path>
                <path
                  d="M2651.344 537.845v25.913L2622.8 592.3h-15.278v-35.176l19.279-19.279z"
                  data-name="Path 56063"
                  transform="translate(-2360.785 -537.845)"
                ></path>
                <path
                  d="M2712.212 537.845l-2.359 2.359v-2.359z"
                  data-name="Path 56064"
                  transform="translate(-2395.74 -537.845)"
                ></path>
                <path
                  d="M2533.184 875.023l24.875-24.874v24.874z"
                  data-name="Path 56065"
                  transform="translate(-2335.395 -644.519)"
                ></path>
                <path
                  d="M2673.478 748.19h-1.159l1.159-1.159z"
                  data-name="Path 56066"
                  transform="translate(-2382.919 -609.297)"
                ></path>
                <path
                  d="M2620.2 794.325h50.459l-61.63 61.63h-1.514v-48.944z"
                  data-name="Path 56067"
                  transform="translate(-2360.785 -625.451)"
                ></path>
                <path
                  d="M2739.6 666.08h14.079v36.38l-18.079 18.08h-25.746v-24.714z"
                  data-name="Path 56068"
                  transform="translate(-2395.74 -581.647)"
                ></path>
                <path
                  d="M2793 841.205v30.764h-30.763z"
                  data-name="Path 56069"
                  transform="translate(-2413.632 -641.464)"
                ></path>
              </g>
              <g data-name="Group 2518" transform="translate(22.395 50.926)">
                <g data-name="Group 2514" transform="translate(193.321)">
                  <path
                    fill="#f7f6f1"
                    d="M2189.5 530.718v63.843h53.2v-63.843zm4.691 59.152v-54.461h43.825v54.461z"
                    data-name="Path 56070"
                    transform="translate(-2189.498 -530.718)"
                  ></path>
                </g>
                <g data-name="Group 2515" transform="translate(125.946)">
                  <path
                    fill="#f7f6f1"
                    d="M2087.17 530.718v63.843h53.207v-63.843zm48.516 59.152h-43.825v-54.461h43.825z"
                    data-name="Path 56071"
                    transform="translate(-2087.17 -530.718)"
                  ></path>
                </g>
                <g data-name="Group 2516" transform="translate(58.571)">
                  <path
                    fill="#f7f6f1"
                    d="M1984.843 530.718v63.843h53.2v-63.843zm48.513 59.152h-43.822v-54.461h43.822z"
                    data-name="Path 56072"
                    transform="translate(-1984.843 -530.718)"
                  ></path>
                </g>
                <path
                  fill="#f7f6f1"
                  d="M2189.5 658.961V722.8h53.2v-63.84zm48.516 59.149h-43.825v-54.458h43.825z"
                  data-name="Path 56073"
                  transform="translate(-1996.177 -574.522)"
                ></path>
                <path
                  fill="#f7f6f1"
                  d="M2087.17 658.961V722.8h53.207v-63.84zm48.516 59.149h-43.825v-54.458h43.825z"
                  data-name="Path 56074"
                  transform="translate(-1961.224 -574.522)"
                ></path>
                <path
                  fill="#f7f6f1"
                  d="M1984.843 658.961V722.8h53.2v-63.84zm48.513 59.149h-43.822v-54.458h43.822z"
                  data-name="Path 56075"
                  transform="translate(-1926.272 -574.522)"
                ></path>
                <path
                  fill="#f7f6f1"
                  d="M2178.687 791.132c-.185-.306-.367-.611-.547-.914a45.48 45.48 0 01-.49-.847c-.083-.147-.166-.292-.246-.44q-.362-.652-.695-1.292l-.223-.44h-11.016q-.386 2.353-.788 4.691h14.477l-.472-.758zm0 0c-.185-.306-.37-.611-.547-.914a45.48 45.48 0 01-.49-.847l-.246-.44q-.362-.652-.695-1.292c-.075-.148-.15-.292-.223-.44h-11.016q-.386 2.353-.788 4.691h14.477l-.472-.758zm-46.932-3.932v71.01h91.226V787.2zm86.535 66.319h-81.845v-61.628h81.845z"
                  data-name="Path 56076"
                  transform="translate(-1976.453 -618.325)"
                ></path>
                <path
                  fill="#f7f6f1"
                  d="M1984.843 787.2v71.01h86.69V787.2zm82 4.691v61.628h-77.309v-61.628z"
                  data-name="Path 56077"
                  transform="translate(-1926.272 -618.325)"
                ></path>
                <path
                  fill="#f7f6f1"
                  d="M2394.946 530.718v63.843h53.207v-63.843zm48.516 59.152h-43.825v-54.461h43.825z"
                  data-name="Path 56078"
                  transform="translate(-2066.352 -530.718)"
                ></path>
                <path
                  fill="#f7f6f1"
                  d="M2497.278 530.718v63.843h53.2v-63.843zm4.691 59.152v-54.461h43.822v54.461z"
                  data-name="Path 56079"
                  transform="translate(-2101.305 -530.718)"
                ></path>
                <g data-name="Group 2517" transform="translate(261.219)">
                  <path
                    fill="#f7f6f1"
                    d="M2292.619 530.718v63.843h53.206v-63.843zm4.691 59.152v-54.461h43.825v54.461z"
                    data-name="Path 56080"
                    transform="translate(-2292.619 -530.718)"
                  ></path>
                </g>
                <path
                  fill="#f7f6f1"
                  d="M2497.278 658.961V722.8h53.2v-63.84zm48.513 59.149h-43.822v-54.458h43.822z"
                  data-name="Path 56081"
                  transform="translate(-2101.305 -574.522)"
                ></path>
                <path
                  fill="#f7f6f1"
                  d="M2394.946 658.961V722.8h53.207v-63.84zm48.516 59.149h-43.825v-54.458h43.825z"
                  data-name="Path 56082"
                  transform="translate(-2066.352 -574.522)"
                ></path>
                <path
                  fill="#f7f6f1"
                  d="M2292.619 658.961V722.8h53.206v-63.84zm48.516 59.149h-43.825v-54.458h43.825z"
                  data-name="Path 56083"
                  transform="translate(-2031.4 -574.522)"
                ></path>
                <path
                  fill="#f7f6f1"
                  d="M2439.531 787.2v71.01h91.226V787.2zm86.535 4.691v61.628h-81.844v-61.628z"
                  data-name="Path 56084"
                  transform="translate(-2081.581 -618.325)"
                ></path>
                <path
                  fill="#f7f6f1"
                  d="M2312.714 787.2c-.092.354-.185.705-.282 1.059l-.088.322c-.088.324-.18.649-.271.973-.223.783-.451 1.566-.684 2.337h29.224q.258-1.194.518-2.366l.185-.845q.161-.744.324-1.479zm0 0c-.092.354-.185.705-.282 1.059l-.088.322c-.088.324-.18.649-.271.973-.223.783-.451 1.566-.684 2.337h29.224q.258-1.194.518-2.366c.059-.284.12-.563.185-.845q.161-.744.324-1.479zm-20.1 0v71.01h86.693V787.2zm82 66.319h-77.304v-61.628h77.312z"
                  data-name="Path 56085"
                  transform="translate(-2031.4 -618.325)"
                ></path>
                <path
                  fill="#f7f6f1"
                  d="M2805.054 530.718v63.843h2.571v-63.843z"
                  data-name="Path 56086"
                  transform="translate(-2206.432 -530.718)"
                ></path>
                <path
                  fill="#f7f6f1"
                  d="M2702.727 530.718v63.843h53.206v-63.843zm4.691 59.152v-54.461h43.825v54.461z"
                  data-name="Path 56087"
                  transform="translate(-2171.481 -530.718)"
                ></path>
                <path
                  fill="#f7f6f1"
                  d="M2600.4 530.718v63.843h53.207v-63.843zm48.516 59.152h-43.826v-54.461h43.825z"
                  data-name="Path 56088"
                  transform="translate(-2136.528 -530.718)"
                ></path>
                <path
                  fill="#f7f6f1"
                  d="M2805.054 658.961V722.8h2.571v-63.84z"
                  data-name="Path 56089"
                  transform="translate(-2206.432 -574.522)"
                ></path>
                <path
                  fill="#f7f6f1"
                  d="M2702.727 658.961V722.8h53.206v-63.84zm48.516 59.149h-43.825v-54.458h43.825z"
                  data-name="Path 56090"
                  transform="translate(-2171.481 -574.522)"
                ></path>
                <path
                  fill="#f7f6f1"
                  d="M2605.087 678.976V678.962a.027.027 0 000 .014zm-4.688-20.015V722.8h53.207v-63.84zm48.516 59.149h-43.825v-54.458h43.825z"
                  data-name="Path 56091"
                  transform="translate(-2136.528 -574.522)"
                ></path>
                <path
                  fill="#f7f6f1"
                  d="M2752 791.891h35.9V787.2h-40.59v71.01h40.59v-4.691H2752z"
                  data-name="Path 56092"
                  transform="translate(-2186.709 -618.325)"
                ></path>
                <path
                  fill="#f7f6f1"
                  d="M2600.4 787.2v71.01h86.691V787.2zm82 66.319h-77.31v-61.628h77.31z"
                  data-name="Path 56093"
                  transform="translate(-2136.528 -618.325)"
                ></path>
                <path
                  fill="#f7f6f1"
                  d="M1895.888 530.718V770.6h35.023V530.718zm30.332 235.194h-25.641v-230.5h25.641z"
                  data-name="Path 56094"
                  transform="translate(-1895.888 -530.718)"
                ></path>
              </g>
              <g data-name="Group 2520" transform="translate(0 7.682)">
                <path
                  fill="#f7f6f1"
                  d="M0 0H621.576V12.729H0z"
                  data-name="Rectangle 44"
                  transform="translate(2.01 2.011)"
                ></path>
                <g data-name="Group 2519">
                  <path
                    fill="#97c4de"
                    d="M1890.1 477.77c-.054.67-.1 1.338-.159 2.01h3.362c-.046.587-.092 1.177-.14 1.769-.005.08-.014.161-.019.242h460.384q-.113-.99-.22-2.011h72.313c0-.587.008-1.177.011-1.763v-.247zm-28.228-12.729v16.75h623.586v-4.021H1865.9v-8.708h619.566v-4.021z"
                    data-name="Path 56095"
                    transform="translate(-1861.876 -465.041)"
                  ></path>
                </g>
              </g>
              <path
                fill="#97c4de"
                d="M0 0H623.587V7.682H0z"
                data-name="Rectangle 45"
              ></path>
            </g>
            <g data-name="Group 2538" transform="translate(260.461)">
              <g data-name="Group 2524">
                <g data-name="Group 2523" transform="translate(14.538 8.222)">
                  <path
                    fill="#f7f6f1"
                    d="M1396.02 183.741l-6.219-10.394h315.906l-6.219 10.394z"
                    data-name="Path 56096"
                    transform="translate(-1386.254 -171.338)"
                  ></path>
                  <g data-name="Group 2522">
                    <path
                      fill="#97c4de"
                      d="M1384.414 170.3l8.625 14.413h305.75l8.623-14.413zm10.907 10.392l-3.814-6.371h308.815l-3.814 6.371z"
                      data-name="Path 56097"
                      transform="translate(-1384.414 -170.296)"
                    ></path>
                  </g>
                </g>
                <path
                  fill="#97c4de"
                  d="M1715.144 157.808h-352.81l6.11 8.221h340.59z"
                  data-name="Path 56098"
                  transform="translate(-1362.334 -157.808)"
                ></path>
              </g>
              <g data-name="Group 2525" transform="translate(23.164 22.635)">
                <path
                  fill="#f7f6f1"
                  d="M0 0H302.636V480.339H0z"
                  data-name="Rectangle 46"
                  transform="translate(1.556 1.556)"
                ></path>
                <path
                  fill="#97c4de"
                  d="M1700.151 435.827v103.805h1.555V436.837zm0 146.933v30.1c.18-.024.348-.04.5-.045.038 0 .075-.005.11-.005a1.661 1.661 0 01.5.038.356.356 0 01.046.018c.016.014.032.024.048.04s.024.024.035.038a.472.472 0 01.048.072 1.008 1.008 0 01.08.155l.04.1c.024.061.046.131.07.2s.04.137.059.209l.016.056v-30.885zm0 30.1c.18-.024.348-.04.5-.045.038 0 .075-.005.11-.005a1.661 1.661 0 01.5.038.356.356 0 01.046.018c.016.014.032.024.048.04s.024.024.035.038a.472.472 0 01.048.072 1.008 1.008 0 01.08.155l.04.1c.024.061.046.131.07.2s.04.137.059.209l.016.056v-30.885l-1.555-.092zm0-177.035v103.807h1.555V436.837zm-302.637-243.639v483.451h305.75V192.186zm302.637 480.339h-299.525V195.3h299.525z"
                  data-name="Path 56099"
                  transform="translate(-1397.514 -192.186)"
                ></path>
              </g>
              <path
                fill="#97c4de"
                d="M0 0H92.83V114.843H0z"
                data-name="Rectangle 47"
                transform="translate(68.104 391.243)"
              ></path>
              <path
                fill="#97c4de"
                d="M0 0H92.83V114.843H0z"
                data-name="Rectangle 48"
                transform="translate(191.877 391.243)"
              ></path>
              <g data-name="Group 2527" transform="translate(46.777 288.415)">
                <path
                  fill="#97c4de"
                  d="M0 0H253.059V54.839H0z"
                  data-name="Rectangle 49"
                ></path>
                <g
                  fill="#f7f6f1"
                  data-name="Group 2526"
                  transform="translate(23.573 15.124)"
                >
                  <path
                    d="M1485.13 619.151v9.65h-8.768v-9.65h-7.183V645.3h7.183v-10.235h8.768V645.3h7.184v-26.149z"
                    data-name="Path 56100"
                    transform="translate(-1469.179 -618.928)"
                  ></path>
                  <path
                    d="M1540.173 627.363a13.565 13.565 0 00-2.67-4.273 13.249 13.249 0 00-4.163-3.02 12.583 12.583 0 00-5.417-1.142 13.072 13.072 0 00-5.358 1.086 13.531 13.531 0 00-4.238 2.911 13.2 13.2 0 00-2.78 4.217 12.826 12.826 0 00-.994 4.974 13.187 13.187 0 00.957 4.935 13.528 13.528 0 002.689 4.254 13.382 13.382 0 004.163 3.005 12.583 12.583 0 005.414 1.142 12.884 12.884 0 005.361-1.107 13.641 13.641 0 004.219-2.927 13.452 13.452 0 002.761-4.2 12.681 12.681 0 00.994-4.953 13.274 13.274 0 00-.938-4.902zm-6.7 7.293a6.836 6.836 0 01-1.07 2.227 5.434 5.434 0 01-1.86 1.583 5.747 5.747 0 01-7.221-1.528 6.718 6.718 0 01-1.1-2.193 8.851 8.851 0 01-.367-2.56 9.2 9.2 0 01.348-2.522 6.62 6.62 0 011.088-2.211 5.482 5.482 0 011.86-1.565 5.685 5.685 0 012.67-.59 5.749 5.749 0 012.635.571 5.681 5.681 0 011.879 1.512 6.359 6.359 0 011.123 2.172 8.97 8.97 0 01.367 2.562 9.409 9.409 0 01-.355 2.542z"
                    data-name="Path 56101"
                    transform="translate(-1484.678 -618.852)"
                  ></path>
                  <path
                    d="M1583.221 634.065a6.07 6.07 0 00-1.584-2.193 10.186 10.186 0 00-2.488-1.566 27.112 27.112 0 00-3.259-1.217q-1.142-.365-2.045-.624a11.76 11.76 0 01-1.528-.536 3.161 3.161 0 01-.959-.606 1.155 1.155 0 01-.33-.847 1.029 1.029 0 01.571-.994 3.318 3.318 0 011.527-.295 8.053 8.053 0 012.413.386 20.849 20.849 0 012.23.828 20.5 20.5 0 012.284 1.179l3.093-5.932q-.885-.441-1.989-.938a25.309 25.309 0 00-2.375-.922 22.642 22.642 0 00-2.635-.7 13.992 13.992 0 00-2.8-.276 11.87 11.87 0 00-3.7.571 9.679 9.679 0 00-3.112 1.676 7.878 7.878 0 00-2.155 2.763 8.786 8.786 0 00-.791 3.83 7.7 7.7 0 00.386 2.544 5.5 5.5 0 001.2 1.97 8.058 8.058 0 002.062 1.547 19.081 19.081 0 002.983 1.27c.786.271 1.531.5 2.231.7s1.318.394 1.86.589a4.247 4.247 0 011.27.684 1.185 1.185 0 01.461.938.962.962 0 01-.461.828 3.082 3.082 0 01-1.64.314 10.637 10.637 0 01-3.037-.442 19.331 19.331 0 01-2.672-.994 21.769 21.769 0 01-2.688-1.437l-3.093 6.3a18.049 18.049 0 002.56 1.252 25.886 25.886 0 002.873.96 25.429 25.429 0 003 .624 20.313 20.313 0 002.946.223 15.543 15.543 0 003.592-.423 9.6 9.6 0 003.206-1.383 7.084 7.084 0 002.283-2.559 8.249 8.249 0 00.866-3.959 7.957 7.957 0 00-.556-3.133z"
                    data-name="Path 56102"
                    transform="translate(-1501.036 -618.813)"
                  ></path>
                  <path
                    d="M1624.655 624.914a9.569 9.569 0 00-1.823-2.908 9.16 9.16 0 00-2.779-2.064 7.956 7.956 0 00-3.539-.791h-11.271V645.3h7.184v-8.03h4.272a8.137 8.137 0 003.517-.756 8.271 8.271 0 002.726-2.024 9.593 9.593 0 002.377-6.283 8.74 8.74 0 00-.664-3.293zm-7.2 5.489a1.685 1.685 0 01-1.236.608h-3.792v-5.6h3.61a2 2 0 011.324.589 2.89 2.89 0 01.665 2.209 3.22 3.22 0 01-.571 2.191z"
                    data-name="Path 56103"
                    transform="translate(-1515.654 -618.928)"
                  ></path>
                  <path
                    d="M1645.692 619.151V645.3h7.184v-26.149z"
                    data-name="Path 56104"
                    transform="translate(-1529.471 -618.928)"
                  ></path>
                  <path
                    d="M1666.168 619.151v6.262h7.551V645.3h7.184v-19.887h7.513v-6.262z"
                    data-name="Path 56105"
                    transform="translate(-1536.465 -618.928)"
                  ></path>
                  <path
                    d="M1721.656 619.151h-6.556L1706 645.3h7.328l1.549-5.119h6.961l1.584 5.119h7.293zm-5.709 16.243l2.431-8.1 2.322 8.1z"
                    data-name="Path 56106"
                    transform="translate(-1550.071 -618.928)"
                  ></path>
                  <path
                    d="M1759.619 639.043v-19.892h-7.183V645.3h19.044v-6.261z"
                    data-name="Path 56107"
                    transform="translate(-1565.931 -618.928)"
                  ></path>
                </g>
              </g>
              <g
                fill="#89b9dc"
                data-name="Group 2528"
                transform="translate(68.105 55.353)"
              >
                <path d="M0 0H57.991V72.063H0z" data-name="Rectangle 50"></path>
                <path
                  d="M0 0H57.991V72.063H0z"
                  data-name="Rectangle 51"
                  transform="translate(158.608)"
                ></path>
                <path
                  d="M0 0H57.998V72.063H0z"
                  data-name="Rectangle 52"
                  transform="translate(79.301)"
                ></path>
                <path
                  d="M0 0H57.991V72.063H0z"
                  data-name="Rectangle 53"
                  transform="translate(0 140.723)"
                ></path>
                <path
                  d="M0 0H57.991V72.063H0z"
                  data-name="Rectangle 54"
                  transform="translate(158.608 140.723)"
                ></path>
                <path
                  d="M0 0H57.998V72.063H0z"
                  data-name="Rectangle 55"
                  transform="translate(79.301 140.723)"
                ></path>
              </g>
              <g
                fill="#a0c8e3"
                data-name="Group 2529"
                transform="translate(68.105 55.353)"
              >
                <path
                  d="M1508.918 241.877l-43.148 43.148v-19.634l23.514-23.514z"
                  data-name="Path 56108"
                  transform="translate(-1465.77 -241.877)"
                ></path>
                <path
                  d="M1492.018 455.6h19.634l-45.882 45.882V481.85z"
                  data-name="Path 56109"
                  transform="translate(-1465.77 -314.879)"
                ></path>
                <path
                  d="M1609.922 329.337l42.385-42.384v19.627l-22.757 22.757z"
                  data-name="Path 56110"
                  transform="translate(-1515.008 -257.273)"
                ></path>
                <path
                  d="M1733.8 242.728l-27.146 27.146v-19.627l8.369-8.37h17.926z"
                  data-name="Path 56111"
                  transform="translate(-1548.05 -241.877)"
                ></path>
                <path
                  d="M1639.213 553.067l23.1-23.1V549.6l-3.471 3.471z"
                  data-name="Path 56112"
                  transform="translate(-1525.013 -340.28)"
                ></path>
                <path
                  d="M1753.948 455.6l-47.289 47.283v-19.626l27.655-27.655z"
                  data-name="Path 56113"
                  transform="translate(-1548.05 -314.879)"
                ></path>
                <path
                  d="M1550.089 350.042l2.473-2.473v2.473z"
                  data-name="Path 56114"
                  transform="translate(-1494.571 -277.978)"
                ></path>
                <path
                  d="M1644.207 241.877v2.118l-58 58v-11.837l48.281-48.281z"
                  data-name="Path 56115"
                  transform="translate(-1506.908 -241.877)"
                ></path>
                <path
                  d="M1465.77 301.446l57.991-57.991v50.459l-20.561 20.565h-37.43z"
                  data-name="Path 56116"
                  transform="translate(-1465.77 -242.416)"
                ></path>
                <path
                  d="M1616.4 241.877l-30.188 30.188v-30.188z"
                  data-name="Path 56117"
                  transform="translate(-1506.908 -241.877)"
                ></path>
                <path
                  d="M1478.715 539.387l49.467-49.467v49.467z"
                  data-name="Path 56118"
                  transform="translate(-1470.192 -326.601)"
                ></path>
                <path
                  d="M1587.5 455.6h50.459l-51.746 51.746v-50.457z"
                  data-name="Path 56119"
                  transform="translate(-1506.908 -314.879)"
                ></path>
                <path
                  d="M1764.649 249.021v50.459l-16.9 16.9h-41.091v-9.368z"
                  data-name="Path 56120"
                  transform="translate(-1548.05 -244.317)"
                ></path>
                <path
                  d="M1765.028 478.084v50.459l-6.8 6.8h-50.459z"
                  data-name="Path 56121"
                  transform="translate(-1548.429 -322.558)"
                ></path>
              </g>
              <g
                fill="#d6e6f0"
                data-name="Group 2530"
                transform="translate(68.104 55.355)"
              >
                <path
                  d="M1465.768 241.88v72.063h57.994V241.88zm53.3 67.372h-48.612v-62.681h48.612z"
                  data-name="Path 56122"
                  transform="translate(-1465.768 -241.88)"
                ></path>
                <path
                  d="M1706.658 241.88v72.063h57.994V241.88zm4.691 4.691h48.613v62.682h-48.613z"
                  data-name="Path 56123"
                  transform="translate(-1548.049 -241.88)"
                ></path>
                <path
                  d="M1586.215 241.88v72.063h57.991V241.88zm53.3 67.372h-48.61v-62.681h48.61z"
                  data-name="Path 56124"
                  transform="translate(-1506.909 -241.88)"
                ></path>
                <path
                  d="M1465.768 455.605v72.063h57.994v-72.063zm53.3 67.372h-48.612V460.3h48.612z"
                  data-name="Path 56125"
                  transform="translate(-1465.768 -314.882)"
                ></path>
                <path
                  d="M1706.658 455.605v72.063h57.994v-72.063zm4.691 4.691h48.613v62.682h-48.613z"
                  data-name="Path 56126"
                  transform="translate(-1548.049 -314.882)"
                ></path>
                <path
                  d="M1586.215 455.605v72.063h57.991v-72.063zm53.3 67.372h-48.61V460.3h48.61z"
                  data-name="Path 56127"
                  transform="translate(-1506.909 -314.882)"
                ></path>
              </g>
              <path
                fill="#f7f6f1"
                d="M0 0H2.474V101.064H0z"
                data-name="Rectangle 56"
                transform="translate(228.151 399.173)"
              ></path>
              <path
                fill="#f7f6f1"
                d="M0 0H2.474V101.064H0z"
                data-name="Rectangle 57"
                transform="translate(100.114 399.173)"
              ></path>
              <g data-name="Group 2533" transform="translate(0 361.859)">
                <g data-name="Group 2532" transform="translate(14.538 8.223)">
                  <path
                    fill="#f7f6f1"
                    d="M1396.02 733.32l-6.219-10.394h315.906l-6.219 10.394z"
                    data-name="Path 56128"
                    transform="translate(-1386.254 -720.917)"
                  ></path>
                  <g data-name="Group 2531">
                    <path
                      fill="#97c4de"
                      d="M1675.511 721.883c-.142.188-.292.375-.442.563-.118.15-.242.3-.365.447-.08.1-.163.2-.244.295-.2.236-.4.475-.611.708h3.241q.213-1.029.448-2.013zm0 0c-.142.188-.292.375-.442.563-.118.15-.239.3-.365.447-.078.1-.161.2-.244.295-.2.236-.4.475-.611.708h3.241q.213-1.029.448-2.013zm-291.1-2.008l5.514 9.213 1.557 2.6 1.555 2.6h305.75l8.623-14.412zm10.907 10.392l-.724-1.209-1.557-2.6-1.533-2.56h308.815l-1.533 2.56-1.558 2.6-.724 1.209z"
                      data-name="Path 56129"
                      transform="translate(-1384.414 -719.875)"
                    ></path>
                  </g>
                </g>
                <path
                  fill="#97c4de"
                  d="M1715.144 707.386h-352.81l6.11 8.221h340.59z"
                  data-name="Path 56130"
                  transform="translate(-1362.334 -707.386)"
                ></path>
              </g>
              <g data-name="Group 2537" transform="translate(9.912 149.483)">
                <g data-name="Group 2534" transform="translate(0 4.914)">
                  <path
                    fill="#f7f6f1"
                    d="M1385.469 405.406a5.026 5.026 0 110-10.052h317.97a5.026 5.026 0 010 10.052z"
                    data-name="Path 56131"
                    transform="translate(-1378.431 -393.344)"
                  ></path>
                  <path
                    fill="#97c4de"
                    d="M1707.37 394.363a7.011 7.011 0 00-4.975-2.062h-317.971a7.036 7.036 0 000 14.073H1702.4a7.035 7.035 0 004.975-12.011zm-4.975 7.99h-317.971a3.015 3.015 0 110-6.031H1702.4a3.015 3.015 0 110 6.031z"
                    data-name="Path 56132"
                    transform="translate(-1377.387 -392.301)"
                  ></path>
                </g>
                <g data-name="Group 2535" transform="translate(8.1)">
                  <path
                    fill="#f7f6f1"
                    d="M0 0H312.733V1.803H0z"
                    data-name="Rectangle 58"
                    transform="translate(1.556 1.556)"
                  ></path>
                  <path
                    fill="#97c4de"
                    d="M1705.534 384.837H1389.69v4.914h315.844v-4.914z"
                    data-name="Path 56133"
                    transform="translate(-1389.69 -384.837)"
                  ></path>
                </g>
                <g data-name="Group 2536" transform="translate(8.1 18.986)">
                  <path
                    fill="#f7f6f1"
                    d="M0 0H312.733V1.803H0z"
                    data-name="Rectangle 59"
                    transform="translate(1.556 1.556)"
                  ></path>
                  <path
                    fill="#97c4de"
                    d="M1705.534 413.673H1389.69v4.914h315.844v-4.914z"
                    data-name="Path 56134"
                    transform="translate(-1389.69 -413.673)"
                  ></path>
                </g>
              </g>
            </g>
          </g>
          <g transform="translate(1703.456 207.349)">
            <path
              fill="#97c4de"
              d="M2733.2 338.41v311.367a116.82 116.82 0 01-22.146 8.3 115.535 115.535 0 01-12.909 2.63c-.279.04-.561.08-.842.118-.1.016-.2.027-.292.04q-1.66.225-3.337.4c-.354.038-.708.075-1.064.1q-4.966.478-10.041.509h-1.49q-1.6-.008-3.2-.07-1.894-.068-3.793-.209-1.057-.072-2.115-.169c-.442-.04-.882-.083-1.319-.126l-.378-.04q-2.392-.25-4.714-.587-4.178-.6-8.143-1.474a106.348 106.348 0 01-11.724-3.259q-2.747-.933-5.358-1.994a93.185 93.185 0 01-21.98-12.531q-1.343-1.029-2.624-2.1-3.092-2.578-5.86-5.361a77.589 77.589 0 01-2.235-2.33q-.486-.526-.957-1.056-1.435-1.608-2.761-3.262a70.27 70.27 0 01-1.123-1.429q-.455-.595-.9-1.193-1.54-2.095-2.927-4.246a76.654 76.654 0 01-7.093-13.928q-.249-.647-.485-1.294a68.14 68.14 0 01-3.962-18.144c-1.359-18.115 2.573-32.637 7.784-45.463.271-.667.547-1.332.823-1.988 2.785-6.6 5.859-12.775 8.655-18.776q1.1-2.361 2.131-4.691c3.835-8.7 6.746-17.2 6.942-26.346.161-7.489-1.225-12.767-3.189-18.838-.917-2.82-1.954-5.809-3.026-9.275a156.333 156.333 0 01-1.37-4.693 168.836 168.836 0 01-4.908-26.493q-.113-.99-.22-2.011-.1-.989-.2-2.01-.394-4.1-.7-8.708-.064-.994-.123-2.011c-.04-.662-.08-1.332-.115-2.01 0-.027 0-.056-.005-.086q-.205-3.836-.124-7.6c1.005-48.055 36.1-86.168 73.385-90.606 25.895-3.076 46.652 6.86 60.032 22.943z"
              data-name="Path 56135"
              transform="translate(-2587.151 -314.914)"
            ></path>
            <g data-name="Group 2539" transform="translate(86.201 51.917)">
              <path
                fill="#f7f6f1"
                d="M2737.869 752.038a1.777 1.777 0 01-.219-.014 1.676 1.676 0 01-1.445-1.879 351.7 351.7 0 002.408-57.187c-1.543-35.115-5.612-70.445-9.547-104.613-2.893-25.119-5.885-51.094-7.872-76.916-3.33-38.627-6.774-78.584 5.52-116.506a1.675 1.675 0 113.187 1.033c-12.084 37.277-8.669 76.891-5.367 115.2 1.982 25.774 4.97 51.716 7.86 76.805 3.943 34.224 8.018 69.615 9.568 104.865a354.993 354.993 0 01-2.434 57.751 1.677 1.677 0 01-1.659 1.461z"
                data-name="Path 56136"
                transform="translate(-2718.07 -393.764)"
              ></path>
            </g>
            <g data-name="Group 2540" transform="translate(86.463 77.989)">
              <path
                fill="#f7f6f1"
                d="M2720.144 480.328a1.676 1.676 0 01-1.13-2.913l47.77-43.616a1.675 1.675 0 112.259 2.474l-47.771 43.616a1.67 1.67 0 01-1.128.439z"
                data-name="Path 56137"
                transform="translate(-2718.469 -433.362)"
              ></path>
            </g>
            <g data-name="Group 2541" transform="translate(20.919 259.674)">
              <path
                fill="#f7f6f1"
                d="M2702.295 746.59a1.666 1.666 0 01-.642-.129l-81.7-33.939a1.675 1.675 0 011.285-3.094l81.7 33.939a1.676 1.676 0 01-.643 3.223z"
                data-name="Path 56138"
                transform="translate(-2618.922 -709.299)"
              ></path>
            </g>
            <g data-name="Group 2542" transform="translate(102.617 240.538)">
              <path
                fill="#f7f6f1"
                d="M2786.43 680.236v3.752l-2.571 1.3-14.169 7.167-4.688 2.37-15.71 7.945-3.857 1.952c-.048.024-.1.045-.147.067a1.675 1.675 0 01-1.364-3.056l1.394-.705 2.377-1.2 17.3-8.754 4.69-2.372 14.169-7.165z"
                data-name="Path 56139"
                transform="translate(-2743.003 -680.236)"
              ></path>
            </g>
          </g>
          <g transform="translate(957.504 35.519)">
            <g data-name="Group 2543" transform="translate(133.27 23.57)">
              <path
                fill="#97c4de"
                d="M1221.931 89.756c-3.3-.375-29.033 6.628-30.041 8.926s-2.324 11.255-.884 12.858 26.842-3 30.476-4.793 5.018-16.47.449-16.991z"
                data-name="Path 56140"
                transform="translate(-1190.37 -89.742)"
              ></path>
              <path
                fill="#80a7bd"
                d="M1236.592 91.824c-5.031-.234-3.814 14.254-1.037 14.7s4.078-14.559 1.037-14.7z"
                data-name="Path 56141"
                transform="translate(-1204.981 -90.452)"
              ></path>
            </g>
            <path
              fill="#bddbe6"
              d="M1211.578 82.13c-14.373 5.771-147.992 27.518-158.143 25.733-8.946-1.573-28.465-34.8-28.465-34.8l6.621-2.384s26.478 21.775 32.393 21.1 119.416-36.262 135.211-37.612c7.307-.627 13.016.086 17.6 1.994a22.721 22.721 0 017.057 4.61 29.987 29.987 0 015.124 6.653c2.086 3.582-6.912 10.498-17.398 14.706z"
              data-name="Path 56142"
              transform="translate(-1000.604 -53.945)"
            ></path>
            <path
              fill="#97c4de"
              d="M1120.134 99.367c-.077-2.073-107.265 3.992-111.472 4.631-2.758.419-20.737 4.7-20.7 5.755.058 1.565 83.722-4.876 87.5-.118 2.045 2.576 44.755-8.023 44.672-10.268z"
              data-name="Path 56143"
              transform="translate(-987.964 -69.313)"
            ></path>
            <g data-name="Group 2544" transform="translate(111.366 28.998)">
              <path
                fill="#d1e6ed"
                d="M1188.229 98c-3.3-.375-29.033 6.628-30.041 8.926s-1.707 9.5-.267 11.1 26.225-1.248 29.859-3.039 5.02-16.466.449-16.987z"
                data-name="Path 56144"
                transform="translate(-1157.103 -97.986)"
              ></path>
              <path
                fill="#97c4de"
                d="M1202.664 100.067c-5.031-.234-3.813 14.254-1.036 14.7s4.077-14.559 1.036-14.7z"
                data-name="Path 56145"
                transform="translate(-1171.488 -98.696)"
              ></path>
            </g>
            <path
              fill="#97c4de"
              d="M1316.1 61.921c-9.273.088-16.109 4.022-16.762 4.046-.827.03-4.938-.763-4.224-3.826.56-2.4 6.9-4.036 13.929-4.83a22.721 22.721 0 017.057 4.61z"
              data-name="Path 56146"
              transform="translate(-1092.847 -55.095)"
            ></path>
            <g
              fill="#97c4de"
              data-name="Group 2545"
              transform="translate(123.781 9.086)"
            >
              <path
                d="M1278.431 67.75c-1.1-.158-1.239 2.923-.053 3.1s1.148-2.943.053-3.1z"
                data-name="Path 56147"
                transform="translate(-1210.658 -67.744)"
              ></path>
              <path
                d="M1274.8 68.646c-1.1-.158-1.239 2.923-.053 3.1s1.153-2.946.053-3.1z"
                data-name="Path 56148"
                transform="translate(-1209.419 -68.05)"
              ></path>
              <path
                d="M1271.175 69.542c-1.1-.157-1.239 2.923-.053 3.1s1.148-2.942.053-3.1z"
                data-name="Path 56149"
                transform="translate(-1208.18 -68.356)"
              ></path>
              <path
                d="M1267.546 70.439c-1.1-.158-1.239 2.922-.053 3.1s1.149-2.939.053-3.1z"
                data-name="Path 56150"
                transform="translate(-1206.94 -68.663)"
              ></path>
              <path
                d="M1263.918 71.335c-1.1-.158-1.239 2.922-.054 3.1s1.15-2.942.054-3.1z"
                data-name="Path 56151"
                transform="translate(-1205.701 -68.969)"
              ></path>
              <path
                d="M1260.29 72.231c-1.1-.158-1.239 2.923-.053 3.1s1.149-2.942.053-3.1z"
                data-name="Path 56152"
                transform="translate(-1204.462 -69.275)"
              ></path>
              <path
                d="M1256.662 73.127c-1.1-.158-1.239 2.923-.053 3.1s1.149-2.942.053-3.1z"
                data-name="Path 56153"
                transform="translate(-1203.223 -69.581)"
              ></path>
              <path
                d="M1253.033 74.024c-1.1-.158-1.239 2.922-.053 3.1s1.149-2.942.053-3.1z"
                data-name="Path 56154"
                transform="translate(-1201.983 -69.887)"
              ></path>
              <path
                d="M1249.406 74.92c-1.1-.158-1.239 2.922-.054 3.1s1.148-2.942.054-3.1z"
                data-name="Path 56155"
                transform="translate(-1200.744 -70.193)"
              ></path>
              <path
                d="M1245.777 75.816c-1.1-.158-1.239 2.922-.054 3.1s1.15-2.942.054-3.1z"
                data-name="Path 56156"
                transform="translate(-1199.505 -70.499)"
              ></path>
              <path
                d="M1242.149 76.713c-1.1-.159-1.239 2.922-.054 3.1s1.15-2.942.054-3.1z"
                data-name="Path 56157"
                transform="translate(-1198.266 -70.806)"
              ></path>
              <path
                d="M1238.521 77.609c-1.1-.158-1.24 2.922-.054 3.1s1.15-2.942.054-3.1z"
                data-name="Path 56158"
                transform="translate(-1197.026 -71.112)"
              ></path>
              <path
                d="M1234.893 78.5c-1.1-.158-1.24 2.922-.055 3.1s1.151-2.936.055-3.1z"
                data-name="Path 56159"
                transform="translate(-1195.787 -71.418)"
              ></path>
              <path
                d="M1231.265 79.4c-1.1-.158-1.24 2.922-.055 3.1s1.151-2.94.055-3.1z"
                data-name="Path 56160"
                transform="translate(-1194.548 -71.724)"
              ></path>
              <path
                d="M1227.637 80.3c-1.1-.159-1.24 2.921-.055 3.1s1.151-2.944.055-3.1z"
                data-name="Path 56161"
                transform="translate(-1193.308 -72.03)"
              ></path>
              <path
                d="M1224.009 81.194c-1.1-.159-1.241 2.922-.055 3.1s1.146-2.941.055-3.1z"
                data-name="Path 56162"
                transform="translate(-1192.069 -72.336)"
              ></path>
              <path
                d="M1220.381 82.09c-1.1-.158-1.24 2.922-.055 3.1s1.151-2.941.055-3.1z"
                data-name="Path 56163"
                transform="translate(-1190.83 -72.642)"
              ></path>
              <path
                d="M1216.753 82.987c-1.1-.159-1.24 2.921-.055 3.1s1.151-2.942.055-3.1z"
                data-name="Path 56164"
                transform="translate(-1189.591 -72.949)"
              ></path>
              <path
                d="M1213.125 83.883c-1.1-.159-1.24 2.921-.055 3.1s1.151-2.941.055-3.1z"
                data-name="Path 56165"
                transform="translate(-1188.351 -73.255)"
              ></path>
              <path
                d="M1209.5 84.779c-1.1-.159-1.241 2.921-.056 3.1s1.149-2.941.056-3.1z"
                data-name="Path 56166"
                transform="translate(-1187.112 -73.561)"
              ></path>
              <path
                d="M1205.869 85.675c-1.1-.159-1.241 2.922-.056 3.1s1.152-2.941.056-3.1z"
                data-name="Path 56167"
                transform="translate(-1185.873 -73.867)"
              ></path>
              <path
                d="M1202.24 86.572c-1.1-.159-1.241 2.921-.055 3.1s1.151-2.941.055-3.1z"
                data-name="Path 56168"
                transform="translate(-1184.633 -74.173)"
              ></path>
              <path
                d="M1198.612 87.468c-1.1-.159-1.241 2.921-.055 3.1s1.151-2.941.055-3.1z"
                data-name="Path 56169"
                transform="translate(-1183.394 -74.479)"
              ></path>
              <path
                d="M1194.984 88.364c-1.1-.159-1.241 2.921-.056 3.1s1.152-2.941.056-3.1z"
                data-name="Path 56170"
                transform="translate(-1182.155 -74.785)"
              ></path>
              <path
                d="M1191.356 89.261c-1.1-.159-1.241 2.921-.056 3.1s1.152-2.941.056-3.1z"
                data-name="Path 56171"
                transform="translate(-1180.916 -75.092)"
              ></path>
              <path
                d="M1187.728 90.157c-1.1-.159-1.241 2.921-.056 3.1s1.151-2.941.056-3.1z"
                data-name="Path 56172"
                transform="translate(-1179.676 -75.398)"
              ></path>
              <path
                d="M1184.1 91.053c-1.1-.159-1.241 2.921-.057 3.1s1.157-2.941.057-3.1z"
                data-name="Path 56173"
                transform="translate(-1178.437 -75.704)"
              ></path>
              <path
                d="M1180.472 91.95c-1.1-.159-1.242 2.921-.057 3.1s1.152-2.941.057-3.1z"
                data-name="Path 56174"
                transform="translate(-1177.198 -76.01)"
              ></path>
              <path
                d="M1176.844 92.846c-1.1-.159-1.242 2.921-.057 3.1s1.152-2.946.057-3.1z"
                data-name="Path 56175"
                transform="translate(-1175.958 -76.316)"
              ></path>
            </g>
          </g>
          <g transform="translate(1036.938 357.638)">
            <g data-name="7" transform="translate(622.793 32.873)">
              <g data-name="Group 2550" transform="translate(0 253.023)">
                <g data-name="Group 2549">
                  <path
                    fill="#093454"
                    d="M2520.742 991.716c.388.321 9.038.137 17.4-.288.982-.05 3.7-1.732 4.656-1.788.944-.055.137 1.516 1.033 1.455 5.61-.379 10.024-.851 10.007-1.318a27.079 27.079 0 00-3.542-12.063c-2.02.586-7.313.252-7.551-.336a76.829 76.829 0 01-9.425 7.985c-2.901 1.637-12.074 2.805-12.578 6.353z"
                    data-name="Path 56179"
                    transform="translate(-2520.742 -977.378)"
                  ></path>
                </g>
              </g>
              <g data-name="Group 2552" transform="translate(12.446 253.894)">
                <g data-name="Group 2551">
                  <path
                    fill="#0a3c60"
                    d="M2539.645 992.466c.363.348 8.973.917 16.635-.1.974-.129 3.813-1.453 4.775-1.438.946.015.024 1.521.923 1.528 5.622.038 10.06-.105 10.077-.572a27.078 27.078 0 00-2.638-12.293c-2.058.435-7.312-.29-7.506-.895a76.909 76.909 0 01-9.99 7.265c-3.011 1.426-11.51 3.004-12.276 6.505z"
                    data-name="Path 56180"
                    transform="translate(-2539.645 -978.7)"
                  ></path>
                </g>
              </g>
              <path
                fill="#232b59"
                d="M2546.4 606.737c0-11.23 10.969-16.3 18.429-12.266 3.336 1.805 6.133 7.18 4.508 10.756 4.688-3.658 10.968-.406 10.947 6.07-.018 5.9-7.866 15.825-18.273 12.139-6.055-2.144-15.611-9.836-15.611-16.699z"
                data-name="Path 56181"
                transform="translate(-2529.505 -593.095)"
              ></path>
              <path
                fill="#265a85"
                d="M2541.92 896.292c.267-6.45-6.5-50.317-11.55-71.069-2.456-10.089-13.853-51.153 1.4-78.9 1.209-2.2 16.476-5.1 21.232-7.311 1.985 7.6 7.766 14.66 9.362 22.366 2.042 9.869-13.1 63.66-13.8 63.711 3.363 5.55 9.586 13.383 10.8 19.98 1.951 10.632-2.178 37.972-7.532 52.182-.177.486-9.837 1.07-9.912-.959z"
                data-name="Path 56182"
                transform="translate(-2521.846 -642.937)"
              ></path>
              <path
                fill="#2e6c9e"
                d="M2560.412 900.789c.332-6.447-5.533-52.013-10.374-72.816-2.355-10.113-16.944-62.141-4.026-79.312 1.509-2.006 19.433.317 24.212-1.847 1.908 7.619 11.538 17.739 12.907 25.488 2.665 15.073-12.479 40.922-11.139 56.252.494 5.656 5.7 12.951 6.843 19.56 1.843 10.65-3.02 39.582-8.517 53.737-.186.481-9.852.968-9.906-1.062z"
                data-name="Path 56183"
                transform="translate(-2527.573 -645.601)"
              ></path>
              <path
                fill="#265a85"
                d="M2555.415 654.842c2.931 1.319 12.578 8.068 14.048 11.427 3.661 8.363-7.171 34.637-7.171 43.949 0 5.966 13.838 20.772 15.162 26.662 1.748 7.771-53.609 9.3-53.746 8.047-2.78-25.279 6.777-31.637 5.333-48.219-.369-4.232-3.794-11.894-3.794-16.7 0-9.707 15.568-20.305 18.072-23.48 2.32-2.939 4.31-5.189 12.096-1.686z"
                data-name="Path 56184"
                transform="translate(-2521.581 -613.577)"
              ></path>
              <path
                fill="#f3a088"
                d="M2562.98 625.877c.437 6.849 3.756 19.1 4.161 21.855.5 3.415-17.562 9.463-19.109 8.691-.94-.469 2.838-8.093 3.508-9.468.572-1.177 2.939-9.749 3.718-18.915.619-7.306 7.542-4.956 7.722-2.163z"
                data-name="Path 56185"
                transform="translate(-2530.012 -603.368)"
              ></path>
              <path
                fill="#f7b690"
                d="M2549.8 604.932c-6.127 10.078-3.155 23.927 1.036 25.257 3.436 1.091 14.581-3.182 14.017-11.166a3.607 3.607 0 003.313-4.707c-1.042-2.24-2.619-.1-3.477.041-2.039-7.2-14.889-9.425-14.889-9.425z"
                data-name="Path 56186"
                transform="translate(-2529.474 -597.138)"
              ></path>
              <path
                fill="#f7b690"
                d="M2589.808 708.939a113.112 113.112 0 01-5.324 32.046c-2.377 7.022-22 28.549-22.969 29.481s-.706-7.033-.706-7.033 5.683-24.02 9.751-29.79c1.786-2.533 3.927-33.729 3.927-33.729z"
                data-name="Path 56187"
                transform="translate(-2534.417 -629.581)"
              ></path>
              <path
                fill="#2e6c9e"
                d="M2582.153 667.6c10.187 2.139 13.867 27.937 13.3 30.523-1.38 6.279-22.94 6.643-24.411 2.507-1.712-4.813 1.406-35.068 11.111-33.03z"
                data-name="Path 56188"
                transform="translate(-2537.77 -618.51)"
              ></path>
              <path
                fill="#f7b690"
                d="M2561.586 799.757c1.3 7.725-3.619 9.188-3.6 10.814s.43 5.7-.369 7.023c-.983 1.635-6.427-6.118-6.437-8.757-.018-4.877 6.348-12.452 6.348-12.452z"
                data-name="Path 56189"
                transform="translate(-2531.14 -662.533)"
              ></path>
            </g>
            <g data-name="6" transform="translate(488.673 12.97)">
              <path
                fill="#f7f6f1"
                d="M2396.025 844.97h20.875l-10.437-34.07z"
                data-name="Path 56190"
                transform="translate(-2344.023 -647.587)"
              ></path>
              <path
                fill="#f7f6f1"
                d="M2458.132 724.583c11.979 25.373 12.47 85.275 10.194 88.293-2.75 3.647-18.181 1.823-18.181 1.823l-7.233-52.929s10.932-46.27 15.22-37.187z"
                data-name="Path 56191"
                transform="translate(-2360.038 -617.701)"
              ></path>
              <g data-name="Group 2556" transform="translate(0 111.458)">
                <path
                  fill="#3c79a7"
                  d="M2346.779 991.756c1.2-.194 1.035 2.73.6 2.962-2.931 1.553-10.144 1.759-15.82 2.163-6.63.471-13.447.118-14.017-.879a2.427 2.427 0 011.181-3.8c1.09-.526 16.127-1.456 16.471-1.366s11.585.92 11.585.92z"
                  data-name="Path 56192"
                  transform="translate(-2317.046 -820.506)"
                ></path>
                <path
                  fill="#f7b690"
                  d="M2347.993 960.017c-.245 4.375-2.692 12.634-3.465 14.258-.3.64-3.551 4.328-1.774 6.217 2.729 2.9 9.575 3.294 11.33-3.548-1.793-7.276 3.116-13.722 3.116-15.886s-9.077-3.358-9.207-1.041z"
                  data-name="Path 56193"
                  transform="translate(-2325.647 -809.55)"
                ></path>
                <path
                  fill="#f7b690"
                  d="M2459.524 962.136c2.079 5.419 1.351 10.9.728 12.449-.309.767-3.066 9.446 4.626 7.059 5-1.553 5.309-2.229 4.793-3.34-2.493-5.368-.8-13.66-.8-15.725s-10.113-2.44-9.347-.443z"
                  data-name="Path 56194"
                  transform="translate(-2365.698 -810.254)"
                ></path>
                <path
                  fill="#3c79a7"
                  d="M2467.541 991.157c.975-.219.62 3.193.354 3.546s-5.514 1.526-11.138 1.381c-5.091-.13-8.324-.4-8.735-1.346-.21-.482.113-2.692 1.1-3.322.873-.556 9.279-1.078 9.562-1s8.857.741 8.857.741z"
                  data-name="Path 56195"
                  transform="translate(-2361.762 -820.36)"
                ></path>
                <g data-name="Group 2555" transform="translate(20.508)">
                  <g data-name="Group 2554">
                    <g clipPath="url(#clip-path-2)" data-name="Group 2553">
                      <path
                        fill="#001b44"
                        d="M0 0H50.685V163.515H0z"
                        data-name="Rectangle 61"
                      ></path>
                      <path
                        fill="#001b44"
                        d="M2403.995 784.021a23.147 23.147 0 013.081.5h-.6v78.871h.618q-.729.274-1.469.5a24.223 24.223 0 01-3 .725 23.246 23.246 0 01-6.186.251c-16.743-1.564-28.626-20.93-26.541-43.255s17.354-39.156 34.097-37.592z"
                        data-name="Path 56196"
                        transform="translate(-2355.528 -749.831)"
                      ></path>
                      <path
                        fill="#001a43"
                        d="M2404.57 786.946a22.183 22.183 0 012.934.475h-.269v74.547h1.665a22.829 22.829 0 01-11.521 1.976c-15.946-1.489-27.263-19.933-25.277-41.2s16.523-37.286 32.468-35.798z"
                        data-name="Path 56197"
                        transform="translate(-2356.283 -750.831)"
                      ></path>
                      <path
                        fill="#001943"
                        d="M2405.145 789.873a21 21 0 012.787.45q.682.159 1.351.361h-1.293v70.46h1.269a21.7 21.7 0 01-10.944 1.877c-15.148-1.415-25.9-18.936-24.013-39.136s15.698-35.427 30.843-34.012z"
                        data-name="Path 56198"
                        transform="translate(-2357.037 -751.833)"
                      ></path>
                      <path
                        fill="#001842"
                        d="M2405.72 792.8a19.906 19.906 0 012.64.427q.647.15 1.28.342h-.9v66.751h.873a20.55 20.55 0 01-10.369 1.778c-14.351-1.34-24.537-17.94-22.75-37.075s14.875-33.565 29.226-32.223z"
                        data-name="Path 56199"
                        transform="translate(-2357.792 -752.834)"
                      ></path>
                      <path
                        fill="#001741"
                        d="M2406.295 795.725a18.831 18.831 0 014.884 1.127h-1.68v62.642h.477a19.419 19.419 0 01-9.793 1.68c-13.554-1.266-23.173-16.943-21.486-35.017s14.044-31.698 27.598-30.432z"
                        data-name="Path 56200"
                        transform="translate(-2358.547 -753.835)"
                      ></path>
                      <path
                        fill="#001641"
                        d="M2406.87 798.65a17.759 17.759 0 014.6 1.061h-1.213v58.957h.08a18.272 18.272 0 01-9.217 1.581c-12.757-1.191-21.81-15.947-20.221-32.956s13.215-29.834 25.971-28.643z"
                        data-name="Path 56201"
                        transform="translate(-2359.302 -754.836)"
                      ></path>
                      <path
                        fill="#001540"
                        d="M2407.445 801.577a16.6 16.6 0 014.309.994h-.745V856.8h1.69a19.939 19.939 0 01-2.006 1.039 17.13 17.13 0 01-8.64 1.482c-11.96-1.117-20.448-14.95-18.958-30.9s12.391-27.961 24.35-26.844z"
                        data-name="Path 56202"
                        transform="translate(-2360.056 -755.837)"
                      ></path>
                      <path
                        fill="#00143f"
                        d="M2408.02 804.5a15.469 15.469 0 014.022.928h-.279v50.617h1.16a18.476 18.476 0 01-1.873.97 15.985 15.985 0 01-8.064 1.383c-11.162-1.042-19.084-13.953-17.694-28.837s11.566-26.101 22.728-25.061z"
                        data-name="Path 56203"
                        transform="translate(-2360.811 -756.838)"
                      ></path>
                      <path
                        fill="#00133e"
                        d="M2408.595 807.429a14.386 14.386 0 013.735.862 14.992 14.992 0 011.741.784h-1.553v46.218h.63a17.141 17.141 0 01-1.739.9 14.845 14.845 0 01-7.489 1.285c-10.365-.969-17.721-12.957-16.43-26.778s10.74-24.239 21.105-23.271z"
                        data-name="Path 56204"
                        transform="translate(-2361.566 -757.839)"
                      ></path>
                      <path
                        fill="#00123e"
                        d="M2409.17 810.355a13.249 13.249 0 013.448.8 13.772 13.772 0 011.607.724h-.951v41.638h1.637a16.628 16.628 0 01-3.142 1.855 13.7 13.7 0 01-6.912 1.186c-9.568-.893-16.358-11.96-15.166-24.717s9.909-22.379 19.479-21.486z"
                        data-name="Path 56205"
                        transform="translate(-2362.321 -758.84)"
                      ></path>
                      <path
                        fill="#00113d"
                        d="M2409.745 813.281a12.557 12.557 0 016.026 2.241h-1.743v37.32h.979a15.273 15.273 0 01-2.879 1.7 12.56 12.56 0 01-6.337 1.086c-8.77-.819-14.994-10.963-13.9-22.657s9.083-20.509 17.854-19.69z"
                        data-name="Path 56206"
                        transform="translate(-2363.075 -759.841)"
                      ></path>
                      <path
                        fill="#000f3c"
                        d="M2410.32 816.207a11.417 11.417 0 015.478 2.037h-1.015v33.928h.321a13.9 13.9 0 01-2.618 1.545 11.421 11.421 0 01-5.761.988c-7.973-.745-13.631-9.967-12.638-20.6s8.26-18.643 16.233-17.898z"
                        data-name="Path 56207"
                        transform="translate(-2363.83 -760.842)"
                      ></path>
                      <path
                        fill="#000e3c"
                        d="M2410.895 819.133a10.275 10.275 0 014.93 1.834h-.288v28.6h1.783c-2.644 2.916-6.073 4.551-9.661 4.216-7.176-.67-12.269-8.97-11.375-18.538s7.435-16.782 14.611-16.112z"
                        data-name="Path 56208"
                        transform="translate(-2364.585 -761.843)"
                      ></path>
                      <path
                        fill="#000d3b"
                        d="M2411.47 822.059a9.136 9.136 0 014.383 1.63 11.1 11.1 0 011.829 1.6h-1.389v23.819h.889a10.358 10.358 0 01-8.587 3.748c-6.378-.6-10.905-7.974-10.111-16.479s6.608-14.914 12.986-14.318z"
                        data-name="Path 56209"
                        transform="translate(-2365.34 -762.845)"
                      ></path>
                      <path
                        fill="#000c3a"
                        d="M2412.045 824.985a9.065 9.065 0 016.777 4.614h-1.775v17.177h1.416a13.5 13.5 0 01-1.42 1.879 9.063 9.063 0 01-7.514 3.279c-5.581-.521-9.542-6.977-8.847-14.418s5.782-13.052 11.363-12.531z"
                        data-name="Path 56210"
                        transform="translate(-2366.094 -763.846)"
                      ></path>
                      <path
                        fill="#000b3a"
                        d="M2412.62 827.911a7.771 7.771 0 015.809 3.955h-.627V844.7h1.3a12.4 12.4 0 01-2.2 3.5 7.769 7.769 0 01-6.441 2.811c-4.784-.447-8.179-5.98-7.583-12.359s4.959-11.188 9.742-10.741z"
                        data-name="Path 56211"
                        transform="translate(-2366.849 -764.847)"
                      ></path>
                      <path
                        fill="#000a39"
                        d="M2413.195 830.838c3.987.372 6.816 4.983 6.32 10.3s-4.131 9.323-8.117 8.951-6.815-4.983-6.32-10.3 4.131-9.324 8.117-8.951z"
                        data-name="Path 56212"
                        transform="translate(-2367.604 -765.848)"
                      ></path>
                      <ellipse
                        cx="7.733"
                        cy="5.8"
                        fill="#000938"
                        data-name="Ellipse 1105"
                        rx="7.733"
                        ry="5.8"
                        transform="rotate(-84.664 63.984 19.921)"
                      ></ellipse>
                      <ellipse
                        cx="5.8"
                        cy="4.35"
                        fill="#000837"
                        data-name="Ellipse 1106"
                        rx="5.8"
                        ry="4.35"
                        transform="rotate(-84.67 63.79 18.13)"
                      ></ellipse>
                      <path
                        fill="#000737"
                        d="M2414.92 839.615c1.595.149 2.727 1.993 2.528 4.12s-1.652 3.729-3.247 3.58-2.727-1.993-2.528-4.119 1.652-3.73 3.247-3.581z"
                        data-name="Path 56213"
                        transform="translate(-2369.868 -768.851)"
                      ></path>
                      <path
                        fill="#000636"
                        d="M2415.5 842.542a1.745 1.745 0 011.264 2.06c-.1 1.063-.826 1.865-1.624 1.79a1.745 1.745 0 01-1.264-2.06c.095-1.064.824-1.865 1.624-1.79z"
                        data-name="Path 56214"
                        transform="translate(-2370.623 -769.852)"
                      ></path>
                    </g>
                  </g>
                </g>
                <path
                  fill="#001b44"
                  d="M2433.046 752.272c7.934 24.673 2.6 58.883 2.61 76.946 5.853 4.447 11.472 9.979 12.217 12.54 4.729 16.249-1.684 52.8-1.684 54.306-1.078 2.01-9.558 2.443-9.465-.965-6.487-41.443-15.88-50.112-19.237-58.982-13.683-36.146-24.635-76.924-24.635-76.924z"
                  data-name="Path 56216"
                  transform="translate(-2342.939 -739.02)"
                ></path>
                <path
                  fill="#f7f6f1"
                  d="M2458.144 977.843c-1.115.039-2.73 2.518-3.625 5.125-1.042 1.1-2.94 1.5-3.93 2.968-.689 1.023-1.558 2.821-1.185 3.307 1.3 1.695 17.837 1.41 18.43-.117.772-1.986-.844-3.8-1.077-5.759-.192-1.616.012-2.527-.463-3.356-.6-1.053-1.261.217-1.96.354-1.634.317-4.359-2.586-6.19-2.522z"
                  data-name="Path 56217"
                  transform="translate(-2362.225 -816.068)"
                ></path>
                <path
                  fill="#f7f6f1"
                  d="M2347.195 988.647c-.475 1.677-24.55 4.391-28 2.151-1.835-1.189-.2-2.549 1.873-3.422 2.416-1.013 8.269-3.43 10.516-4.429a4.916 4.916 0 002.969-2.926c.281-.579.7-1.048.949-.87.741.531.891 2.36.574 3.809-.31 1.418 8.99-.5 9.664-1.632a.322.322 0 01.584.072c.523 1.738 1.301 5.746.871 7.247z"
                  data-name="Path 56218"
                  transform="translate(-2317.506 -816.502)"
                ></path>
              </g>
              <path
                fill="#f7f6f1"
                d="M2358.769 638.964c-2.36.316-11.13 40.777-12.686 46.3-1.643 5.832-14.229 40.9-14.261 42.323-.043 1.891 16.11 4.143 17.8 2.53 1.719-1.64 15.011-54.3 19.542-63.793 5.314-11.13-3.69-28.258-10.395-27.36z"
                data-name="Path 56219"
                transform="translate(-2322.093 -588.848)"
              ></path>
              <path
                fill="#3c79a7"
                d="M2406.7 620.29c6.35 1.179 29.467 3.911 37.305 18.1 3.3 5.977-17.375 52.477-16.515 56.872s2.183 26.356 1.269 27.552c-3.631 4.749-47.675 11.023-54.2 1.179-1.85-2.792 1.884-25.413 1.159-30.311-.633-4.279-6.478-52.783.211-61.556 5.54-7.27 30.771-11.836 30.771-11.836z"
                data-name="Path 56220"
                transform="translate(-2336.072 -582.481)"
              ></path>
              <path
                fill="#f7f6f1"
                d="M2405.98 566.193c-1.929 2.5-1.3 15.721.507 16.621 4.068 2.031 17.667 5.405 20.56 4.333s5.345-11.639 3.816-17.851c-1.944-7.896-21.099-8.011-24.883-3.103z"
                data-name="Path 56221"
                transform="translate(-2347.021 -562.867)"
              ></path>
              <path
                fill="#f3a088"
                d="M2427.983 586.667c.032 9.662 1.109 23.761 1.638 25.548s-6.833 4.85-11.856 4.346c-6.927-.695-10.7-3.746-10.528-5.313s3.707-13.008 3.707-13.008l6.711-11.411z"
                data-name="Path 56222"
                transform="translate(-2347.851 -570.996)"
              ></path>
              <path
                fill="#f7b690"
                d="M2414.606 567.229c-5.152.436-9.448 2.229-8.737 11.223s1.2 11.189 3.891 15.789 14.485-.655 15.12-.772 2.149-9.165 3.195-10.807 3.251-4.25 2.559-5.707-2.923.286-2.923.286 2.048-11.291-13.105-10.012z"
                data-name="Path 56223"
                transform="translate(-2347.359 -564.323)"
              ></path>
              <path
                fill="#f7f6f1"
                d="M2412.074 589.939c-1.314-.216-5.876 1.128-6.869 2.093s.2 10.256 2.221 11.719a22.058 22.058 0 007.739 3.157c1.705.186 7.235-2.84 8.3-3.479s4.623-12.188 4.072-15.324.909-6.829-.25-6.266-1.233 9.193-3.042 10.492-9.845-2.01-12.171-2.392z"
                data-name="Path 56224"
                transform="translate(-2347.036 -569.328)"
              ></path>
              <path
                fill="#f7f6f1"
                d="M2438.543 620.2c-1.352 1.334-11.927 71.674 9.518 106.845 10.293-4.739 6.961-16.727 6.961-16.727s-1.171-29.166 4.19-44.607 8.328-28.927 6.325-32.168c-1.217-1.97-25.151-15.159-26.994-13.343z"
                data-name="Path 56225"
                transform="translate(-2357.25 -582.392)"
              ></path>
              <path
                fill="#f7f6f1"
                d="M2458.89 660.093c-.537 8.975 7.827 24.21 7.04 31.01-.876 7.569-12.033 36.823-12.033 36.823s11.195 9.019 13.66 8.083c5.619-2.134 16.993-29.691 18.09-40.709.795-7.988-2.173-45.385-13.569-54.96-2.771-2.329-12.324 5.297-13.188 19.753z"
                data-name="Path 56226"
                transform="translate(-2363.79 -589.188)"
              ></path>
              <path
                fill="#f7b690"
                d="M2449.525 779.846c-.424 1.376 5.7 10.544 6.31 10.059 3.753-2.975 4.516-10.706 4.4-12.546-.132-2.045-3.2-4.8-4.825-4.289-2.16.677-5.346 5.025-5.885 6.776z"
                data-name="Path 56227"
                transform="translate(-2362.29 -634.645)"
              ></path>
              <path
                fill="#f7f6f1"
                d="M2388.251 620.172c-1.1-1.817-18.569 7.718-23.292 12.307-.969.942-.339 29.849-3.768 61.342-4.022 36.94-12.044 77.439-11.183 80.537 1.073 3.857 23.3 6.216 25.229 4.319 1.754-1.722 4.719-61.915 8.042-107.794 1.944-26.829 5.645-49.599 4.972-50.711z"
                data-name="Path 56228"
                transform="translate(-2328.283 -582.363)"
              ></path>
              <g data-name="Group 2557" transform="translate(45.661 42.7)">
                <path
                  fill="#97c4de"
                  d="M2392.736 695.74a.382.382 0 01-.36-.256c-1.249-3.592-5.667-31.364-4.923-33.137a7.427 7.427 0 011.935-2.114c1.374-1.21 3.082-2.716 2.961-4.28-.086-1.111-1.073-1.271-2.688-1.4-1.274-.1-2.591-.208-3.07-1.287s-.017-5.659.807-13.064c.527-4.741 1.073-9.642 1.037-12.1a.381.381 0 01.375-.386.359.359 0 01.386.376c.036 2.5-.511 7.426-1.042 12.19-.61 5.483-1.3 11.7-.868 12.671.295.664 1.334.748 2.435.836 1.445.116 3.245.261 3.388 2.1.15 1.943-1.717 3.588-3.217 4.909a7.415 7.415 0 00-1.736 1.838c-.543 1.526 3.566 28.638 4.94 32.594a.381.381 0 01-.36.506z"
                  data-name="Path 56229"
                  transform="translate(-2386.395 -627.719)"
                ></path>
              </g>
              <g data-name="Group 2558" transform="translate(80.541 44.756)">
                <path
                  fill="#97c4de"
                  d="M2439.75 698.626a.381.381 0 01-.381-.375c-.022-1.418 1.582-8.118 3.28-15.212 1.614-6.74 3.442-14.379 3.291-15.295-.109-.653-1.33-1.9-2.511-3.112-2.139-2.189-4.352-4.454-3.089-5.882 1.568-1.769 5.7-.687 7.09-.257.317-3.057 2.205-19.364 7.189-27.471a.381.381 0 01.649.4c-5.3 8.625-7.11 27.428-7.128 27.617a.379.379 0 01-.174.286.385.385 0 01-.333.037c-1.443-.513-5.463-1.53-6.723-.106-.794.9 1.344 3.085 3.063 4.844 1.379 1.412 2.57 2.63 2.717 3.519.165.995-1.136 6.556-3.3 15.6-1.613 6.736-3.28 13.7-3.259 15.022a.381.381 0 01-.375.387z"
                  data-name="Path 56230"
                  transform="translate(-2439.369 -630.841)"
                ></path>
              </g>
              <path
                fill="#f7b690"
                d="M2338.381 773.1c-1.394.845-4.746 9.024-4.932 11.648-.1 1.425 4.007 7.927 5.19 7.8s-.395-5.372 0-7.222 2.854-5.512 4.123-5.522 1.012 2.522.943 3.885.194 3.6.72 3.731 1.052-3.14 1.578-3.608 2.235-4.544 2.1-5.99-1.505-4.629-2.863-5.26c-1.556-.733-5.387-.362-6.859.538z"
                data-name="Path 56231"
                transform="translate(-2322.648 -634.359)"
              ></path>
            </g>
            <g data-name="5" transform="translate(401.929 25.421)">
              <path
                fill="#f7f6f1"
                d="M2249.593 703.407c-5.906-13.134-4.09-26.333-4.471-29.781-1.127-10.188-15.118-22.068-15.118-22.068-2.832 1.168-9.536 4.652-12.352 8.088-2.543 3.1.56 27.05-1.877 35.063-1.1 3.634-16.91 72.372-14.535 75.918 12.025 17.959 76.084 8.687 76.084-.322-.001-8.705-16.189-41.231-27.731-66.898z"
                data-name="Path 56232"
                transform="translate(-2190.662 -605.613)"
              ></path>
              <g data-name="Group 2561" transform="translate(0 100.329)">
                <g data-name="Group 2559" transform="translate(59.76 152.798)">
                  <path
                    fill="#f7b690"
                    d="M2285.658 967.313a22.229 22.229 0 01.6 11.9c-.257.637-2.544 7.837 3.838 5.857 4.153-1.289 3.989-4.445 3.81-4.922-1.65-4.38-.5-10.928-.5-12.473.009-1.708-8.384-2.02-7.748-.362z"
                    data-name="Path 56233"
                    transform="translate(-2279.329 -966.22)"
                  ></path>
                  <path
                    fill="#072b45"
                    d="M2292.31 993.784c.809-.182.514 2.649.294 2.943s-4.575 1.266-9.242 1.146c-4.224-.108-6.907-.334-7.248-1.117a3.238 3.238 0 01.914-2.756c.724-.461 7.7-.894 7.934-.831s7.348.615 7.348.615z"
                    data-name="Path 56234"
                    transform="translate(-2276.063 -975.422)"
                  ></path>
                  <path
                    fill="#0a3c5f"
                    d="M2284.512 982.736c-.925.032-2.266 2.089-3.008 4.252-.864.914-2.439 1.242-3.261 2.462-.572.849-1.292 2.341-.983 2.744 1.077 1.406 14.8 1.169 15.292-.1.64-1.648-.7-3.156-.893-4.778-.178-1.487 0-2.231-.521-3.017-.163-.243-.57-.1-.556.371.032 1.027.189 2.842-1.294 3.05-1.279-3.77-3.255-5.036-4.776-4.984z"
                    data-name="Path 56235"
                    transform="translate(-2276.447 -971.861)"
                  ></path>
                </g>
                <g data-name="Group 2560" transform="translate(0 151.284)">
                  <path
                    fill="#f7b690"
                    d="M2200.573 965.053a50.616 50.616 0 01-2.706 11.747c-.256.637-2.544 7.838 3.839 5.857a15.69 15.69 0 002.779-1.115c1.734-.936 1.276-3.235 1.147-3.654-1.341-4.355 1.777-10.892 1.777-12.454 0-1.713-6.664-2.149-6.836-.381z"
                    data-name="Path 56236"
                    transform="translate(-2189.386 -963.921)"
                  ></path>
                  <path
                    fill="#072b45"
                    d="M2202.452 991.306c.81-.182.514 2.649.294 2.943s-3.932 1.266-8.6 1.146c-4.224-.109-8.336-.368-8.677-1.151-.175-.4-.408-2.2.413-2.723.724-.461 9.629-.894 9.864-.831s6.706.616 6.706.616z"
                    data-name="Path 56237"
                    transform="translate(-2185.301 -973.062)"
                  ></path>
                  <path
                    fill="#0a3c5f"
                    d="M2195.372 980.257c-.924.044-2.673 3.365-3.65 4.252-.931.845-3.726 1.242-4.549 2.462a3.253 3.253 0 00-.36 2.759c1.077 1.406 15.464 1.154 15.956-.113.64-1.647.081-2.727-.113-4.35-.177-1.487-.353-2.049-.456-2.989-.04-.362-.411-.533-.67.067-.407.944-.787 2.678-1.381 2.9-.871.219-.228-5.206-4.777-4.988z"
                    data-name="Path 56238"
                    transform="translate(-2185.759 -969.498)"
                  ></path>
                </g>
                <path
                  fill="#2e6c9e"
                  d="M2257.74 826.014c3.412-9.109 13.564-46.161 9.3-63.911-1.466-6.1-12.034-22.139-17.485-27.948h-25.913s-15.084 52.79-19.824 86.773c-.461 3.3.138 14.277-.369 20.742-1.006 12.838-.94 34.182-2.929 45.8.35 3.237 7.964 4.03 8.312 2.232 2.878-14.882 16.377-35.458 16.9-48.516.117-2.912-1.125-9.605-2.536-14 5.731-14.164 10.631-36.275 13.06-49.134 1.693 12.675 3.74 35.426 5.345 50.371.484 4.505.865 12.936 2.038 17.478 1.981 7.677 7.69 16.615 12.915 44.74-.078 3.608 7.424 1.465 8.319-.662 0-1.6 4.08-37.077 1.415-50.7-.536-2.732-3.688-8.558-8.548-13.265z"
                  data-name="Path 56239"
                  transform="translate(-2190.499 -734.155)"
                ></path>
              </g>
              <path
                fill="#3c79a7"
                d="M2257.06 652.033c2.9 1.3 6.591 6.16 7.881 9.385.853 2.133-5.774 22.732-1.761 40.586 1.729 7.688-27.676 4.257-27.544 3.018 1.126-10.6 2.574-49.684 6.2-54.384 2.264-2.938 7.521-2.071 15.224 1.395z"
                data-name="Path 56240"
                transform="translate(-2202.494 -604.692)"
              ></path>
              <g data-name="Group 2562" transform="translate(31.884)">
                <path
                  fill="#232b59"
                  d="M2265.564 602.133c3.495-2.584 4.831-8.053 2.453-9.936a4.347 4.347 0 00-4.826.072 8.142 8.142 0 001.474-2.407 1.924 1.924 0 00-.7-2.377c-.572-.433-2.654-.8-5.587 1.15.922-.868 1.324-2.96.44-3.884-1.75-1.829-5.067-.08-6.651 1.181.82-1.946.619-3.675-.467-4.036-1.777-.593-4.825 1.045-6.411 4.537.17-.7.219-2.527-.882-3.334-1.074-.789-2.759.622-3 1.933-1.015-2.663-6.018-.821-4.892 3.981-2.412-.982-4.32 4.142-.718 7.52a2.088 2.088 0 01-1.349-1.116c-2.349 4.479 1.557 9.653 4.511 10.271 4.447 4.186 3.031 6.557 8.9 9.591 4.162 2.152 7.654.834 8.131-.625a4.7 4.7 0 005.764-5.73c1.606.2 3.912-.221 4.757-1.6a4.215 4.215 0 00-.947-5.191z"
                  data-name="Path 56241"
                  transform="translate(-2233.725 -581.778)"
                ></path>
                <path
                  fill="#f3a088"
                  d="M2255.964 619.447c.432 6.776 2.971 19.484 4.116 21.623 1.613 3.01-7.106 5.877-10.761 4.91s-4.729-2.9-4.838-4.193 3.424-8.925 3.842-20.2c.269-7.249 7.465-4.903 7.641-2.14z"
                  data-name="Path 56242"
                  transform="translate(-2237.399 -593.728)"
                ></path>
                <path
                  fill="#f7b690"
                  d="M2241.429 598.73c-6.063 9.97-1.266 26.338 2.605 26.5 3.158.132 12.845-4.661 12.287-12.56a3.549 3.549 0 003.278-4.657c-1.333-2.6-3.439.04-3.439.04s-1.756-1.213-.643-5.9c-12.158 2.207-14.088-3.423-14.088-3.423z"
                  data-name="Path 56243"
                  transform="translate(-2235.373 -587.568)"
                ></path>
              </g>
              <path
                fill="#f7f6f1"
                d="M2265.238 652.242c2.592.567 10.924 4.957 13.191 7.638 1.841 2.178-2.15 30.227-2.106 31.525.255 7.519 27.382 75.912 25.006 79.456-6.456 9.631-23.705 8.363-26.493 7.72-5.262-12.069-15.18-89.193-14.053-99.381s4.455-26.958 4.455-26.958z"
                data-name="Path 56244"
                transform="translate(-2211.053 -605.847)"
              ></path>
              <g data-name="Group 2563" transform="translate(27.494 54.503)">
                <path
                  fill="#97c4de"
                  d="M2233.181 690.492a.381.381 0 01-.354-.24c-.872-2.191-5.208-13.2-4.849-14.655.166-.676 1.229-1.328 2.959-2.322a9.747 9.747 0 002.312-1.57c-.283-.641-3.274-3.769-6.075-6.5a.381.381 0 01.531-.546c2.881 2.8 6.316 6.3 6.316 7.032 0 .654-.977 1.246-2.7 2.239-.995.572-2.5 1.436-2.6 1.844-.207.842 2.328 7.94 4.818 14.191a.382.382 0 01-.213.5.4.4 0 01-.145.027z"
                  data-name="Path 56245"
                  transform="translate(-2227.058 -664.556)"
                ></path>
              </g>
              <g data-name="Group 2564" transform="translate(51.562 52.061)">
                <path
                  fill="#97c4de"
                  d="M2276.136 661a.378.378 0 00-.533-.074c-.753.562-1.614 1.212-2.5 1.9-3.4 2.635-7.224 5.814-7.138 6.75.051.592 1.066 1.077 3.739 2.216a17.577 17.577 0 012.937 1.418c-.074.887-2.814 6.023-5.406 10.395q-.35.591-.691 1.152c-1.1 1.823-2.133 3.415-2.854 4.364a.382.382 0 00.072.533.376.376 0 00.231.078.37.37 0 00.3-.15c.537-.7 1.354-1.94 2.287-3.442.866-1.4 1.834-3.018 2.758-4.637 2.212-3.865 4.187-7.712 4.053-8.465-.088-.5-.951-.909-3.388-1.948a17.61 17.61 0 01-3.265-1.606c.282-.705 3.311-3.305 6.76-5.994.842-.656 1.7-1.316 2.563-1.956a.384.384 0 00.075-.534z"
                  data-name="Path 56246"
                  transform="translate(-2263.612 -660.846)"
                ></path>
              </g>
              <path
                fill="#f7f6f1"
                d="M2267.058 696.558c-3.779 1.177-18.7-3.4-25.959-3.712-.942-.04-7.871 4.683-8.754 6.935 9.88 9.988 40.709 16.33 46.594 8.665 3.831-4.99.6-30.935-10.194-44.973-3.875-5.04-4.49 18.133-2.64 24.686.902 3.193 1.773 8.141.953 8.399z"
                data-name="Path 56247"
                transform="translate(-2201.37 -609.44)"
              ></path>
              <path
                fill="#f7f6f1"
                d="M2217.248 692.283c4.232-1.909 30.111-.7 32.219-.059s4.944.785 4.3 2.822c-1.275 4.021-40.7 22.829-48.583 15.381s4.738-39.186 13.048-46.585z"
                data-name="Path 56248"
                transform="translate(-2191.24 -609.809)"
              ></path>
              <path
                fill="#f7b690"
                d="M2221.081 706.366c-2.373-1.514-5.268-2.308-5.751-1.8-.391.411 2.179 2 2.929 2.4-.844-.174-5.147-.6-5.743-.019-.294.286.8 1.005 2.073 1.612a58.648 58.648 0 016.492-2.193z"
                data-name="Path 56249"
                transform="translate(-2194.58 -623.666)"
              ></path>
              <g data-name="Group 2565" transform="translate(23.745 68.073)">
                <path
                  fill="#97c4de"
                  d="M2223.57 698.98a.38.38 0 01-.29-.134c-.073-.086-1.8-2.123-1.911-3.815-.12-1.761 1.82-7.179 3.721-9.713a.381.381 0 11.609.457c-1.908 2.543-3.669 7.744-3.57 9.2.1 1.44 1.714 3.353 1.73 3.372a.381.381 0 01-.29.628z"
                  data-name="Path 56250"
                  transform="translate(-2221.364 -685.166)"
                ></path>
              </g>
              <g data-name="Group 2566" transform="translate(16.991 81.277)">
                <path
                  fill="#97c4de"
                  d="M2211.488 710.441a.381.381 0 01-.2-.7c3.662-2.3 10.551-4.2 16.38-4.514a.366.366 0 01.4.36.38.38 0 01-.359.4c-5.622.3-12.508 2.195-16.015 4.4a.379.379 0 01-.206.054z"
                  data-name="Path 56251"
                  transform="translate(-2211.106 -705.22)"
                ></path>
              </g>
              <g data-name="Group 2567" transform="translate(63.401 67.091)">
                <path
                  fill="#97c4de"
                  d="M2283.167 696.582a.374.374 0 01-.2-.057.381.381 0 01-.122-.525c1.521-2.441.825-4.51-.056-7.129a21.845 21.845 0 01-1.193-4.777.381.381 0 01.758-.08 20.954 20.954 0 001.157 4.614c.87 2.586 1.692 5.028-.019 7.774a.382.382 0 01-.325.18z"
                  data-name="Path 56252"
                  transform="translate(-2281.593 -683.674)"
                ></path>
              </g>
              <g data-name="Group 2568" transform="translate(44.469 85.736)">
                <path
                  fill="#97c4de"
                  d="M2253.219 722.573a.381.381 0 01-.162-.725c.438-.207 1.2-.531 2.171-.942 3.479-1.475 9.289-3.939 12.111-6.023a3.619 3.619 0 00-.265-2.314.381.381 0 11.653-.392c.588.981.58 2.935.117 3.28-2.876 2.148-8.787 4.654-12.318 6.152-.96.407-1.719.728-2.144.928a.378.378 0 01-.163.036z"
                  data-name="Path 56253"
                  transform="translate(-2252.839 -711.992)"
                ></path>
              </g>
              <path
                fill="#f7b690"
                d="M2272.607 707a25.41 25.41 0 017.38-2.281c.555.076 3.031 5.437 2.454 6.241s-11.629-2.76-9.834-3.96z"
                data-name="Path 56254"
                transform="translate(-2215.055 -623.769)"
              ></path>
              <g data-name="Group 2569" transform="translate(50.137 81.37)">
                <path
                  fill="#97c4de"
                  d="M2268.665 706.631c-.03 0-.7-.055-.7-.055-1.886-.15-4.734-.378-6.156-.455a.379.379 0 01-.359-.4.371.371 0 01.4-.36c1.432.077 4.286.3 6.176.456l.672.054a.381.381 0 01-.03.76z"
                  data-name="Path 56255"
                  transform="translate(-2261.447 -705.36)"
                ></path>
              </g>
              <g data-name="Group 2570" transform="translate(52.515 100.236)">
                <path
                  fill="#97c4de"
                  d="M2278.319 736.655a45.681 45.681 0 01-13-1.9.381.381 0 11.25-.72 44.182 44.182 0 0015.591 1.709.381.381 0 11.093.757 24.643 24.643 0 01-2.934.154z"
                  data-name="Path 56256"
                  transform="translate(-2265.059 -734.014)"
                ></path>
              </g>
              <path
                fill="#f7f6f1"
                d="M2205.358 801.721c6.31 1.109 16.462 3.181 18.678 0s1.258-67.566 1.258-67.566l-9.017 11.3z"
                data-name="Path 56257"
                transform="translate(-2192.152 -633.826)"
              ></path>
              <g data-name="Group 2571" transform="translate(22.489 100.125)">
                <path
                  fill="#97c4de"
                  d="M2219.836 736.271a.381.381 0 01-.055-.758c2.2-.316 8.017-1.646 8.076-1.659a.381.381 0 11.17.742c-.24.055-5.91 1.35-8.137 1.671a.371.371 0 01-.054.004z"
                  data-name="Path 56258"
                  transform="translate(-2219.456 -733.844)"
                ></path>
              </g>
              <g data-name="Group 2572" transform="translate(68.278 85.737)">
                <path
                  fill="#97c4de"
                  d="M2293.583 713.941a.38.38 0 01-.171-.041 10.667 10.667 0 00-4.042-1.146.381.381 0 01.021-.762 11.31 11.31 0 014.365 1.227.381.381 0 01-.172.722z"
                  data-name="Path 56259"
                  transform="translate(-2288.999 -711.992)"
                ></path>
              </g>
            </g>
            <g data-name="4" transform="translate(307.677 16.568)">
              <g data-name="Group 2573" transform="translate(31.52 35.97)">
                <path
                  fill="#232b59"
                  d="M2103.433 669.659a.642.642 0 01-.524-.269c-11-15.4-15.314-29.811-11.549-38.541 1.9-4.4 5.807-7.126 11.3-7.879a.643.643 0 11.175 1.275c-5.1.7-8.562 3.094-10.3 7.114-3.585 8.313.682 22.25 11.415 37.284a.643.643 0 01-.15.9.634.634 0 01-.367.116z"
                  data-name="Path 56260"
                  transform="translate(-2090.026 -622.963)"
                ></path>
              </g>
              <g data-name="Group 2574" transform="translate(52.028 37.044)">
                <path
                  fill="#232b59"
                  d="M2131.445 670.216a.619.619 0 01-.147-.017.643.643 0 01-.481-.772c4.4-18.828 1.446-32.339-2.784-38.7-1.85-2.785-4.038-4.462-6.325-4.851a.643.643 0 01.215-1.268c2.646.449 5.13 2.319 7.181 5.407 4.379 6.589 7.457 20.481 2.966 39.709a.643.643 0 01-.625.492z"
                  data-name="Path 56261"
                  transform="translate(-2121.173 -624.593)"
                ></path>
              </g>
              <path
                fill="#f3a088"
                d="M2152.2 661.913c1.959 1.639 9.653 39.412 8.493 49.428-1.63 14.065-10.4 34.216-10.4 34.216l-15.678 2.574s.915-100.178 17.585-86.218z"
                data-name="Path 56262"
                transform="translate(-2073.738 -599.841)"
              ></path>
              <g data-name="Group 2575" transform="translate(39.325 256.218)">
                <path
                  fill="#001832"
                  d="M2102.834 991.06c-1.24-.2-1.068 2.816-.616 3.055 3.024 1.6 10.466 1.815 16.32 2.231 6.84.487 12.357.679 14.46-.907 1.105-.834 1.226-2.736-1.219-3.916-1.125-.543-16.638-1.5-16.992-1.41s-11.953.947-11.953.947z"
                  data-name="Path 56263"
                  transform="translate(-2101.881 -968.616)"
                ></path>
                <path
                  fill="#f7b690"
                  d="M2117.637 958.767c.253 4.513-2.363 11.664-1.566 13.339.314.66 2.809 5.539.977 7.487-2.814 2.993-9.878 3.4-11.688-3.66 1.85-7.505 2.78-13.86 2.78-16.093s9.36-3.463 9.497-1.073z"
                  data-name="Path 56264"
                  transform="translate(-2103.069 -957.467)"
                ></path>
                <path
                  fill="#00294c"
                  d="M2103.329 986.795c.49 1.73 25.326 4.53 28.889 2.22 1.893-1.227.209-2.63-1.932-3.531-2.493-1.044-9.509-5.536-11.825-6.568-1.906-.842-3.293-4.59-4.042-3.916-.7.629-.658 4.421-.332 5.915.32 1.462.1 2.733-.553 2.931-3.65 1.111-7.476-5.049-8.462-4.756-1.272.377-2.245 5.95-1.743 7.705z"
                  data-name="Path 56265"
                  transform="translate(-2102.329 -963.428)"
                ></path>
              </g>
              <g data-name="Group 2576" transform="translate(29.067 254.244)">
                <path
                  fill="#f7b690"
                  d="M2100.867 955.918c-2.287 5.961-.735 14.5-.049 16.2.34.844 3.373 10.39-5.088 7.765-5.5-1.708-5.289-5.892-5.051-6.524 2.188-5.807-.095-14.909-.095-16.957 0-2.267 11.127-2.681 10.283-.484z"
                  data-name="Path 56266"
                  transform="translate(-2087.764 -954.469)"
                ></path>
                <path
                  fill="#072b45"
                  d="M2087.008 991.652c-1.073-.242-.681 3.511-.389 3.9s6.066 1.678 12.251 1.519c5.6-.143 9.156-.443 9.608-1.481.232-.531-.125-2.962-1.212-3.654-.959-.611-10.207-1.185-10.518-1.1s-9.74.816-9.74.816z"
                  data-name="Path 56267"
                  transform="translate(-2086.302 -966.888)"
                ></path>
                <path
                  fill="#0a3c5f"
                  d="M2098.2 977.006c1.226.043 3 2.769 3.987 5.637 1.146 1.212 3.233 1.647 4.323 3.264.758 1.125 1.713 3.1 1.3 3.638-1.428 1.864-19.621 1.551-20.272-.129-.849-2.185.928-4.183 1.185-6.334.235-1.973-.006-2.958.69-4 .215-.322.756-.128.737.492-.043 1.362-.25 3.767 1.715 4.044 1.699-5.002 4.319-6.681 6.335-6.612z"
                  data-name="Path 56268"
                  transform="translate(-2086.647 -962.167)"
                ></path>
              </g>
              <path
                fill="#224f73"
                d="M2136.426 766.847s-11.379 109.21-14.693 125.225c-.6 2.9-18.034 1.908-18.435-1.024-1.559-11.413-8.583-35.055-7.25-45.613.595-4.7 4.156-13.127 6.535-18.844 2.971-7.139-11.291-47.3-.17-78.787 3.007-8.515 34.013 19.043 34.013 19.043z"
                data-name="Path 56269"
                transform="translate(-2060.506 -629.077)"
              ></path>
              <path
                fill="#275c87"
                d="M2111.052 756.938s-8.118 119.367-10.59 135.534c-.447 2.924-23.667 3.755-24.953-2.958-2.169-11.312-1.623-32.533-.843-43.147.347-4.729 3.462-13.327 5.539-19.16 2.593-7.284-13.751-46.641-4.294-78.67 2.559-8.662 35.141 8.401 35.141 8.401z"
                data-name="Path 56270"
                transform="translate(-2052.669 -629.047)"
              ></path>
              <path
                fill="#224f73"
                d="M2147.324 776.125c-.43 3.431 2.261 8.406 4.181 8.9 0 3.2-3.106 8.471-4.75 12.758-1.786 4.656-8.6-9.113-8.6-9.113z"
                data-name="Path 56271"
                transform="translate(-2074.946 -639.308)"
              ></path>
              <path
                fill="#2e6c9e"
                d="M2095.876 630.127c10.723 1.116 26.452 9.775 30.349 21.365 2.2 6.539-2.545 75.7-2.545 75.7l-57.6 1.433s3.95-17.3 4.361-21.823c.984-10.831-4.571-45.237-11.39-57.414-4.805-8.576 27.466-20.235 36.825-19.261z"
                data-name="Path 56272"
                transform="translate(-2047.76 -589.42)"
              ></path>
              <path
                fill="#f3a088"
                d="M2115 606.392s2.872 19 2.739 19.544c-.254 1.029-1.747 11.789-3.49 12.8-2.538 1.466-16.969-10.535-17.911-12.8-.208-.5 2.469-21.787 2.469-21.787z"
                data-name="Path 56273"
                transform="translate(-2060.658 -580.566)"
              ></path>
              <g data-name="Group 2579" transform="translate(33.306)">
                <path
                  fill="#232b59"
                  d="M2117.58 588.926c1.871-10.978-.524-17.194-1.576-18.7-1.474-2.114-18.917-3.334-21.681 1.219s-1.3 15.694-.163 17.483 13.824 3.415 13.824 3.415z"
                  data-name="Path 56274"
                  transform="translate(-2092.739 -568.332)"
                ></path>
                <path
                  fill="#f7b690"
                  d="M2114.222 580.567c1.75 2.206 2.255 4.835 2.3 9.431 3.876-6.5 2.575 8.052-.3 6.6-.458 4.7-2.874 9.753-6.961 12a5.62 5.62 0 01-5.526.427c-5.045-2.358-7.43-7.885-7.921-11.92-1.428.076-3.033-4.864-2.9-7.6.094-1.877 1.531-2.713 2.307-.164-.151-5.009.369-7.693 1.434-8.781 1.344-1.372 5.4 2.771 10.978 2.791 5.225.025 5.488-4.172 6.589-2.784z"
                  data-name="Path 56275"
                  transform="translate(-2092.797 -572.415)"
                ></path>
                <g data-name="Group 2577" transform="translate(2.479 17.476)">
                  <path
                    fill="#232b59"
                    d="M2106.5 598.163a7.406 7.406 0 00-.26-2.121.974.974 0 00-.346-.539 6.379 6.379 0 00-3.075-.616 29.174 29.174 0 00-5.294.333c-.549.107-.775.209-.9.413a8.491 8.491 0 00.035 3.152 14.585 14.585 0 00.375 1.935 4.226 4.226 0 00.15.44 1.174 1.174 0 00.692.758 7.016 7.016 0 00.852.1c.965.076 2.555.15 4 .15a12.153 12.153 0 002.976-.236.637.637 0 00.2-.1 1.611 1.611 0 00.386-.853 14.663 14.663 0 00.209-2.816zm-1.174 2.967a26.2 26.2 0 01-4.6.11 39.787 39.787 0 01-1.879-.1 6.136 6.136 0 01-.7-.08 2.837 2.837 0 01-.372-1.048 16.063 16.063 0 01-.161-.88 14.849 14.849 0 01-.225-2.967 21.649 21.649 0 014.592-.388 8.193 8.193 0 013.358.429c.364.493.324 3.53.08 4.629a1.446 1.446 0 01-.092.294z"
                    data-name="Path 56276"
                    transform="translate(-2096.504 -594.875)"
                  ></path>
                </g>
                <g data-name="Group 2578" transform="translate(14.363 17.478)">
                  <path
                    fill="#232b59"
                    d="M2124.425 595.633c-.121-.2-.346-.306-.9-.413a28.281 28.281 0 00-4.506-.343c-1.657 0-3.243.155-3.862.627-.156.118-.566.435-.606 2.659a12.251 12.251 0 00.308 3.171 1.084 1.084 0 00.287.5 8.4 8.4 0 003.174.334 41.231 41.231 0 004.849-.246.812.812 0 00.439-.308 1.971 1.971 0 00.252-.45 12.657 12.657 0 00.6-2.965 6.65 6.65 0 00-.035-2.566zm-1.514 5.427a43.14 43.14 0 01-6.86.129 1.788 1.788 0 01-.324-.059c-.319-.664-.415-4.375-.009-4.924.984-.7 6.822-.408 7.934-.045a12.4 12.4 0 01-.128 2.369 7.08 7.08 0 01-.613 2.53z"
                    data-name="Path 56277"
                    transform="translate(-2114.553 -594.877)"
                  ></path>
                </g>
              </g>
              <path
                fill="#f3a088"
                d="M2053.483 656.861c-9.14 14.628-11.285 51.531-9.154 58.681 3.86 12.948 24.877 34.321 24.877 34.321l3.86-8.34s-11.626-26.22-11.58-31.452 3.01-17.113 3.01-23.084-11.013-30.126-11.013-30.126z"
                data-name="Path 56278"
                transform="translate(-2042.638 -598.571)"
              ></path>
              <path
                fill="#2e6c9e"
                d="M2042.155 692.608c.483-8.862 5.683-42.317 15.68-45.212 7.248-2.1 14.236 48.787 10.537 49.646s-26.217-4.434-26.217-4.434z"
                data-name="Path 56279"
                transform="translate(-2042.155 -595.316)"
              ></path>
              <path
                fill="#2e6c9e"
                d="M2153.608 649.871c6.925 3.378 15.364 32.973 13.693 39.889a63.556 63.556 0 01-12.286 2.9z"
                data-name="Path 56280"
                transform="translate(-2080.224 -596.184)"
              ></path>
              <g data-name="Group 2580" transform="translate(78.119 71.768)">
                <path
                  fill="#265a85"
                  d="M2161.121 699.268a.325.325 0 01-.321-.336c.367-8.065 0-21.134 0-21.265a.322.322 0 01.313-.331.3.3 0 01.331.312c0 .132.368 13.225 0 21.313a.322.322 0 01-.323.307z"
                  data-name="Path 56281"
                  transform="translate(-2160.799 -677.331)"
                ></path>
              </g>
              <circle
                cx="5.308"
                cy="5.308"
                r="5.308"
                fill="#97c4de"
                data-name="Ellipse 1107"
                transform="rotate(-45 121.158 -5.96)"
              ></circle>
              <g data-name="Group 2581" transform="translate(25.361 71.772)">
                <path
                  fill="#265a85"
                  d="M2082.551 706.541a.327.327 0 01-.321-.346c.014-.185 1.352-18.685-1.545-28.443a.322.322 0 01.617-.183c2.929 9.872 1.583 28.488 1.569 28.675a.322.322 0 01-.32.297z"
                  data-name="Path 56282"
                  transform="translate(-2080.673 -677.338)"
                ></path>
              </g>
              <path
                fill="#265a85"
                d="M2089.789 679.392c2.091 0 14.743-.161 15.011 0s.215 4.5.215 4.5h-14.8z"
                data-name="Path 56283"
                transform="translate(-2058.426 -606.243)"
              ></path>
              <g data-name="Group 2582" transform="translate(31.496 40.749)">
                <path
                  fill="#232b59"
                  d="M2103.421 672.138a.642.642 0 01-.524-.269c-12.456-17.447-16.232-33.312-9.854-41.4a.643.643 0 111.01.8c-5.917 7.508-2.034 23.154 9.892 39.859a.643.643 0 01-.15.9.634.634 0 01-.374.11z"
                  data-name="Path 56284"
                  transform="translate(-2089.99 -630.22)"
                ></path>
              </g>
              <g data-name="Group 2583" transform="translate(57.895 40.954)">
                <path
                  fill="#232b59"
                  d="M2134.489 672.244a.619.619 0 01-.147-.017.643.643 0 01-.481-.772c4.707-20.149.982-34.076-3.637-39.879a.643.643 0 011.007-.8c5.028 6.317 8.689 20.4 3.884 40.973a.643.643 0 01-.626.495z"
                  data-name="Path 56285"
                  transform="translate(-2130.084 -630.532)"
                ></path>
              </g>
              <g data-name="Group 2584" transform="translate(53.302 86.459)">
                <path
                  fill="#97c4de"
                  d="M2135.833 705.766a.322.322 0 01-.32-.295 5.98 5.98 0 00-4.354-4.9c-2.72-.82-5.445.178-7.477 2.74a.322.322 0 11-.5-.4c2.2-2.78 5.18-3.857 8.167-2.957a6.635 6.635 0 014.81 5.463.322.322 0 01-.294.348z"
                  data-name="Path 56286"
                  transform="translate(-2123.108 -699.643)"
                ></path>
              </g>
              <g data-name="Group 2585" transform="translate(52.179 81.6)">
                <path
                  fill="#232b59"
                  d="M2129.378 715.66a.483.483 0 01-.341-.824c2.689-2.69 7.088-11.931 6.038-17.5a4.4 4.4 0 00-2.512-3.489 5.368 5.368 0 00-5.394.205c-3.2 2.135-4.945 8.074-4.792 16.294a.483.483 0 01-.474.492.511.511 0 01-.491-.473c-.159-8.556 1.744-14.794 5.221-17.114a6.346 6.346 0 016.316-.287 5.312 5.312 0 013.074 4.193c1.105 5.863-3.491 15.553-6.3 18.366a.482.482 0 01-.345.137z"
                  data-name="Path 56287"
                  transform="translate(-2121.403 -692.263)"
                ></path>
              </g>
              <g data-name="Group 2586" transform="translate(44.031 80.293)">
                <path
                  fill="#232b59"
                  d="M2115.137 691.255a3.626 3.626 0 00-4.691-.22 3.151 3.151 0 00-.268.225 3.677 3.677 0 00-.8 1.1 3.6 3.6 0 00-.352 1.555 3.558 3.558 0 00.04.533 3.631 3.631 0 106.069-3.19zm-2.477 5.323a2.67 2.67 0 01-2.667-2.667 2.626 2.626 0 01.45-1.483 2.667 2.667 0 112.216 4.149z"
                  data-name="Path 56288"
                  transform="translate(-2109.028 -690.279)"
                ></path>
              </g>
              <path
                fill="#97c4de"
                d="M2121.546 719c-1.577-.241-1.469 3.592-.156 3.539s1.386-3.354.156-3.539z"
                data-name="Path 56289"
                transform="translate(-2068.876 -619.791)"
              ></path>
              <path
                fill="#97c4de"
                d="M2133.789 725.115c-1.151-1.1-3.27 2.091-2.165 2.8s3.062-1.938 2.165-2.8z"
                data-name="Path 56290"
                transform="translate(-2072.609 -621.806)"
              ></path>
              <path
                fill="#2e6c9e"
                d="M2121.637 723.71c-.041-.2.5-.242.542 0s-.502.201-.542 0z"
                data-name="Path 56291"
                transform="translate(-2069.303 -621.348)"
              ></path>
              <path
                fill="#2e6c9e"
                d="M2131.743 728.836c.055-.2.554.01.483.246s-.539-.049-.483-.246z"
                data-name="Path 56292"
                transform="translate(-2072.754 -623.129)"
              ></path>
              <path
                fill="#97c4de"
                d="M2135.671 689.026c-1.045-.188-.888.965-1.635 1.5-.531.382-2.747-.053-2.416 1.329.26 1.085 1.711.284 3.153.654 1.462.375 2.478 1.677 2.968 1.065.853-1.065-1.044-1.674-1.446-2.437s.458-1.916-.624-2.111z"
                data-name="Path 56293"
                transform="translate(-2072.702 -609.551)"
              ></path>
            </g>
            <g data-name="3" transform="translate(230.012 24.528)">
              <path
                fill="url(#radial-gradient)"
                d="M1952.226 729.591c-4.232 8.71-16.06 17.729-19.1 34.024s1.967 48.015 2.181 52.013-7.436 17.8-8.606 25.448 3.264 40.58 3.264 40.58 8.023.57 8.832-1.453c.581-1.456 12.586-50.928 14.475-62.406 1.882-11.432 10.493-77.544 10.493-77.544z"
                data-name="Path 56294"
                transform="translate(-1924.988 -631.374)"
              ></path>
              <path
                fill="url(#radial-gradient-2)"
                d="M1988.3 730.658c2.272 9.413 14.3 20.752 13.778 37.32s-5.371 44.554-6.433 48.414 4.974 15.12 4.924 25.413c-.038 7.736-11.451 39.858-11.451 39.858l-9.028-.72s.276-51.683-.045-63.264-5.3-79.073-5.3-79.073z"
                data-name="Path 56295"
                transform="translate(-1941.466 -631.738)"
              ></path>
              <path
                fill="#f7f6f1"
                d="M1978.6 664.378c-1.71-3.931-9.383-10.913-17.083-14.481-4.142-2.573-12.163-.794-15.416 1.275-8.081 4.171-12.6 8.261-15.328 14.911s-8.162 36.31-6.121 42.3a78.057 78.057 0 003.484 7.3 53.914 53.914 0 00-2.379 6.689c-2.819 16.024.481 50.177 1.852 51.105 2.456 1.663 55.405 2.2 57.534-2.517s1.578-37.176-.308-45.31c-.568-2.447-1.425-5.427-2.452-8.678 2.354-5.8 4.057-10.555 4.128-11.706.207-3.395-2.746-29.006-7.911-40.888z"
                data-name="Path 56296"
                transform="translate(-1924.201 -603.717)"
              ></path>
              <g data-name="Group 2587" transform="translate(1.555 247.23)">
                <path
                  fill="#f7b690"
                  d="M1939.989 957.505c-.483 5.784-.894 14.686-.271 16.234.309.768 3.067 9.446-4.626 7.06a14.127 14.127 0 01-4.485-2.075c-1.081-.857-.451-3.029-.273-3.471 2.23-5.52.762-15.468.762-17.379.004-2.065 9.11-2.974 8.893-.369z"
                  data-name="Path 56297"
                  transform="translate(-1927.732 -955.906)"
                ></path>
                <path
                  fill="#072b45"
                  d="M1927.323 992.422c-.976-.219-.892 3.048-.426 3.75.521.784 5.586 1.321 11.21 1.177 5.091-.13 8.324-.4 8.735-1.346.211-.483-.113-2.693-1.1-3.322-.873-.555-9.279-1.077-9.562-1s-8.857.741-8.857.741z"
                  data-name="Path 56298"
                  transform="translate(-1926.561 -968.123)"
                ></path>
                <path
                  fill="url(#radial-gradient-3)"
                  d="M1937.024 979.2c1.115.038 3.264 2.455 4.159 5.061 1.042 1.1 2.94 1.5 3.93 2.968.689 1.023 1.558 2.821 1.185 3.307-1.3 1.695-17.837 1.41-18.43-.117-.771-1.986.844-3.8 1.077-5.759.193-1.613 6.247-5.52 8.079-5.46z"
                  data-name="Path 56299"
                  transform="translate(-1926.937 -963.864)"
                ></path>
              </g>
              <g data-name="Group 2588" transform="translate(32.968 247.398)">
                <path
                  fill="#f7b690"
                  d="M1991.012 957.485c-2.091 7.506-2.931 14.793-2.308 16.341.31.768 3.067 9.446-4.625 7.06-5-1.553-4.974-5.013-4.8-5.4 2.461-5.385 2.1-14.718 2.343-17.521.178-2.054 9.964-2.541 9.39-.48z"
                  data-name="Path 56300"
                  transform="translate(-1975.964 -956.161)"
                ></path>
                <path
                  fill="#072b45"
                  d="M1975.143 992.422c-.917.569-1.088 3.083-.621 3.785.52.784 6.426 1.286 12.049 1.142 5.091-.13 7.681-.4 8.092-1.346.211-.483-.112-2.693-1.1-3.322-.873-.555-8.636-1.077-8.919-1s-9.501.741-9.501.741z"
                  data-name="Path 56301"
                  transform="translate(-1974.271 -968.291)"
                ></path>
                <path
                  fill="url(#radial-gradient-4)"
                  d="M1984.273 979.012a4.611 4.611 0 013.189 2.225c.492.7 2.766 2.825 3.012 3.542 1.042 1.1 1.552.919 2.542 2.388.689 1.023 1.558 2.821 1.185 3.307-1.3 1.695-17.837 1.41-18.43-.117-.771-1.986 1.487-3.8 1.721-5.759.193-1.617 4.948-5.644 6.781-5.586z"
                  data-name="Path 56302"
                  transform="translate(-1974.729 -963.966)"
                ></path>
              </g>
              <path
                fill="#97c4de"
                d="M1937.849 763.238s-.24 27.686 2.155 29.245 41.549 1.364 43.419.161c2.326-1.5 2.326-28.441 2.326-30.7s-45.921-1.47-47.9 1.294z"
                data-name="Path 56303"
                transform="translate(-1928.86 -641.973)"
              ></path>
              <path
                fill="#f7b690"
                d="M1980.922 786.728c-3.285 5.955-6.769 16.33-6.2 17.572s8.139 1.134 8.76.381c.554-.671 1.724-6.792 2.006-8.276.272-1.433-.519-7.149-.519-7.149z"
                data-name="Path 56304"
                transform="translate(-1941.435 -650.89)"
              ></path>
              <path
                fill="#f7b690"
                d="M1964.3 791.8c1.875 3.679 8.759 12.408 8.746 15.06-.006 1.4-7.057 2.141-8.656 1.734-1.5-.381-3.563-8.407-3.912-11.8s3.822-4.994 3.822-4.994z"
                data-name="Path 56305"
                transform="translate(-1936.584 -652.621)"
              ></path>
              <path
                fill="#232b59"
                d="M1971.551 608.577c4.5-3.095-.093-19.243-7.278-24.918s-13.37-2.457-15.731-1.021c-3.735 2.273-18.167 17.024-8.113 26.663s31.122-.724 31.122-.724z"
                data-name="Path 56306"
                transform="translate(-1928.548 -580.422)"
              ></path>
              <path
                fill="#2e6c9e"
                d="M1963.735 650.6c-2.49.4-6.729 2.037-9.065 5.04s9.353 23.858 10.318 23.858c2.038 0 10.4-21.821 9.41-24.395-.67-1.731-7.86-4.947-10.663-4.503z"
                data-name="Path 56307"
                transform="translate(-1934.503 -604.379)"
              ></path>
              <path
                fill="#f3a088"
                d="M1960.311 628.43c-.461 7.238-1.88 14.851-3.1 17.135-1.722 3.215 7.591 6.278 11.495 5.244s5.052-3.1 5.167-4.478-4.574-5.93-5.4-15.616c-.656-7.715-7.973-5.236-8.162-2.285z"
                data-name="Path 56308"
                transform="translate(-1935.403 -595.844)"
              ></path>
              <path
                fill="#f7b690"
                d="M1973.736 601.071c1.114 9.933-2.826 20.676-6.037 21.254-4.359.786-12.133-4.888-12.394-14.093a3.789 3.789 0 01-3.232-5.152c1.569-2.694 3.666.238 3.666.238s1.02-5 9.354-9.111c2.763-.391 8.375 4.478 8.643 6.864z"
                data-name="Path 56309"
                transform="translate(-1933.619 -585.123)"
              ></path>
              <g data-name="Group 2589" transform="translate(11.984 71.21)">
                <path
                  fill="#97c4de"
                  d="M1949.131 707.075a.371.371 0 01-.173-.042c-8.492-4.345-7.656-10.205-3.553-18.252a.381.381 0 11.679.346c-4.651 9.122-3.808 13.631 3.222 17.228a.381.381 0 01-.174.72z"
                  data-name="Path 56310"
                  transform="translate(-1942.402 -688.573)"
                ></path>
              </g>
              <g data-name="Group 2590" transform="translate(42.862 69.649)">
                <path
                  fill="#97c4de"
                  d="M1989.679 705.783a.381.381 0 01-.2-.707c3.433-2.073 5.409-4.673 5.874-7.728.509-3.341-.9-7.173-3.858-10.512a.381.381 0 01.57-.506c3.111 3.509 4.584 7.567 4.041 11.132-.5 3.289-2.6 6.071-6.233 8.265a.377.377 0 01-.194.056z"
                  data-name="Path 56311"
                  transform="translate(-1989.298 -686.203)"
                ></path>
              </g>
              <g data-name="Group 2591" transform="translate(3.76 112.146)">
                <path
                  fill="#97c4de"
                  d="M1934.593 759.486a.382.382 0 01-.333-.2 362.882 362.882 0 01-4.306-7.99.381.381 0 01.677-.35c.826 1.6 2.748 5.185 4.3 7.969a.381.381 0 01-.148.518.377.377 0 01-.19.053z"
                  data-name="Path 56312"
                  transform="translate(-1929.911 -750.745)"
                ></path>
              </g>
              <g data-name="Group 2592" transform="translate(14.309 90.232)">
                <path
                  fill="#97c4de"
                  d="M1952.786 743.3a.381.381 0 01-.356-.244q-.593-1.534-1.179-3.014c-3.12-7.927-5.815-14.773-5.24-22.227a.372.372 0 01.41-.35.38.38 0 01.35.409c-.562 7.279 2.1 14.05 5.19 21.889q.584 1.484 1.181 3.018a.381.381 0 01-.218.492.372.372 0 01-.138.027z"
                  data-name="Path 56313"
                  transform="translate(-1945.933 -717.464)"
                ></path>
              </g>
              <g data-name="Group 2593" transform="translate(55.545 114.261)">
                <path
                  fill="#97c4de"
                  d="M2008.941 758.727a.372.372 0 01-.157-.034.381.381 0 01-.19-.5c.237-.525.479-1.138.714-1.733.318-.805.647-1.639.978-2.289a.38.38 0 11.678.345c-.314.619-.637 1.435-.949 2.225-.237.6-.483 1.225-.728 1.767a.38.38 0 01-.346.219z"
                  data-name="Path 56314"
                  transform="translate(-2008.56 -753.958)"
                ></path>
              </g>
              <g data-name="Group 2594" transform="translate(42.971 87.554)">
                <path
                  fill="#97c4de"
                  d="M1989.844 743.974a.38.38 0 01-.354-.523c3.712-9.216 6.189-23.6 5.1-29.607a.381.381 0 11.749-.136c1.237 6.807-1.694 21.468-5.141 30.028a.382.382 0 01-.354.238z"
                  data-name="Path 56315"
                  transform="translate(-1989.463 -713.395)"
                ></path>
              </g>
              <g data-name="Group 2595" transform="translate(12.753 53.509)">
                <path
                  fill="#97c4de"
                  d="M1961.836 725.518a.38.38 0 01-.375-.314 136.173 136.173 0 01-1.794-18.1c-.111-5.274.351-11.5.688-16.053a47.573 47.573 0 00.284-6.023c-.258-.667-4.229-3.313-11.8-7.859-.61-.366-1.067-.641-1.3-.787a.749.749 0 01-.4-.671c.025-.732 1.19-1.386 3.09-2.393a9.239 9.239 0 002.486-1.587c-.146-.831-5.2-5.705-7.636-8.047-.518-.5-.992-.956-1.391-1.345a.381.381 0 11.531-.546c.4.388.871.844 1.388 1.342 4.977 4.795 7.7 7.572 7.858 8.52.116.712-.907 1.291-2.881 2.337a10 10 0 00-2.676 1.722l.034.022c.232.145.687.417 1.292.78 8.5 5.1 11.9 7.432 12.144 8.311.155.566.025 2.438-.26 6.281-.336 4.534-.8 10.743-.686 15.981a135.453 135.453 0 001.782 17.978.382.382 0 01-.375.448z"
                  data-name="Path 56316"
                  transform="translate(-1943.569 -661.689)"
                ></path>
              </g>
              <g data-name="Group 2596" transform="translate(31.792 53.509)">
                <path
                  fill="#97c4de"
                  d="M1973.027 684.043a.67.67 0 01-.352-.09.381.381 0 01.383-.659c.414 0 2.371-1.065 5.508-3.55 3.423-2.711 5.065-4.568 5.1-4.97a14.458 14.458 0 00-3.081-1.721c-2.06-.984-2.592-1.263-2.422-1.774.213-.635 3.7-5.236 9.258-9.51a.38.38 0 11.464.6 42.778 42.778 0 00-8.913 8.992 20.072 20.072 0 001.942 1c2.443 1.167 3.515 1.747 3.515 2.411 0 1.466-6.711 6.875-9.672 8.581a3.96 3.96 0 01-1.73.69z"
                  data-name="Path 56317"
                  transform="translate(-1972.485 -661.689)"
                ></path>
              </g>
              <g data-name="Group 2597" transform="translate(31.757 79.725)">
                <path
                  fill="#97c4de"
                  d="M1972.847 703.949a.379.379 0 01-.375-.321 8.842 8.842 0 010-1.766.371.371 0 01.406-.355.382.382 0 01.354.406 8.582 8.582 0 000 1.6.381.381 0 01-.318.434.359.359 0 01-.067.002z"
                  data-name="Path 56318"
                  transform="translate(-1972.432 -701.505)"
                ></path>
              </g>
              <g data-name="Group 2598" transform="translate(31.027 93.385)">
                <path
                  fill="#97c4de"
                  d="M1971.8 724.807a.38.38 0 01-.364-.273 4.43 4.43 0 01-.009-1.978.385.385 0 01.448-.3.381.381 0 01.3.448 3.77 3.77 0 00-.008 1.614.382.382 0 01-.26.471.374.374 0 01-.107.018z"
                  data-name="Path 56319"
                  transform="translate(-1971.323 -722.252)"
                ></path>
              </g>
              <g data-name="Group 2599" transform="translate(31.641 107.439)">
                <path
                  fill="#97c4de"
                  d="M1972.752 746.616a.381.381 0 01-.375-.319 6.31 6.31 0 01.018-2.451.381.381 0 11.714.263 5.606 5.606 0 00.018 2.064.381.381 0 01-.313.438.406.406 0 01-.062.005z"
                  data-name="Path 56320"
                  transform="translate(-1972.256 -743.597)"
                ></path>
              </g>
              <path
                fill="#f7f6f1"
                d="M1950.458 757.89s7.991 21.172 7.5 22.822-4.976 4.352-6.112 3.7-13.99-22.213-13.99-22.213l1.605-4.619z"
                data-name="Path 56321"
                transform="translate(-1928.864 -640.934)"
              ></path>
              <path
                fill="#f7f6f1"
                d="M1987.086 759.087s-5.618 18.593-5.7 19.521 5.3 2.936 6.45 2.936 11.82-21.654 11.82-21.654l-2.87-3.764z"
                data-name="Path 56322"
                transform="translate(-1943.734 -640.437)"
              ></path>
              <g data-name="Group 2600" transform="translate(32.554 157.292)">
                <path
                  fill="#97c4de"
                  d="M1974.371 832.725a.383.383 0 01-.381-.394c0-.091.3-9.111-.341-12.569a.381.381 0 01.749-.138c.659 3.538.366 12.36.354 12.734a.381.381 0 01-.381.367z"
                  data-name="Path 56323"
                  transform="translate(-1973.643 -819.312)"
                ></path>
              </g>
            </g>
            <g data-name="2" transform="translate(107.508)">
              <g data-name="Group 2601" transform="translate(5.125 271.049)">
                <path
                  fill="#f7b690"
                  d="M1760.257 956.187c-2.145 5.59-1.394 15.228-.751 16.824.319.791 3.163 9.745-4.772 7.283a14.77 14.77 0 01-4.589-2.111c-1.149-.9-.5-3.162-.314-3.623 2.293-5.7.781-15.947.781-17.917 0-2.129 10.436-2.516 9.645-.456z"
                  data-name="Path 56324"
                  transform="translate(-1747.142 -954.828)"
                ></path>
                <path
                  fill="#072b45"
                  d="M1746.714 992.173c-1.006-.226-.92 3.145-.438 3.87.537.808 5.763 1.362 11.564 1.213 5.252-.134 8.587-.416 9.011-1.389.217-.5-.116-2.778-1.136-3.427-.9-.573-9.572-1.111-9.865-1.033s-9.136.766-9.136.766z"
                  data-name="Path 56325"
                  transform="translate(-1745.929 -967.32)"
                ></path>
                <path
                  fill="#0a3c5f"
                  d="M1757.272 978.438c1.15.039 2.817 2.6 3.739 5.286 1.075 1.136 3.033 1.545 4.055 3.061.711 1.055 1.606 2.91 1.223 3.412-1.339 1.747-18.4 1.454-19.013-.121-.8-2.049.871-3.924 1.111-5.941.2-1.667.034-2.582.478-3.462a5.341 5.341 0 00.849.471c-.047 1.3-.088 3.254 1.62 3.494 1.592-4.69 4.049-6.265 5.938-6.2z"
                  data-name="Path 56326"
                  transform="translate(-1746.316 -962.892)"
                ></path>
              </g>
              <g data-name="Group 2602" transform="translate(78.946 272.209)">
                <path
                  fill="#072b45"
                  d="M1860.4 990.632c-1.24-.2-1.068 2.816-.616 3.055 3.024 1.6 10.466 1.815 16.32 2.231 6.84.487 12.357.68 14.46-.907 1.105-.834 1.226-2.736-1.219-3.916-1.125-.543-16.638-1.5-16.992-1.41s-11.953.947-11.953.947z"
                  data-name="Path 56327"
                  transform="translate(-1858.526 -967.893)"
                ></path>
                <path
                  fill="#f7b690"
                  d="M1867.545 957.89c.253 4.513 3.631 11.96 4.428 13.634.314.66 2.809 5.539.976 7.487-2.814 2.993-9.878 3.4-11.688-3.66 1.85-7.505-3.214-14.156-3.214-16.388s9.364-3.463 9.498-1.073z"
                  data-name="Path 56328"
                  transform="translate(-1858.047 -956.59)"
                ></path>
                <path
                  fill="#0a3c5f"
                  d="M1860.9 986.367c.49 1.73 25.326 4.53 28.889 2.22 1.893-1.227.209-2.63-1.932-3.531-2.493-1.044-9.508-5.536-11.825-6.568-1.906-.842-3.293-4.59-4.042-3.916-.7.629-.658 4.421-.332 5.915.319 1.462.1 2.733-.553 2.931-3.65 1.111-7.476-5.049-8.462-4.756-1.276.377-2.243 5.95-1.743 7.705z"
                  data-name="Path 56329"
                  transform="translate(-1858.975 -962.704)"
                ></path>
              </g>
              <g
                fill="#275c87"
                data-name="Group 2603"
                transform="translate(6.52 115.82)"
              >
                <path
                  d="M1851.443 877.518c-.439 3.153-11.829 3.539-12.444 1.843-4.278-11.785-13.246-24.043-16.207-43.759-.628-4.182.7-13.423 1-18.04.676-10.511-15.371-34.529-16.161-39.52-.942-6.007-1.181-9.929-1.181-9.929l8.6-30.073.2-.71 5.223-18.259 17.143 9.8s2.673-.648 2.806 1.94c.425 8.223 1.453 27.683 2.668 46.9 1.035 16.38-.391 30.2.652 39.112 1.536 13.163 3.984 43.664 7.701 60.695z"
                  data-name="Path 56330"
                  transform="translate(-1767.995 -719.072)"
                ></path>
                <path
                  d="M1807.447 747.59s-4.8 17.383-11.893 39.6a995.912 995.912 0 01-5.6 17.011c-2.708 8-5.615 11.711-8.615 19.635-1.792 4.739-2.2 14.183-6.192 25-3.724 10.081-10.295 24.613-14.056 34.79-1.278 4.535-11.447 3.514-12.556 1.437 0-1.553-1.992-32.023 2.1-45.3 1.757-5.7 8.65-12.7 11.284-19.469 3.5-9.005-1.056-25.037-1.911-40.037a226.319 226.319 0 01-.206-28.44 74.393 74.393 0 011.254-9.55l30.066 3.451 2.164.246z"
                  data-name="Path 56331"
                  transform="translate(-1748.048 -726.995)"
                ></path>
              </g>
              <path
                fill="#275c87"
                d="M1848.845 632.3c10.106 3.482 15.536 32.8 15.287 47.692-.313 18.721-50.765 10.437-50.765 10.437l.762-18.3 26.9-1.61z"
                data-name="Path 56332"
                transform="translate(-1763.839 -573.613)"
              ></path>
              <path
                fill="#2e6c9e"
                d="M1788.622 612.083c-6.552 1.215-41.277 9.952-45.185 19.6-3.9 9.622-7.342 34.832-3.837 56.409.407 2.505 10.281 3.935 10.695 6.374.564 3.33 2.017 33.316 2.929 34.525 3.267 4.33 60.082 9.27 66.813-.886 1.909-2.879 14.673-90.828 4.593-101.424-6.501-6.83-36.008-14.598-36.008-14.598z"
                data-name="Path 56333"
                transform="translate(-1738.146 -566.708)"
              ></path>
              <path
                fill="#232b59"
                d="M1820.711 548.337c1 .833 2.087.762 3.159 2.632 2.489 4.34-1.244 23.391-10.323 28.476-2.568 1.439-9.521 5.4-13.377 1.275-1.659-1.777-1.692-3.63-3.456-14.555-.745-4.618-2.027-8.57-.2-13.583 2.111-5.8 8.74-4.919 9.826-5.542 1.7-.976 4.533-3.87 8.3-3.872 5.304-.002 5.291 4.519 6.071 5.169z"
                data-name="Path 56334"
                transform="translate(-1757.763 -543.169)"
              ></path>
              <path
                fill="#f3a088"
                d="M1801.7 583.242c-1.081 8.74-2.5 17.429-3.136 18.967-.74 1.774 6.472 5.728 11.681 5.767 7.182.052 11.385-2.661 11.385-4.288s-2.366-18.063-2.366-18.063l-5.625-12.444s-11.77 8.719-11.939 10.061z"
                data-name="Path 56335"
                transform="translate(-1758.766 -553.42)"
              ></path>
              <path
                fill="#f7b690"
                d="M1822.825 579.815c-.923 5.1-3.039 8.814-6.438 9.445-4.944.916-9.928-1.208-11.913-3.478-2.655-3.04-3.427-9.26-3.5-12.339-1.241.521-1.75-3.331-1.305-4.785.809-2.681 2.1-.969 2.263-.418a24.846 24.846 0 01.113 4.148c.006 1.168.085 2.131.424 2.283.173.08.544-.418.783-1.022.192-.059.384-.461.41-.713.3-2.821-.218-7.789 3.658-10.026 2.376-1.367 5.913-.279 8.084-.246 2.037.04 4.718-.936 5.655-.252 1.553 1.129 1.812 2.655 2.164 7.606a40.17 40.17 0 01-.398 9.797z"
                data-name="Path 56336"
                transform="translate(-1759.106 -549.659)"
              ></path>
              <path
                fill="#f3a088"
                d="M1858.613 698.616c1 4.446-8.4 18.782-14.945 19.6-14.159 1.78-46.458-27.155-46.458-27.155l11.4-.313s26.025-5.258 35.649-3.6 13.787 8.936 14.354 11.468z"
                data-name="Path 56337"
                transform="translate(-1758.32 -592.24)"
              ></path>
              <path
                fill="#265a85"
                d="M1800.3 752.137c.988.858 1.991 4.867 0 5.223s-22.344.662-23.229 0-1.327-4.2 0-4.75 22.529-1.078 23.229-.473z"
                data-name="Path 56338"
                transform="translate(-1751.154 -614.455)"
              ></path>
              <path
                fill="#265a85"
                d="M1799.375 669.14c.8.7 1.618 3.955 0 4.244s-18.157.537-18.876 0-1.078-3.415 0-3.86 18.31-.876 18.876-.384z"
                data-name="Path 56339"
                transform="translate(-1752.38 -586.122)"
              ></path>
              <path
                fill="#232b59"
                d="M1823.236 584.426c2.522 5.13.747 10.5-2.652 11.13-4.944.916-15.354.113-17.339-2.157-2.655-3.04-2.789-11.356-2.862-14.435a2.387 2.387 0 001.96-1.275 9.668 9.668 0 001.208 1.262 7.25 7.25 0 003.262 4.991c2.5 1.52 4.573-.6 9.292-1 4.977-.411 6.667.309 7.131 1.484z"
                data-name="Path 56340"
                transform="translate(-1759.404 -554.96)"
              ></path>
              <path
                fill="#f3a088"
                d="M1792.55 683.65a60.4 60.4 0 00-13.671-5.929c-1.786-.514-3.807.059-5.531.495-2.029.513-3.8 1.028-3.54 1.715 1 2.678 6.37-.367 6.9 1.038a38.451 38.451 0 00-5.463 1.894 15.029 15.029 0 00-2.765 4.535c-.74 2.458 3.448 4.1 5.088 5.144s12.382-2.271 12.382-2.271z"
                data-name="Path 56341"
                transform="translate(-1748.478 -589.059)"
              ></path>
              <path
                fill="#f7b690"
                d="M1752.875 700.966c-5.58 10.945 21.223 18.863 30.182 17.436 7.29-1.161 34.039-32.575 38.743-32.75.763-2.7-5.79-5.714-11.141-5.342s-33.147 2.291-40.252 7.234-15.872 10.167-17.532 13.422z"
                data-name="Path 56342"
                transform="translate(-1742.918 -590.002)"
              ></path>
            </g>
            <g data-name="1" transform="translate(0 19.778)">
              <path
                fill="#f7f6f1"
                d="M1688.35 672.2c1.9 1.588 6.679 32.451 8.158 47.244 1.314 13.135 1.314 29.213 1.314 29.213l-25.228-29.757s-.394-60.221 15.756-46.7z"
                data-name="Path 56343"
                transform="translate(-1608.246 -606.344)"
              ></path>
              <path
                fill="#2e6c9e"
                d="M1660.162 780.269l-3.818-31.786 43.042 2.551-.42 34.675z"
                data-name="Path 56344"
                transform="translate(-1602.696 -633.076)"
              ></path>
              <g data-name="Group 2604" transform="translate(51.845 254.236)">
                <path
                  fill="#072b45"
                  d="M1654.529 991.352c-1.2-.195-1.034 2.727-.6 2.959 2.929 1.553 10.136 1.757 15.806 2.161 6.625.471 11.968.658 14-.878 1.071-.807 1.188-2.65-1.181-3.793-1.089-.525-16.114-1.455-16.457-1.365s-11.568.916-11.568.916z"
                  data-name="Path 56345"
                  transform="translate(-1653.606 -969.954)"
                ></path>
                <path
                  fill="#f7b690"
                  d="M1667.558 960.591c.244 4.371-.98 10.956-.208 12.579.3.639 2.721 5.364.946 7.251-2.727 2.9-9.568 3.292-11.321-3.544a55.435 55.435 0 001.383-15.246c0-2.163 9.07-3.354 9.2-1.04z"
                  data-name="Path 56346"
                  transform="translate(-1654.757 -959.332)"
                ></path>
                <path
                  fill="#0a3c5f"
                  d="M1655.008 987.145c.474 1.676 24.529 4.388 27.981 2.15 1.833-1.188.2-2.548-1.871-3.421-2.415-1.011-8.3-5.508-10.542-6.507-1.845-.816-3.189-4.446-3.914-3.793-.677.609-1.551 4.427-1.234 5.875.31 1.416.095 2.648-.536 2.839-3.535 1.075-7.241-4.891-8.2-4.607-1.229.367-2.17 5.763-1.684 7.464z"
                  data-name="Path 56347"
                  transform="translate(-1654.04 -964.853)"
                ></path>
              </g>
              <g data-name="Group 2605" transform="translate(29.042 251.984)">
                <path
                  fill="#f7b690"
                  d="M1633.082 957.315c-2.215 5.773-.713 14.044-.048 15.693.329.817 3.267 10.063-4.928 7.521-5.332-1.654-5.123-5.707-4.892-6.319 2.119-5.625-.092-14.44-.092-16.423 0-2.2 10.778-2.6 9.96-.472z"
                  data-name="Path 56348"
                  transform="translate(-1620.391 -955.912)"
                ></path>
                <path
                  fill="#072b45"
                  d="M1619.659 991.924c-1.04-.234-.66 3.4-.377 3.778s5.875 1.625 11.866 1.472c5.424-.139 8.868-.43 9.306-1.435.224-.514-.12-2.869-1.174-3.539-.93-.592-9.885-1.148-10.187-1.067s-9.434.791-9.434.791z"
                  data-name="Path 56349"
                  transform="translate(-1618.974 -967.94)"
                ></path>
                <path
                  fill="#0a3c5f"
                  d="M1630.5 977.74c1.188.041 2.909 2.682 3.862 5.46 1.109 1.173 3.131 1.6 4.187 3.161.735 1.089 1.66 3.005 1.263 3.523-1.383 1.8-19 1.5-19.635-.125-.822-2.115.9-4.052 1.148-6.135.228-1.91-.006-2.864.668-3.874.209-.312.733-.124.714.477-.042 1.318-.242 3.648 1.661 3.917 1.64-4.844 4.178-6.471 6.132-6.404z"
                  data-name="Path 56350"
                  transform="translate(-1619.308 -963.367)"
                ></path>
              </g>
              <path
                fill="#255882"
                d="M1676.737 764.866a338.064 338.064 0 00.058 61l-.937 5.747c-4.863 29.772-9.472 56.552-10.993 63.9-.581 2.806-9.5 1.5-9.887-1.338-1.51-11.054-8.313-33.952-7.021-44.178.576-4.557 4.025-12.715 6.329-18.252 2.877-6.914-10.935-45.809-.165-76.309 2.913-8.255 22.616 9.43 22.616 9.43z"
                data-name="Path 56351"
                transform="translate(-1599.776 -634.7)"
              ></path>
              <path
                fill="#275c87"
                d="M1643.789 765.068s-12.577 115.13-14.971 130.788c-.433 2.833-10.41 2.395-10.947-.42-2.086-10.96-10.079-33.471-9.325-43.75.337-4.581 3.354-12.908 5.366-18.557 1.042-2.926-1.071-11.193-3.248-22.08-3.072-15.368-1.216-34.924 4.145-53.082 2.478-8.391 28.98 7.101 28.98 7.101z"
                data-name="Path 56352"
                transform="translate(-1586.353 -635.464)"
              ></path>
              <path
                fill="#f7f6f1"
                d="M1660.682 787.256l-57.845-1.994s5.688-105.088-5.378-124.839c-2.709-4.845 6.787-10.7 16.972-14.553 7.283-2.76 14.919-4.49 18.7-4.1a38.407 38.407 0 0112.359 3.783c7.566 3.673 14.714 9.689 17.037 16.907 3.624 11.273-1.845 124.796-1.845 124.796z"
                data-name="Path 56353"
                transform="translate(-1582.423 -596.608)"
              ></path>
              <path
                fill="#f3a088"
                d="M1651.655 618.779s2.781 18.4 2.652 18.929c-.246 1-5.2-3.284-6.889-2.309-2.458 1.42-12.928 4.5-13.84 2.309-.2-.483 2.391-21.1 2.391-21.1z"
                data-name="Path 56354"
                transform="translate(-1594.916 -588.031)"
              ></path>
              <g data-name="Group 2606" transform="translate(31.992)">
                <path
                  fill="#232b59"
                  d="M1651.073 599.1c4.413-4.034 1.578-6.705 5.468-9.306a5.4 5.4 0 00-3.614-.111c1-1.513 1.329-3.619 2.823-4.415a6.209 6.209 0 00-4.049.857c.041-1.431 2.781-2.944 2.289-4.77a6.821 6.821 0 01-4.13 1.745c-.071-.934.913-3 2.958-4.252-2.1-.172-3.615-.038-5.234 1.308-.406-1.451.243-2.68 1.2-3.844a4.463 4.463 0 00-4.758 2.392c-.878-.575-1.278-3.025-1.321-4.681-1.007.757-2.106 3.189-2.391 4.324a20.531 20.531 0 00-1.125-5.142c-1.185 3.026-3.961 2.293-4.171 6.051-.343-1.693-3.515-2.745-4.606-3.325 1.151 2.507.993 4.988.681 5.329-.26.284-2.31-.9-2.308-3.384-1.447 1.431-2.923 4.267-1.127 8.74-.558-.033-2.269-1.553-2.9-3.393-.552.8-1.3 5.241.322 9.46a3.309 3.309 0 01-1.622-1c1.286 6.16 4.459 8.522 5.7 9.922.948 1.068 2.987.194 4.3.5a92.961 92.961 0 009.268 1.612z"
                  data-name="Path 56355"
                  transform="translate(-1623.454 -573.207)"
                ></path>
                <path
                  fill="#f7b690"
                  d="M1654.381 599.49c1.115 1.722 1.035 7.476.973 9.441-.071 2.241-2.1 11.766-4.616 13.149-2.184 1.2-8.5 1.57-10.037.84-3.734-1.774-7.146-8.238-7.622-12.146-2.1.113-4.4-10.475-1.282-8.843.931.488 1.366 3.068 1.4 3.987.037.887.919 2.055 1.016 1.767.81-2.4 1.214-7.55 1.945-7.988 1.322-.791 3.372.311 3.657 2a2.371 2.371 0 00.065-3.189c2.443-.545 5.213-.389 7.387 3.43a5.454 5.454 0 00-.737-4.754 5.437 5.437 0 014.313 3.864 5.617 5.617 0 00-.694-4.664 14.732 14.732 0 014.232 3.106z"
                  data-name="Path 56356"
                  transform="translate(-1625.784 -581.123)"
                ></path>
              </g>
              <path
                fill="#f7f6f1"
                d="M1590.048 664.212c-8.853 14.167-16.714 51.658-14.927 58.66 1.5 5.88 19.73 12.988 41.939 11.345 3.168-.234.952-17.041.952-17.041s-19.456-1.535-19.411-6.6 1.634-8.766 1.634-14.549-10.187-31.815-10.187-31.815z"
                data-name="Path 56357"
                transform="translate(-1574.866 -604.292)"
              ></path>
              <path
                fill="#f3a088"
                d="M1708.414 803.207c-1.5.524-2.4-1.475-2.863-1.342-.577.167-.731 1.581-1.4 1.7a15.479 15.479 0 01-5.471-1.182c-.577-.672-.761-4.068.169-4.462.4-.171 4.251-1.119 5.04-.869.514.163.678 2.874 1.17 2.891 1.107.038-.1-2.694 1.827-2.492 2.023.212 2.681 5.349 1.528 5.756z"
                data-name="Path 56358"
                transform="translate(-1616.992 -649.652)"
              ></path>
              <path
                fill="#f7b690"
                d="M1652.875 743.627c-.376-.989-1.865-.76-2.993-.392a1.755 1.755 0 00.8-2.1c-.11-.385-1.821.295-3.365.456-1.277.133-2.6-.487-3.981.3a16.717 16.717 0 00-4.389 4.431l.863 6.084c4.112 3.606 10.33 1.99 11.938.764 1.717-1.308 1.712-8.006 1.127-9.543z"
                data-name="Path 56359"
                transform="translate(-1596.755 -630.528)"
              ></path>
              <g data-name="Group 2607" transform="translate(47.174 112.723)">
                <path
                  fill="#d6956f"
                  d="M1646.673 745.775a.161.161 0 01-.079-.3 10.522 10.522 0 012.427-.473 15.1 15.1 0 002.993-.579.161.161 0 01.133.293 14.784 14.784 0 01-3.083.605 11.508 11.508 0 00-2.313.435.156.156 0 01-.078.019z"
                  data-name="Path 56360"
                  transform="translate(-1646.512 -744.407)"
                ></path>
              </g>
              <g data-name="Group 2608" transform="translate(23.279 91.352)">
                <path
                  fill="#97c4de"
                  d="M1630.448 733.36c-.41-1.236-4.835-2.227-13.981-3.857-2.027-.359-4.109-.732-5.053-.971a3.966 3.966 0 01-.421-.126 2.881 2.881 0 01-.018-.453c.018-1.324.437-4.675.809-7.688.177-1.437.359-2.9.526-4.323.142-1.239.276-2.447.38-3.576a.38.38 0 00-.343-.415.329.329 0 00-.051 0 .379.379 0 00-.364.346c-.11 1.15-.244 2.383-.391 3.648a550.992 550.992 0 01-.512 4.23c-.8 6.435-.97 8.242-.668 8.7.15.225.55.375 2.5.753.839.161 1.961.364 3.474.633 4.007.713 12.126 2.16 13.268 3.184a.437.437 0 01.123.163.379.379 0 00.362.26.143.143 0 00.045-.005.255.255 0 00.075-.013.381.381 0 00.24-.49z"
                  data-name="Path 56361"
                  transform="translate(-1610.221 -711.949)"
                ></path>
              </g>
              <g data-name="Group 2609" transform="translate(22.529 123.705)">
                <path
                  fill="#97c4de"
                  d="M1625.291 767.978c-5.286 0-14.362-.806-15.88-1.018a.381.381 0 11.105-.754c2.794.389 17.57 1.5 19.06.758 1.26-.628 2.206-4.234 2.465-5.571a.381.381 0 01.748.145c-.1.53-1.053 5.2-2.872 6.108a11.558 11.558 0 01-3.626.332z"
                  data-name="Path 56362"
                  transform="translate(-1609.083 -761.085)"
                ></path>
              </g>
              <g data-name="Group 2610" transform="translate(80.964 81.782)">
                <path
                  fill="#97c4de"
                  d="M1698.212 731.421a.387.387 0 01-.38-.412c.018-.223 1.782-22.4.672-33.174a.381.381 0 11.758-.079c1.117 10.848-.653 33.09-.671 33.314a.381.381 0 01-.379.351z"
                  data-name="Path 56363"
                  transform="translate(-1697.831 -697.415)"
                ></path>
              </g>
              <path
                fill="#b3d5e8"
                d="M1652.043 673.279c-4.773 2.014-6.749 2.864-11.548 0-4.3-2.567-16.1-19.326-17.017-27.079a78.161 78.161 0 0111.249-3.357c3.045-.641 5.939.851 7.718 1.035 2.236.231 4.441-.974 6.974-.109a44.914 44.914 0 015.121 2.117 48.621 48.621 0 01-2.497 27.393z"
                data-name="Path 56364"
                transform="translate(-1591.47 -596.938)"
              ></path>
              <path
                fill="#2e6c9e"
                d="M1648.57 648.035s2.981 6.327 3.732 7.721-3.26 16.02-3.238 18.143c1.63 1.842 7.956 6.2 7.956 6.2a55.062 55.062 0 003.6-6.2c.363-4.842-6.146-17.6-5.7-19.695s2.375-7.134 2.375-7.134l-3.619-2.573h-2.252z"
                data-name="Path 56365"
                transform="translate(-1600.041 -597.558)"
              ></path>
              <path
                fill="#9ccbe6"
                d="M1631.982 636.271c-1.394 0-3.872 6.336-3.646 7.239.336 1.34 8.846 6.541 11.1 6.541.645 0 5.2-7.56 6.38-7.667s4.51 6.737 5.737 6.326c.8-.268 2.69-4.377 2.526-5.985s-1.433-5.269-2.044-5.381-4.29 3.752-6.219 4.02c-2.282.319-12.973-5.093-13.834-5.093z"
                data-name="Path 56366"
                transform="translate(-1593.125 -594.748)"
              ></path>
              <g data-name="Group 2611" transform="translate(23.355 53.636)">
                <path
                  fill="#97c4de"
                  d="M1643.455 788.927a.374.374 0 01-.126-.022.38.38 0 01-.232-.485c1.243-3.536 1.813-22.746 1.605-54.092-.164-24.85-.771-48.292-1-49.285-.635-1.044-13.239-5.7-17.982-7.458-2.411-.891-3.484-1.291-3.7-1.407a.528.528 0 01-.271-.375c-.089-.479.429-1.115 1.514-2.4.776-.916 2.39-2.821 2.062-3.265-.358-.481-3.4-.119-5.217.1-3.1.367-4.209.444-4.525-.143a12.911 12.911 0 01-.589-2.2c-.727-3.18-2.081-9.1-4.589-12.624a.381.381 0 01.62-.442c2.6 3.649 3.972 9.664 4.712 12.9a17.5 17.5 0 00.517 2.012 17.894 17.894 0 003.764-.253c3.3-.391 5.339-.574 5.918.206.69.928-.724 2.6-2.091 4.211a13.354 13.354 0 00-1.3 1.68c.475.195 1.864.708 3.438 1.291 13.309 4.921 18.136 7 18.446 7.949.269.821.868 24.972 1.032 49.234.15 22.35-.013 49.969-1.647 54.619a.381.381 0 01-.359.249z"
                  data-name="Path 56367"
                  transform="translate(-1610.336 -654.667)"
                ></path>
              </g>
              <g data-name="Group 2612" transform="translate(57.761 54.923)">
                <path
                  fill="#97c4de"
                  d="M1662.972 684.619a.381.381 0 01-.156-.728c4.862-2.18 10.152-10.67 10.2-11.642a18.083 18.083 0 00-2.166-2.2c-1.621-1.512-2.032-1.94-2.053-2.3a.5.5 0 01.146-.354c.3-.265.992-.182 3.609.259a20.439 20.439 0 003.916.464c.405-.841-.04-7.243-1.294-10.993a.381.381 0 11.722-.242c1.184 3.54 2 11.091 1.054 11.839-.4.318-1.429.207-4.525-.317-.866-.146-1.969-.333-2.6-.388.365.377 1.023.992 1.544 1.477 1.529 1.425 2.263 2.147 2.389 2.605.336 1.227-5.831 10.337-10.63 12.488a.372.372 0 01-.156.032z"
                  data-name="Path 56368"
                  transform="translate(-1662.591 -656.622)"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
