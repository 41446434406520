import * as yup from 'yup';

const schema = yup.object().shape({
  name: yup.string().required('Name is a Required Field'),
  email: yup.string().required('Email is a Required Field'),
  password: yup.string().required('Password is a Required Field'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'Passwords Must Match')
    .required('Confirm Password is a Required Field'),
});

export default schema;
