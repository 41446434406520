import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Props } from './index';
export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '5.1rem',
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      background: (props: Props) =>
        props.transparent ? 'transparent' : '#333',
    },
    loginText: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '1rem !important',
      },
    },
    menuIcon: {
      color: '#fff',
    },
    infoBtn: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down('xl')]: {
        justifyContent: 'space-around',
      },
    },
    img: {
      width: '150px',
      height: 'auto',
      [theme.breakpoints.down('sm')]: {
        width: '100px',
      },
    },
    logoWrapper: {
      width: 'fit-content',
    },
    linkText: {
      color: props => (props.transparent ? theme.palette.primary.dark : '#fff'),
      display: 'flex',

      [theme.breakpoints.down('xl')]: {
        fontSize: '14px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: '14px',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '12px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '10px',
      },
    },
    title: {
      marginRight: theme.spacing(7),
      color: '#fff',
      [theme.breakpoints.down('xl')]: {
        marginRight: theme.spacing(5),
      },
      [theme.breakpoints.down('lg')]: {
        marginRight: theme.spacing(2),
      },
    },
  }),
);
