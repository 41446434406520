
export const config = {
  firebase: {
    apiKey: 'AIzaSyCKV3VeK1cmjh57gKFp0YZlhSmRMKwYA6g',
    authDomain: 'urgent-care-ec38b.firebaseapp.com',
    databaseURL: 'https://urgent-care-ec38b.firebaseio.com',
    projectId: 'urgent-care-ec38b',
    storageBucket: 'urgent-care-ec38b.appspot.com',
    messagingSenderId: '414830734605',
    appId: '1:414830734605:web:1cf110fd4824c12f571444',
    measurementId: 'G-PTKLHWMTHF',
  },
  stripeKey:
    'pk_live_51HrNOcLnDoFBR6Nn0HQbSIFi5pNjxByZZCXHBmDHES6wnfOOV1SCd5UyNd7LFzNosSB5kUPrKwvUBeG0v5gAsb1z00a1eFNcPj',
  baseUrl: 'https://us-central1-urgent-care-ec38b.cloudfunctions.net',
};
