import React, { useContext } from "react";
import { Grid, useMediaQuery, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme, useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import ResponsiveBtn from "../ResponsiveBtn";
import { UserContext } from "../../context/UserProvider";
import Doctors from "../../SVG/dcotorsSVGJSX";
import LocationOnIcon from "@material-ui/icons/LocationOn";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    titleText: {
      marginBottom: "2.75rem",
      [theme.breakpoints.down("md")]: {
        textAlign: "center",
        margin: "2rem 2rem 0 2rem",
      },
    },
    svgDoctors: {
      zIndex: 2,
      [theme.breakpoints.down("md")]: {
        height: "100%",
      },
    },
    btnWrapper: {
      marginBottom: "1rem",
      flexWrap: "wrap",
      display: "flex",
    },
    content: {
      height: "80vh",
      paddingLeft: "4rem",
      background: "#F7F6F1",
      paddingTop: "5.5rem",
      [theme.breakpoints.down("md")]: {
        padding: "1rem",
        height: "100vh",
        marginTop: "0rem",
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: "0rem",
        height: "95vh",
      },
    },
    showCaseContentWrapper: {
      height: "fit-content",
      alignSelf: "center",
      textAlign: "left",
      [theme.breakpoints.down("xs")]: {
        textAlign: "center",
      },
      zIndex: 1,
    },
    firstText: {
      marginBottom: "2.75rem",
      [theme.breakpoints.down("md")]: {
        textAlign: "center",
        margin: "3rem 2rem",
      },
      [theme.breakpoints.down("xs")]: {
        marginBottom: "0.25rem",
      },
    },
    showCaseFooterImg: {
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    backgroundOverlay: {
      position: "relative",
      width: "100%",
      height: "20vh",
      background: `url(${require("../../assets/svg/Rectangle9.svg")}) no-repeat center center/cover`,
    },
    treePaperWrapper: {
      bottom: 0,
      height: "100%",
      width: "20%",
    },
  })
);

const ShowCase: React.FC = () => {
  const history = useHistory();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));

  const classes = useStyles();

  const isSmall = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down("md");
  });
  const { user } = useContext(UserContext);

  const Btns = (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
      }}
    >
      <Grid
        className={classes.btnWrapper}
        item
        container
        justify={isSmall ? "center" : "flex-start"}
        xs={12}
        md={6}
        lg={6}
      >
        <ResponsiveBtn
          width="300px"
          onClick={() => history.push("/contact-us")}
          size="large"
          variant="outlined"
        >
          Hours & Location
        </ResponsiveBtn>
      </Grid>
      <Grid
        item
        className={classes.btnWrapper}
        container
        justify="center"
        xs={12}
        md={6}
        lg={6}
        style={{ display: "flex",  justifyContent: smDown? 'none': "flex-start" }}
      >
        <ResponsiveBtn
          width="300px"
          onClick={() => history.push(`${user ? "/dashboard" : "/signup"}`)}
          size="large"
          variant="outlined"
        >
          {user ? " Make an appointment" : " Make an appointment"}
        </ResponsiveBtn>
      </Grid>

      <Grid
        className={classes.btnWrapper}
        item
        container
        justify={isSmall ? "center" : "flex-start"}
        xs={12}
        md={6}
        lg={6}
      >
        <a
          href="tel:2629250535"
          style={{ color: "black", textDecoration: "none" }}
        >
          <ResponsiveBtn width="300px" size="large" variant="outlined">
            📞 call us : 262-925-0535
          </ResponsiveBtn>{" "}
        </a>
      </Grid>
      <Grid
        item
        className={classes.btnWrapper}
        container
        justify="center"
        xs={12}
        md={6}
        lg={6}
        style={{ display: "flex", justifyContent: smDown? 'none': "flex-start" }}
      >
        <a
          href="https://www.google.com/maps/place/6430+Green+Bay+Rd,+Kenosha,+WI+53142,+USA/@42.5761772,-87.8885366,17z/data=!3m1!4b1!4m5!3m4!1s0x880560fa2fd8d38b:0xdcf7bf58faba92b!8m2!3d42.5761733!4d-87.8863479"
          target="_blanck"
          style={{ color: "black", textDecoration: "none" }}
        >
          <ResponsiveBtn width="300px" size="large" variant="outlined">
            <LocationOnIcon color="primary" style={{ position: "relative" }} />{" "}
            Get Directions
          </ResponsiveBtn>
        </a>
      </Grid>
    </div>
  );
  return (
    <section className={classes.root}>
      <Grid
        className={classes.content}
        container
        direction={isSmall ? "row-reverse" : "row"}
        justify={"center"}
        alignItems={"center"}
      >
        <Grid item xs={10} lg={6} className={classes.showCaseContentWrapper}>
          <Grid
            className={classes.showCaseContentWrapper}
            item
            container
            xs
            alignItems="center"
          >
            <Grid item xs={12}>
              {/* <Typography
                className={classes.titleText}
                component="p"
                variant="h4"
                color="primary"
              >
                Search in one place
              </Typography> */}
              <Typography
                className={classes.firstText}
                component="p"
                variant="h1"
                style={{
                  color: "black",
                }}
              >
                Find Convenient Care That Measures up to Your Urgent Care Needs
              </Typography>
            </Grid>

            {!isSmall && (
              <>
                <Grid item xs={12} container justify="center" direction="row">
                  {Btns}
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        {!isSmall && (
          <Grid
            item
            container
            justify={isSmall ? "center" : "flex-end"}
            xs={6}
            md={6}
            lg={6}
          >
            <Doctors className={classes.svgDoctors} width="100%" height="90%" />
          </Grid>
        )}
        {isSmall && (
          <>
            <Grid item xs={12} container justify="center" direction="row">
              {Btns}
            </Grid>
          </>
        )}
      </Grid>
      {/*      <Grid item container justify="flex-start" xs={12}>
        <Grid item xs={4}>
          <img
            className={classes.showCaseFooterImg}
            src={require('../../assets/svg/gradientWithPaper.svg')}
            alt="paper"
            width="480px"
            height="100%"
          />
        </Grid>
      </Grid>*/}
    </section>
  );
};

export default ShowCase;
