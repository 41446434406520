import React from 'react';
import {
  Button,
  makeStyles,
  createStyles,
  ButtonProps,
  Theme,
  useMediaQuery,
} from '@material-ui/core';
import clsx from 'clsx';

interface Props extends ButtonProps {
  width?: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: (props: Props) => props.width && props.width,
    },
  }),
);

const ResponsiveBtn: React.FC<Props> = props => {
  const { children, className, size, ...rest } = props;
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );
  const getSize = () => {
    if (isSmallScreen) {
      return size === 'large'
        ? 'medium'
        : size === 'medium'
        ? 'small'
        : 'small';
    } else return size;
  };
  const classes = useStyles(props);
  return (
    <Button
      className={clsx(classes.root, className)}
      size={getSize()}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default ResponsiveBtn;
