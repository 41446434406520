import React from 'react';
import { useHistory } from 'react-router-dom';
import ResponsiveBtn from '../ResponsiveBtn';

const GetDirectionsBtn: React.FC = () => {
  const history = useHistory();
  return (
    <ResponsiveBtn
      onClick={() => history.push('/contact-us')}
      color="primary"
      variant="contained"
      size="large"
    >
      Get Directions
    </ResponsiveBtn>
  );
};

export default GetDirectionsBtn;
