import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FirebaseUtil from '../../config/firebase';
import { useSnackbar } from 'notistack';
import { bool } from 'yup';
interface Props extends DialogProps {
	handleClose: () => void;
}

const PasswordResetDialog: React.FC<Props> = ({
	open,
	handleClose,
	...rest
}) => {
	const [email, setEmail] = useState('');
	const { enqueueSnackbar } = useSnackbar();

	const onResetPassword = async () => {
		handleClose();
		FirebaseUtil.auth.sendPasswordResetEmail(email);
		enqueueSnackbar('Password reset link has been sent', {
			variant: 'success',
			autoHideDuration: 3000,
			preventDuplicate: true,
			anchorOrigin: { horizontal: 'right', vertical: 'top' },
		});
	};

	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='form-dialog-title'
				{...rest}>
				<DialogTitle id='form-dialog-title'>Reset Password</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Please enter your email to reset your password
					</DialogContentText>
					<TextField
						autoFocus
						margin='dense'
						id='name'
						label='Email Address'
						type='email'
						value={email}
						onChange={({ target: { value } }) => setEmail(value)}
						fullWidth
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={onResetPassword} color='primary'>
						Reset My Password
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default PasswordResetDialog;
