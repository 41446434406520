export const LOGIN = '/login';
export const SIGNUP = '/signup';
export const APPOINTMENT = '/appointment';
export const NEW_APPOINTMENT = '/appointment/newAppointment';
export const INSURANCE = '/insurance';
export const PATIENT = '/patient';
export const PAYMENT = '/payment';
export const SETTING = '/setting';
export const INVOICE = `${PAYMENT}/invoices`;

export const AppointmentPageName = 'Appointments';
export const invoicePageName = 'Invoice';
export const addAppointmentPageName = 'Add Appointment';
export const PatientsPageName = 'Patients';
export const InsurnacesPageName = 'Insurnaces';
export const PaymentPageName = 'Payment';
