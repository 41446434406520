import * as routesConst from '../../containers/PublicWebsite/constants';

const links = [
  {
    route: routesConst.SERVICES,
    title: 'Sevices',
    current: false,
  },
  {
    route: routesConst.OCCUPATIONAL,
    title: 'Occupational',
    current: false,
  },
  {
    route: routesConst.BILLING_AND_INSURANCE,
    title: 'Insurance & Payment',
    current: false,
  },
  {
    route: routesConst.OUR_TEAM,
    title: 'Our Team',
    current: false,
  },
  {
    route: routesConst.CONTACT_US,
    title: 'Contact',
    current: false,
  },
  {
    route: routesConst.URGENT_CARE_VS_EMERGENCY_ROOM,
    title: 'Urgent Care Vs Emergency Room',
    current: false,
  },
  {
    route: routesConst.SPECIAL_OFFER,
    title: 'Special Offers',
    current: false,
  },
  {
    route: routesConst.IN_THE_NEWS,
    title: 'In The News',
    current: false,
  },
  {
    route: '/articles',
    title: 'Health Articles',
    current: false,
  },
];

export default links;
