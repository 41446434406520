import React from 'react';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import {
    Badge,
    Button,
    makeStyles,
    createStyles,
    Theme,
} from '@material-ui/core';
import {UserAvatar} from '../../components';

interface Props {
    userName: string;
    avatarUrl?: string;
    file: any;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;

    [x: string]: any;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            transform: 'none',
        },
        resetTrnasform: {
            transform: 'none',
        },
        input: {
            display: 'none',
        },

        avatar: {
            width: '200px',
            height: '200px',
            fontSize: '4rem',
            [theme.breakpoints.down('sm')]: {
                width: '200px',
                height: '200px',
                fontSize: '2.5rem',
            },
        },
        editAvatarBtn: {
            minWidth: 'fit-content',
            width: 'fit-content',
            top: '0',
            padding: '0 10px',
            height: '35px',
            fontSize: '12px'
        },
    }),
);

const AvatarEditPadge: React.FC<Props> = ({userName, avatarUrl, onChange,removePicture}) => {
    const classes = useStyles();
    const handleFileClick=(e:any)=>{
        e.target.value = ''
    }
    return (
        <Badge
            classes={{
                anchorOriginBottomLeftRectangle: classes.resetTrnasform,
            }}
            className={classes.root}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            badgeContent={
                <>
                    <input
                        accept="image/*"
                        className={classes.input}
                        id="contained-button-file"
                        type="file"
                        onChange={onChange}
                        onClick={handleFileClick}
                    />
                    <label htmlFor="contained-button-file">
                        <Button
                            component="span"
                            startIcon={<EditOutlinedIcon/>}
                            className={classes.editAvatarBtn}
                            variant="contained"
                            size="small"
                            color="primary"
                        >
                            edit
                        </Button>
                    </label>
                    {avatarUrl &&
                    <>
                        <span style={{width: '5px'}}/>
                        <Button
                            component="span"
                            startIcon={<EditOutlinedIcon/>}
                            className={classes.editAvatarBtn}
                            variant="contained"
                            size="small"
                            color="primary"
                            onClick={removePicture}
                        >
                            Remove
                        </Button>
                    </>
                    }
                </>
            }
        >
            <UserAvatar
                className={classes.avatar}
                userName={userName}
                avatarUrl={avatarUrl}
            />
        </Badge>
    );
};

export default AvatarEditPadge;
