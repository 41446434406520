import React, {useContext, useEffect, useState} from 'react';
import useTheme from '@material-ui/core/styles/useTheme';
import {AiOutlineMail} from 'react-icons/all';
import {makeStyles, Dialog, DialogTitle, DialogContent, Link} from '@material-ui/core';
import Typography from "@material-ui/core/Typography";
import {UserContext} from "../../context/UserProvider";
import FirebaseUtil from '../../config/firebase';
interface WrapperProps {
     style?: React.CSSProperties,
     open: boolean,
     closeDialog: () => void
}

const useStyles = makeStyles(() => ({
     emailContainer: {
          width: 'fit-content',
          padding: '10px 12px',
          boxShadow: '0px 4.0px 24.0px -8.0px rgba(47, 0, 244, 0.2)',
          borderRadius: '6px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '20px 0',
     },
     textStyle: {color: '#707070', marginTop: '10px'},
     centeredColDiv: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height:'fit-content'
     },
}));

const VerificationWrapper = ({ style, open, closeDialog}: WrapperProps) => {

     const theme = useTheme();
     const classes = useStyles();
     const {user} = useContext(UserContext);
     const [count, setCount] = useState(60);
     const [verified, setVerify] = useState(false);
     const [disable, setDisable] = useState(false);
     const [currentUser, setUser] = useState<any>('');
     const authUser = FirebaseUtil.auth.currentUser;

     useEffect(() => {
          if (authUser) {
               setUser(authUser)
               if (authUser.emailVerified) {
                    setVerify(true);
               } else {
                    setVerify(false);
               }
               const timer = setInterval(() => {
                    authUser?.reload().then(() => {
                         if (authUser.emailVerified) {
                              setVerify(true);
                              clearInterval(timer);
                         }
                    });
               }, 4000);
          }
     }, []);

     useEffect(()=>{
          if(verified){
               closeDialog();
          }
     },[verified])

     const sendEmail = () => {
          if (!disable) {
               currentUser?.sendEmailVerification()
               setDisable(true);
          }
     };

     useEffect(() => {
          let interval: NodeJS.Timeout;
          let counter = 60;
          if (disable) {
               setTimeout(() => {
                    setDisable(false);
               }, 60000);

               interval = setInterval(() => {
                    if (counter <= 1) {
                         clearInterval(interval);
                         setCount(60);
                    } else {
                         counter -= 1;
                         setCount((c) => c - 1);
                    }
               }, 1000);
          }
     }, [disable]);

     return (
        <div style={style}>
             <Dialog
                 onClose={closeDialog}
                 open={open}
                 disableBackdropClick={true}
                 disableEscapeKeyDown={true}
             >
               <DialogTitle>Email Verification</DialogTitle>
               <DialogContent>
                 <div style={{height: '2px'}}/>

                       <div className={classes.centeredColDiv}>
                            <Typography variant={'body1'}  style={{marginTop: '20px'}}>We have sent
                                 you a Verification Email!</Typography>

                            <Typography variant={'body2'}  className={classes.textStyle}>
                                 Check your email to continue.</Typography>

                            <div className={classes.emailContainer}>
                                 <AiOutlineMail color={theme.palette.primary.main}/>
                                 <Typography variant={'body2'}
                                       style={{marginLeft: '10px'}}>{user?.email}</Typography>
                            </div>

                            <Typography variant={'body2'} className={classes.textStyle}>
                                 Didn&apos;t Receive?
                                 <Link onClick={sendEmail} style={{color: theme.palette.primary.main,cursor:'pointer'}}>Resend</Link>
                            </Typography>

                            {count !== 60 &&
                            <Typography variant={'body2'}  className={classes.textStyle}>
                              Retry in :
                              <span style={{color: theme.palette.primary.main}}>{count}</span>
                            </Typography>
                            }
                       </div>
               </DialogContent>
             </Dialog>
        </div>
     );
};

export default VerificationWrapper;