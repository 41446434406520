import React, { useState, useEffect } from 'react';
import { MenuItem, Typography } from '@material-ui/core';
import CustomSelect from '../CustomSelect';
import { PatientStepFormValues } from '../../containers/PatientForm';
interface Address {
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zipCode: string;
  default?: boolean;
}

interface Props {
  addresses: Address[];
  values: PatientStepFormValues;
  setValues: (
    values: PatientStepFormValues,
    shouldValidate?: boolean | undefined,
  ) => void;
}

const formatAddress = ({ address1, address2, city, zipCode }: Address) => {
  return `${address1}, ${address2 ? `${address2}, ` : ''}${city}, ${zipCode}`;
};

const UserAddressSelector: React.FC<Props> = ({
  addresses,
  values,
  setValues,
}) => {
  const [address, setAddress] = useState<number>(-1);

  const onChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setAddress(Number(value));
    setValues({
      ...values,
      address1: addresses[Number(value)].address1,
      address2: addresses[Number(value)].address2,
      state: addresses[Number(value)].state,
      zipCode: addresses[Number(value)].zipCode,
      city: addresses[Number(value)].city,
    });
  };
  useEffect(() => {
    addresses.forEach((address, index) => {
      if (address.default) {
        setAddress(index);
        setValues({
          ...values,
          address1: addresses[Number(index)].address1,
          address2: addresses[Number(index)].address2,
          zipCode: addresses[Number(index)].zipCode,
          state: addresses[Number(index)].state,
          city: addresses[Number(index)].city,
        });
      }
    });
  }, [addresses]);

  return (
    <CustomSelect
      value={address >= 0 && address}
      onChange={onChange}
      variant="filled"
      label="Select Address"
      fullWidth
    >
      {addresses.map((d, index) => {

        return (
          <MenuItem value={index}>
            <Typography variant="body1" color="textPrimary">
              {formatAddress(d)}
            </Typography>
          </MenuItem>
        );
      })}
    </CustomSelect>
  );
};

export default UserAddressSelector;
