import { createMuiTheme, responsiveFontSizes } from '@material-ui/core';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#AE3431',
      dark: '#333333',
    },
    secondary: {
      main: '#FAFBFC',
      dark: '#666666',
    },
    text: {
      secondary: '#666666',
    },
  },
  overrides: {
    MuiTextField: {
      root: {
        '& label.Mui-focused': {
          color: '#333',
          fontFamily: 'Montserrat, sans-serif',
          fontWeight: '300',
          fontSize: '14px',
        },

        '& label.MuiInputLabel-shrink': {
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '15px',
          color: '#333',
          transform: 'translate(12px, 7px) scale(0.75)',
        },
      },
    },
    MuiButton: {
      sizeLarge: {
        width: '358px',
        minHeight: '50px',
        height: 'auto',
        fontSize: '18px',
        fontFamily: "'Montserrat',sans-serif",
        borderRadius: '4px',
      },
      outlinedSizeLarge: {
        color: '#000',
        borderColor: '#AE3431',
      },
      containedSizeSmall: {
        width: '158px',
        height: '48px',
        fontSize: '14px',
        fontFamily: "'Montserrat',sans-serif",
        color: '#fff',
        borderRadius: '4px',
      },
      outlinedSizeSmall: {
        width: '158px',
        height: '48px',
        fontSize: '14px',
        fontFamily: "'Montserrat',sans-serif",
        color: '#000',
        borderColor: '#AE3431',
        borderRadius: '4px',
      },
      outlined: {
        width: '250px',
        minHeight: '40px',
        fontSize: '14px',
        fontFamily: "'Montserrat',sans-serif",
        color: '#000',
        borderRadius: '4px',
        borderColor: '#AE3431',
        '&:hover': {
          backgroundColor: '#AE3431',
          color: '#fff',
        },
      },
      contained: {
        width: '250px',
        fontSize: '14px',
        minHeight: '40px',

        fontFamily: "'Montserrat',sans-serif",
        color: '#fff',
        borderRadius: '4px',
      },
    },
  },
  typography: {
    h1: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 'bold',
      fontSize: '50px',
    },
    h2: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 'bold',
      fontSize: '38px',
    },
    h3: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 'normal',
      fontSize: '35px',
    },
    h4: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 'normal',
      fontSize: '28px',
    },
    h5: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 'bold',
      fontSize: '24px',
    },
    h6: {
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '18px',
    },
    body1: {},
    body2: {},
  },
});

export default responsiveFontSizes(theme);
