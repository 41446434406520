import React from 'react';
import {Typography, Grid, makeStyles, createStyles} from '@material-ui/core';

interface Props {
    title: string;
    pargraphArr: string[];

    [x: string]: any;
}

const useStyles = makeStyles(() =>
    createStyles({
        notBold: {
            fontWeight: 'normal',
        },
    }),
);

const Article: React.FC<Props> = ({title, pargraphArr, ...props}) => {
    const classes = useStyles();
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography
                        className={classes.notBold}
                        color="primary"
                        component="h3"
                        variant="h2"
                        {...props}
                    >
                        {title}
                    </Typography>
                </Grid>
                <Grid item container spacing={2} xs={12}>
                    {pargraphArr.map((pText, index) => (
                        <Grid item xs={12}>
                            <Typography key={index} component="p" variant="h6">
                                {pText}
                               
                            </Typography>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </>
    );
};

export default Article;
