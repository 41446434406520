import React from "react";
import {
  Typography,
  makeStyles,
  createStyles,
  Theme,
  Button,
  Container,
  Grid,
  List,
  ListItemText,
  ListItem,
} from "@material-ui/core";
import { Helmet } from "react-helmet";
import { GetDirectionsBtn } from "../../../components";
import clsx from "clsx";
import textObj from "./text.json";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: "20vh",
      marginBottom: theme.spacing(10),
    },
    notBold: {
      fontWeight: "normal",
    },
    marginBottom: {
      marginBottom: theme.spacing(4),
    },
    mainTitle: {
      marginBottom: theme.spacing(1),
    },
    pageIntro: {
      marginBottom: theme.spacing(4),
    },
    subItem: {
      marginBottom: theme.spacing(3),
    },
    listItem: { paddingBottom: "0", paddingTop: "0" },
    subHeader: {
      fontSize: "20px",
      marginBottom: theme.spacing(1),
    },
  })
);
const UrgentVsEmergencyPage: React.FC = () => {
  const classes = useStyles();
  return (
    <main id="urgent-care-vs-emergency-room">
      <Helmet>
        <link rel="canonical" href="/urgent-care-vs-emergency-room" />
        <title>Urgent Care Vs Emergency Room | Kenosha UrgiCare</title>
        <meta
          name="description"
          content="When you're sick, injured, or dealing with a health situation you can't solve alone, the last thing you want is to get stuck trying to figure out where to go."
        />
      </Helmet>
      <Container maxWidth="xl">
        <Grid className={classes.root} container>
          <Grid item container xs={12} md={8}>
            <Grid className={classes.pageIntro} item xs={12}>
              <Typography
                className={clsx(classes.notBold, classes.mainTitle)}
                color="primary"
                component="h3"
                variant="h2"
              >
                {textObj.mainTitle}
              </Typography>
              <Typography component="p" variant="h6">
                {textObj.desc}
              </Typography>
            </Grid>
            <Grid className={classes.subItem} item xs={12}>
              <Typography
                color="primary"
                className={classes.subHeader}
                component="h4"
                variant="h6"
              >
                {textObj.costs.title}
              </Typography>
              <Typography component="h4" variant="h6">
                {textObj.costs.text}
              </Typography>
            </Grid>
            <Grid className={classes.subItem} item xs={12}>
              <Typography
                color="primary"
                className={classes.subHeader}
                component="h4"
                variant="h6"
              >
                {textObj.wait.title}
              </Typography>
              <Typography component="p" variant="h6">
                {textObj.wait.text}
              </Typography>
            </Grid>
            <Grid className={classes.subItem} item xs={12}>
              <Typography
                color="primary"
                className={classes.subHeader}
                component="h4"
                variant="h6"
              >
                {textObj.personalizedCare.title}
              </Typography>
              <Typography component="p" variant="h6">
                {textObj.personalizedCare.text}
              </Typography>
            </Grid>
            <Grid className={classes.subItem} item xs={12}>
              <Typography
                color="primary"
                className={classes.subHeader}
                component="h4"
                variant="h6"
              >
                {textObj.toKnow.title}
              </Typography>
              <Typography component="p" variant="h6">
                {textObj.toKnow.text}
              </Typography>
            </Grid>
            <Grid className={classes.subItem} item xs={12}>
              <Typography
                color="primary"
                className={classes.subHeader}
                component="h4"
                variant="h6"
              >
                {textObj.assessments.title}
              </Typography>
              <Typography component="h4" variant="h6">
                {textObj.assessments.text}
              </Typography>
            </Grid>
            <Grid className={classes.subItem} item xs={12}>
              <Typography
                color="primary"
                className={classes.subHeader}
                component="h4"
                variant="h6"
              >
                {textObj.whenEmergency.title}
              </Typography>
              <List>
                {textObj.whenEmergency.list.map((reason) => (
                  <ListItem className={classes.listItem}>
                    <ListItemText primary={reason} />
                  </ListItem>
                ))}
              </List>
              <Typography
                className={classes.marginBottom}
                component="p"
                variant="h6"
              >
                If you are experiencing any of the above conditions, please call{" "}
                <a
                  href="tel:911"
                  style={{ color: "black", textDecoration: "underline" }}
                >
                  9-1-1.
                </a>
                immediately.
              </Typography>
              <Typography component="p" variant="h6">
                If you have any questions on whether we provide a certain
                service, please call us at{" "}
                <a
                  href="tel:2629250535"
                  style={{ color: "black", textDecoration: "underline" }}
                >
                  262-925-0535
                </a>
                . If you have an emergency,call{" "}
                <a
                  href="tel:911"
                  style={{ color: "black", textDecoration: "underline" }}
                >
                  9-1-1.
                </a>
              </Typography>
            </Grid>
            <Grid item xs>
              <GetDirectionsBtn />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </main>
  );
};

export default UrgentVsEmergencyPage;
