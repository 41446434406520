import React from 'react';
import ArticleLayout from '../ArticleLayout';
import { Article } from '../../../components';
import textObj from './text.json';

const KnoshaCorona: React.FC = () => {
  return (
    <ArticleLayout>
      <Article title={textObj.title} pargraphArr={textObj.textArr} />
    </ArticleLayout>
  );
};

export default KnoshaCorona;
