export const HOME = '/';
export const LOGIN = '/login';
export const SIGNUP = '/signup';
export const CORONA_UPDATE = '/corona-update';
export const SERVICES = '/services';
export const NEW_TELEHELTH_SERVICE = '/';
export const OCCUPATIONAL = '/occupational-health-services';
export const BILLING_AND_INSURANCE = '/insurance-payment';
export const OUR_TEAM = '/meet-our-medical-team';
export const CONTACT_US = '/contact-us';
export const URGENT_CARE_VS_EMERGENCY_ROOM = '/urgent-care-vs-emergency-room';
export const SPECIAL_OFFER = '/special-offer';
export const IN_THE_NEWS = '/in-the-news';
