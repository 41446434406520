import React, { useState } from "react";

import {
  Grid,
  Typography,
  makeStyles,
  createStyles,
  Theme,
  Divider,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Container,
} from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { Helmet } from "react-helmet";

import { Article, GetDirectionsBtn } from "../../../components";
import jsonObj from "./text.json";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: "20vh",
      marginBottom: "2rem",
    },
    bold: {
      fontWeight: "bold",
    },
    nestedList: { marginLeft: theme.spacing(2) },
    nestedText: { marginLeft: theme.spacing(2) },
  })
);

const providers = [
  "United Healthcare",
  "Anthem Blue Cross Blue Shield",
  "Trilogy",
  "Health EOS",
  "Humana",
  "Medicare",
  "Medicaid",
];

const BillingAndInsurancePage: React.FC = () => {
  const [listData, setListData] = useState(() =>
    jsonObj.dropArticles.arr.map((e) => {
      return {
        ...e,
        opened: false,
      };
    })
  );
  const classes = useStyles();

  const handleItemClick = (key: string) => {
    const newArr = listData.map((item: any) => {
      if (item.title === key) {
        return { ...item, opened: !item.opened };
      } else return { ...item, opened: false };
    });
    setListData(newArr);
  };
  return (
    <main className={classes.root}>
      <Helmet>
        <link rel="canonical" href="/insurance-payment" />
        <title>Insurance and Payment | Kenosha Walk-In Clinic</title>
      </Helmet>
      <Container>
        <Grid container>
          <Grid
            item
            container
            spacing={5}
            xs={12}
            md={6}
            style={{ marginBottom: "20px" }}
          >
            <Grid item xs={12}>
              <Article
                title={jsonObj.articlesArray[0].title}
                pargraphArr={jsonObj.articlesArray[0].textArr}
              />
              <List component="ul">
                {providers.map((company) => (
                  <ListItem>
                    <ListItemText>{company}</ListItemText>
                  </ListItem>
                ))}
              </List>
            
            </Grid>
            <Grid item>
              <GetDirectionsBtn />
            </Grid>
          </Grid>

          <Grid container alignContent="flex-start" spacing={1} xs={11} md={6}>
            <Grid item xs={12}>
              <Typography color="primary" component="h4" variant="h3">
                {jsonObj.dropArticles.title}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container direction="column" xs>
                <List component="ul">
                  {listData.map((mainItem: any, index) => (
                    <React.Fragment key={index}>
                      <Divider />
                      <ListItem
                        button
                        onClick={() => handleItemClick(mainItem.title)}
                      >
                        <ListItemText primary={mainItem.title} />
                        {mainItem.opened ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse
                        in={mainItem.opened}
                        timeout="auto"
                        unmountOnExit
                      >
                        {mainItem.header && (
                          <Typography
                            className={classes.nestedText}
                            component="p"
                            variant="body1"
                          >
                            {mainItem.header}
                          </Typography>
                        )}
                        {mainItem.list && (
                          <List
                            className={classes.nestedList}
                            component="div"
                            disablePadding
                          >
                            {mainItem.list.map((subItem: any) => (
                              <ListItem>
                                <ListItemText>{`- ${subItem}`}</ListItemText>
                              </ListItem>
                            ))}
                          </List>
                        )}
                        {mainItem.footer && (
                          <Typography
                            className={classes.nestedText}
                            component="p"
                            variant="body1"
                          >
                            {mainItem.footer}
                          </Typography>
                        )}
                      </Collapse>
                    </React.Fragment>
                  ))}
                </List>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </main>
  );
};

export default BillingAndInsurancePage;
