import { firestore } from 'firebase/app';
import moment from 'moment';

export interface PatinetType {
  id?: string;
  userId?: string;
  firstName: string;
  middleName: string;
  lastName: string;
  dateOfBirth: firestore.Timestamp;
  gender: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zipCode: string;
  SSN: string;
  race: string;
  martialStatus: string;
  prefaredLang: string;
  homePhone?: string;
  cellPhone: string;
  isDeleted?: boolean;
}

export class Patient {
  id?: string;
  userId?: string;
  firstName: string;
  middleName: string;
  lastName: string;
  dateOfBirth: firestore.Timestamp;
  gender: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zipCode: string;
  SSN: string;
  race: string;
  martialStatus: string;
  prefaredLang: string;
  homePhone?: string;
  cellPhone: string;
  isDeleted?: boolean;

  constructor({
    id,
    userId,
    firstName,
    middleName,
    lastName,
    dateOfBirth,
    gender,
    address1,
    address2,
    city,
    state,
    zipCode,
    SSN,
    race,
    martialStatus,
    prefaredLang,
    homePhone,
    cellPhone,
    isDeleted,
}: PatinetType) {
    this.id = id;
    this.userId = userId;
    this.firstName = firstName;
    this.middleName = middleName;
    this.lastName = lastName;
    this.dateOfBirth = dateOfBirth;
    this.gender = gender;
    this.address1 = address1;
    this.address2 = address2;
    this.city = city;
    this.state = state;
    this.zipCode = zipCode;
    this.SSN = SSN;
    this.race = race;
    this.martialStatus = martialStatus;
    this.prefaredLang = prefaredLang;
    this.homePhone = homePhone;
    this.cellPhone = cellPhone;
    this.isDeleted = isDeleted ?? false;
  }
  static firebaseTimeStampToDate(date: firestore.Timestamp): string {
    return moment(date.toDate()).format('yyyy-MM-DD');
  }
}

export const patientConverter = {
  toFirestore: (patient: PatinetType) => {
    return { ...patient };
  },
  fromFirestore: (
    snapshot: firestore.QueryDocumentSnapshot,
    options: firestore.SnapshotOptions,
  ) => {
    const data = snapshot.data(options);
    const {
      userId,
      firstName,
      middleName,
      lastName,
      dateOfBirth,
      gender,
      address1,
      address2,
      city,
      state,
      zipCode,
      SSN,
      race,
      martialStatus,
      prefaredLang,
      homePhone,
      cellPhone,
      isDeleted,
    } = data;
    return new Patient({
      id: snapshot.id,
      userId,
      firstName,
      middleName,
      lastName,
      dateOfBirth,
      gender,
      address1,
      address2,
      city,
      state,
      zipCode,
      SSN,
      race,
      martialStatus,
      prefaredLang,
      homePhone,
      cellPhone,
      isDeleted,
    });
  },
};
