import React from 'react';

import { Field, Form, Formik } from 'formik';
import handleAuthErrors from '../../utilities/handleAuthErrors';
import FirebaseUtil from '../../config/firebase';
import {
  makeStyles,
  createStyles,
  Grid,
  Typography,
} from '@material-ui/core';
import { CustomInput, DashboardDialog } from '..';
import { useSnackbar, OptionsObject as SnackbarOptions } from 'notistack';
import validationSchema from './validation.schema';
import CustomButton from '../Button';

interface FormValues {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    fullWidth: {
      width: '100%',
    },
    autoHeight: {
      height: 'auto',
    },
    titleWrapper: {
      margin: '1rem 0',
    },
    container: {
      margin: '3rem 0',
    },
  }),
);

interface Props {
  onClose: any;
}

const snackbarCommonOpt: SnackbarOptions = {
  autoHideDuration: 3000,
  preventDuplicate: true,
  anchorOrigin: { horizontal: 'right', vertical: 'top' },
};

const ChangePasswordDialog: React.FC<Props> = ({ onClose }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (values: FormValues) => {
    try {
      await FirebaseUtil.auth.currentUser?.updatePassword(values.newPassword);
      enqueueSnackbar('Password changed successfully', {
        variant: 'success',
        ...snackbarCommonOpt,
      });
    } catch (e) {
      enqueueSnackbar(handleAuthErrors(e.code), {
        variant: 'error',
        ...snackbarCommonOpt,
      });
    } finally {
      onClose();
    }
  };

  return (
    <DashboardDialog
      classes={{ paperWidthSm: classes.autoHeight }}
      open={true}
      onClose={onClose}
    >
      <Grid container spacing={3} className={classes.container}>
        <Grid
          item
          className={classes.titleWrapper}
          container
          justify="center"
          xs={12}
        >
          <Typography component="p" variant="h4" color="primary">
            Change Password
          </Typography>
        </Grid>
        <Grid item container justify="center" xs={12}>
          <Grid item xs={9}>
            <Formik
              validationSchema={validationSchema}
              validateOnBlur={false}
              validateOnChange={false}
              initialValues={{
                oldPassword: '',
                newPassword: '',
                confirmNewPassword: '',
              }}
              onSubmit={handleSubmit}
            >
              {({  errors, touched, isSubmitting, handleSubmit }) => (
                <Grid item xs={12}>
                  <Form className={classes.fullWidth} onSubmit={handleSubmit}>
                    <Grid spacing={3} item container xs={12}>
                      <Grid item xs={12}>
                        <Field
                          fullWidth
                          as={CustomInput}
                          variant="filled"
                          name="oldPassword"
                          type="password"
                          label="Old Password *"
                          error={
                            touched.oldPassword && errors.oldPassword && true
                          }
                          helperText={touched.oldPassword && errors.oldPassword}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          fullWidth
                          as={CustomInput}
                          variant="filled"
                          name="newPassword"
                          type="password"
                          label="New Password *"
                          error={
                            touched.newPassword && errors.newPassword && true
                          }
                          helperText={touched.newPassword && errors.newPassword}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          fullWidth
                          as={CustomInput}
                          variant="filled"
                          name="confirmNewPassword"
                          type="password"
                          label="Confirm New Password *"
                          error={
                            touched.confirmNewPassword &&
                            errors.confirmNewPassword &&
                            true
                          }
                          helperText={
                            touched.confirmNewPassword &&
                            errors.confirmNewPassword
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CustomButton
                          disabled={isSubmitting}
                          loading={isSubmitting}
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          Change My Password
                        </CustomButton>
                      </Grid>
                    </Grid>
                  </Form>
                </Grid>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Grid>
    </DashboardDialog>
  );
};
export default ChangePasswordDialog;
