import React, { useState, useContext } from 'react';
import {
  AppBar,
  Toolbar,
  Link,
  Typography,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
  Theme,
} from '@material-ui/core';
import clsx from 'clsx';

import MenuIcon from '@material-ui/icons/Menu';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Link as RouterLink } from 'react-router-dom';

import { UserContext } from '../../context/UserProvider';
import Logo from '../../assets/logo.png';

import useStyles from './styles';
import AvatarWithMenu from '../AvatarWithMenu';
import MobileDrawer from '../MobileDrawer';
import DrawerContent from '../DrawerContent';
import {
  HOME,
  LOGIN,
  SERVICES,
  OCCUPATIONAL,
  BILLING_AND_INSURANCE,
  OUR_TEAM,
  CONTACT_US,
  URGENT_CARE_VS_EMERGENCY_ROOM,
  SPECIAL_OFFER,
  IN_THE_NEWS,
} from '../../containers/PublicWebsite/constants';
export interface Props {
  transparent?: boolean;
}

const links = [
  {
    route: SERVICES,
    name: 'SERVICES',
  },
  {
    route: OCCUPATIONAL,
    name: 'OCCUPATIONAL',
  },
  {
    route: BILLING_AND_INSURANCE,
    name: 'INSURANCE & PAYMENT',
  },
  {
    route: OUR_TEAM,
    name: 'OUR TEAM',
  },
  {
    route: CONTACT_US,
    name: 'CONTACT',
  },
];

const infoSubLinks = [
  {
    route: URGENT_CARE_VS_EMERGENCY_ROOM,
    name: 'Urgent Care Vs Emergency Room',
  },
  {
    route: SPECIAL_OFFER,
    name: 'Special Offers',
  },
  {
    route: IN_THE_NEWS,
    name: 'In The News',
  },
  {
    route: '/articles',
    name: 'Health Articles',
  },
];

const NavBar: React.FC<Props> = props => {
  const classes = useStyles(props);
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );
  const { user } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="fixed" className={classes.root} elevation={0}>
      <Toolbar className={classes.toolbar}>
        <Grid className={classes.logoWrapper} container alignItems="center">
          <Grid item>
            {isSmallScreen && (
              <IconButton
                className={classes.menuIcon}
                onClick={() => setShowMobileMenu(c => !c)}
                edge="start"
                color="primary"
                aria-label="menu"
              >
                <MenuIcon fontSize="large" />
              </IconButton>
            )}
          </Grid>

          <Grid item>
            <Link component={RouterLink} to={HOME}>
              <div className={classes.logoWrapper}>
                <img src={Logo} alt="logo" className={classes.img} />
              </div>
            </Link>
          </Grid>
        </Grid>

        <>
          <MobileDrawer
            open={showMobileMenu}
            onClose={() => setShowMobileMenu(false)}
          >
            <DrawerContent onClose={() => setShowMobileMenu(false)} />
          </MobileDrawer>
          {!isSmallScreen && (
            <>
              {links.map((item,index) => (
                <Link
                  className={classes.title}
                  component={RouterLink}
                  to={item.route}
                  key={index}
                >
                  <Typography
                    className={classes.linkText}
                    variant="h6"
                    component="h2"
                  >
                    {item.name}
                  </Typography>
                </Link>
              ))}
              <Link
                component="button"
                variant="h6"
                aria-controls="simple-menu"
                aria-haspopup="true"
                className={clsx(classes.linkText, classes.infoBtn)}
                onClick={handleClick}
              >
                INFO
                <ExpandMoreIcon />
              </Link>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {infoSubLinks.map((l,index) => (
                  <Link component={RouterLink} to={l.route} key={index}>
                    <MenuItem onClick={handleClose}>
                      <Typography variant="h6" component="h2">
                        {l.name}
                      </Typography>
                    </MenuItem>
                  </Link>
                ))}
              </Menu>
            </>
          )}
        </>
        {user ? (
          <AvatarWithMenu
            avatarUrl={user.profilePicture}
            showName={false}
            userName={user.name}
          />
        ) : (
          <Link className={classes.title} component={RouterLink} to={LOGIN}>
            <Typography
              className={clsx(classes.linkText, classes.loginText)}
              variant="h6"
              component="h2"
            >
              Login / Signup
            </Typography>
          </Link>
        )}
      </Toolbar>
    </AppBar>
  );
};
export default NavBar;
