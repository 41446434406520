import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import ArticleLayout from '../ArticleLayout';
import { Article, GetDirectionsBtn } from '../../../components';
import textObj from './text.json';
import BlogFooter from '../BlogFooter';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(10),
    },
  }),
);

const MidAugestOption: React.FC = () => {
  const classes = useStyles();
  return (
    <main className={classes.root}>
      <Helmet>
        <title>In the News | Kenosha Urgicare</title>
        <link rel="canonical" href="/in-the-news" />
      </Helmet>
      <ArticleLayout>
        <Article title={textObj.title} pargraphArr={textObj.textArr} />
        <BlogFooter className={classes.root} />
        <GetDirectionsBtn />
      </ArticleLayout>
    </main>
  );
};

export default MidAugestOption;
