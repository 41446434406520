import React from 'react';
import {
  Grid,
  CardMedia,
  makeStyles,
  createStyles,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginTop: '20vh',
    },
    img: {
      width: '200px',
      marginBottom: '3rem',
    },
    centerText: {
      textAlign: 'center',
    },
  }),
);

const PageNotFound: React.FC = () => {
  const classes = useStyles();

  return (
    <main>
      <Grid className={classes.root} container justify="center">
        <Grid item xs={8} container justify="center">
          <CardMedia
            className={classes.img}
            component="img"
            image={require('../../assets/doctors.png')}
          />
        </Grid>
        <Grid item container justify="center" xs={12}>
          <Grid item xs={12}>
            <Typography
              className={classes.centerText}
              variant="h3"
              component="p"
            >
              404 Page Not Found
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              className={classes.centerText}
              component="p"
              variant="h4"
            >
              We didn't find the page you are looking for.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </main>
  );
};

export default PageNotFound;
