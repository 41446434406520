import React, { useState, useCallback } from 'react';
import {
  Grid,
  Slider,
  makeStyles,
  createStyles,
  Button,
  Typography,
  Theme,
} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CancelIcon from '@material-ui/icons/Cancel';
import Cropper from 'react-easy-crop';
import DashboardDialog from '../DashboardDialog';
import CustomButton from "../Button";

interface Props {
  open: boolean;
  onClose: () => any;
  getCropedArea: any;
  image: any;
  onSubmit: any;
  isSubmitting: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: '100%',
    },
    title: {
      margin: '1rem 0',
      textAlign: 'center',
    },
    cropperGridContainer: {
      margin: '1rem',
      height: '400px',
      [theme.breakpoints.down('xs')]: {
        height: '250px',
      },
    },
    cropperGridItem: {
      position: 'relative',
    },
    cropperWrapper: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: '80px',
      height: '100%',
    },
    zoomWrapper: {
      height: 'fit-content',
    },
    sliderWrapper: {},
    slider: {
      padding: '22px 0',
    },
  }),
);

const EditAvatarDialog: React.FC<Props> = ({
  open,
  onClose,
  image,
  onSubmit,
  getCropedArea,
  isSubmitting,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const classes = useStyles();

  const onCropComplete = useCallback(
    (croppedArea, croppedAreaPixels) => {
      getCropedArea(croppedAreaPixels);
    },
    [getCropedArea],
  );

  return (
    <DashboardDialog open={true} onClose={onClose}>
      <Typography className={classes.title} component="p" variant="h4">
        Edit Profile Picture
      </Typography>
      <Grid container className={classes.container} direction="row">
        <Grid
          className={classes.cropperGridContainer}
          item
          container
          justify="center"
          xs={12}
        >
          <Grid className={classes.cropperGridItem} item xs={10}>
            <div className={classes.cropperWrapper}>
              <Cropper
                image={image}
                crop={crop}
                zoom={zoom}
                aspect={4 / 3}
                showGrid={false}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </div>
          </Grid>
        </Grid>
        <Grid
          className={classes.sliderWrapper}
          item
          container
          justify="center"
          xs={12}
        >
          <Grid item className={classes.zoomWrapper} container xs={9}>
            <Grid item container justify="center" alignItems="center" xs={12}>
              <Grid item xs={3} sm={2}>
                <Typography id="Zoom" gutterBottom>
                  Zoom
                </Typography>
              </Grid>
              <Grid item xs>
                <Slider
                  className={classes.slider}
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={(e, zoom) => setZoom(zoom as number)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container justify="space-around" xs={12}>
          <Grid item>
            <Button
              startIcon={<CancelIcon />}
              variant="contained"
              color="primary"
              onClick={onClose}
              disabled={isSubmitting}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <CustomButton
              onClick={onSubmit}
              startIcon={<CloudUploadIcon />}
              variant="contained"
              disabled={isSubmitting}
              loading={isSubmitting}
              color="primary"
            >
              Upload
            </CustomButton>
          </Grid>
        </Grid>
      </Grid>
    </DashboardDialog>
  );
};

export default EditAvatarDialog;
