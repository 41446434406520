import React from "react";
import {
  Typography,
  Container,
  Grid,
  makeStyles,
  Link,
  createStyles,
  Theme,
} from "@material-ui/core";
import { Helmet } from "react-helmet";
import { GetDirectionsBtn } from "../../../components";
import { Link as RouterLink } from "react-router-dom";
import textObj from "./text.json";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: "20vh",
      marginBottom: theme.spacing(10),
    },
    contactData: {
      "& span": {
        color: "#333",
        marginLeft: theme.spacing(2),
      },
    },
    bottomMargin: {
      marginBottom: theme.spacing(2),
    },
    currentlyText: {
      letterSpacing: "2",
      lineHeight: "2",
      marginBottom: theme.spacing(2),
    },
    marginBottom: {
      marginBottom: theme.spacing(2),
    },
    basedOnService: {
      marginBottom: theme.spacing(3),
    },
    mainHead: {
      marginBottom: theme.spacing(2),
    },
    notBold: {
      fontWeight: "normal",
    },
  })
);

const SpecialOffersPage: React.FC = () => {
  const classes = useStyles();
  return (
    <main className={classes.root}>
      <Helmet>
        <title>Special Offers | Kenosha UrgiCare"</title>
        <link rel="canonical" href="/special-offer" />
      </Helmet>
      <Container maxWidth="xl">
        <Grid container>
          <Grid item container xs={12} md={8}>
            <Grid className={classes.mainHead} item xs={12}>
              <Typography
                className={classes.notBold}
                color="primary"
                component="h3"
                variant="h2"
              >
                {textObj.mainTitle}
              </Typography>
            </Grid>
            <Grid
              className={classes.marginBottom}
              item
              container
              spacing={4}
              xs={12}
            >
              <Grid item xs={12}>
                <Typography component="p" variant="h6">
                  {textObj.mainIntro.welcome}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography component="p" variant="h6">
                  {textObj.mainIntro.seasonal}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography
                className={classes.currentlyText}
                color="primary"
                component="p"
                variant="h6"
              >
                {textObj.currently}
              </Typography>
            </Grid>
            <Grid className={classes.basedOnService} item xs={12}>
              <Typography component="p" variant="h6">
                {textObj.basedOnService}
              </Typography>
            </Grid>
            <Grid
              className={classes.bottomMargin}
              item
              xs={12}
              container
              spacing={3}
              direction="column"
            >
              <Grid item xs>
                <Typography
                  className={classes.contactData}
                  color="primary"
                  component="p"
                  variant="h6"
                >
                  {textObj.call.label}
                  <a
                    href="tel:2629250535"
                    style={{ color: "black", textDecoration: "underline" }}
                  >
                    <span>{textObj.call.number}</span>
                  </a>
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography
                  className={classes.contactData}
                  color="primary"
                  component="p"
                  variant="h6"
                >
                  {textObj.email.label}
                <a href="mailto:contact@kenoshaurgicare.com"   style={{ color: "black", textDecoration: "underline" }}>  <span>{textObj.email.text}</span></a>
                 
                </Typography>
              </Grid>

              <Grid item xs>
                <Typography component="p" variant="h6">
                  Location and Mailing Address:
                </Typography>
                <a href="https://www.google.com/maps/place/6430+Green+Bay+Rd,+Kenosha,+WI+53142,+USA/@42.5761772,-87.8885366,17z/data=!3m1!4b1!4m5!3m4!1s0x880560fa2fd8d38b:0xdcf7bf58faba92b!8m2!3d42.5761733!4d-87.8863479" target="_blanck" style={{ color: "black", textDecoration: "underline" }}> 
                <Typography component="p" variant="h6">
                  6430 Green Bay Road, Kenosha, WI 53142
                </Typography>
                </a>
              </Grid>
            </Grid>
            <Grid item xs>
              <GetDirectionsBtn />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </main>
  );
};

export default SpecialOffersPage;
