import React from 'react';
import { makeStyles, createStyles, Typography, Theme } from '@material-ui/core';
import { FaFacebookF } from 'react-icons/fa';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: '455px',
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down('lg')]: {
        minHeight: '390px',
      },
      backgroundColor: '#333',
      '& > *': {
        color: '#fff',
      },
    },
    content: {
      display: 'flex',
      justifyContent: 'center',
      maxWidth: '1170px',

      alignItems: 'center',
      padding: '2rem',
      '& > div': {
        marginRight: '1.25rem',
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        padding: '1rem',
        textAlign: 'center',
        '& > div': {
          marginBottom: '1.25rem',
        },
      },
    },
    fullWidth: {
      width: '100%',
    },
    logoText: {
      marginLeft: '0.5rem',
    },
    ico: {
      marginRight: theme.spacing(2),
    },
  }),
);

const Footer: React.FC = () => {
  const classes = useStyles();
  return (
    <footer className={classes.root}>
      <div className={classes.content}>
        <div className={classes.fullWidth}>
          <div>
            <img src={require('../../assets/logo.png')} alt="logo" />
          </div>
          <Typography className={classes.logoText} component="p" variant="h6">
            6430 Green Bay Rd
            <br /> Kenosha, WI 53142
            <br/>
            <a href="tel:2629250535" style={{color:"white" , textDecoration:'none'}}>
        📞262-925-0535
    </a>
            <br /> 
          </Typography>
        </div>
        <div>
          <Typography component="p" variant="h6">
            No one should have to wait out an illness or injury because they
            can’t afford the right care. We keep our medical care affordable so
            people can take care of minor issues before they become big
            problems.
          </Typography>
        </div>
        <div>
          <a
            href="https://www.facebook.com/kenoshaurgicare"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebookF className={classes.ico} color="#ADADAD" />
          </a>
          <Typography component="p" variant="h6">
            We Proudly Offer Urgent Care Services in the Following Areas:
            Kenosha, Bristol, Paddock Lake, Pleasant Prairie, Burlington WI,
            Racine, Sturtevent, Waukegan IL, Zion IL, Lake Geneva WI, Gurnee IL
            Silver Lake, Twin Lakes, Fossland IL, Russell IL, Rosecrans IL, Dunn
            Corners IL & More...
          </Typography>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
