import React, { useState } from 'react';

import {
  Grid,
  Typography,
  makeStyles,
  createStyles,
  Theme,
  Divider,
  List,
  ListItem,
  ListItemText,
  Collapse,
  useMediaQuery,
  Container,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Helmet } from 'react-helmet';
import { Article, GetDirectionsBtn } from '../../../components';
import jsonObj from './text.json';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: '20vh',
    },
    muiLimit: {
      padding: '40px',
      [theme.breakpoints.down('xs')]: {
        padding: '20px',
      },
    },
    nestedList: { marginLeft: theme.spacing(2) },
    nestedText: { marginLeft: theme.spacing(2) },
  }),
);

const articleList = [
  'Preventing injuries',
  'Evaluating and treating workplace injuries',
  'Employee wellness',
  'Pre-placement testing',
  'Occupational medicine and therapy',
];

const OccupationalPage: React.FC = () => {
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const [listData, setListData] = useState(() =>
    jsonObj.dropArticles.arr.map(e => {
      return {
        ...e,
        opened: false,
      };
    }),
  );
  const classes = useStyles();

  const handleItemClick = (key: string) => {
    const newArr = listData.map((item: any) => {
      if (item.title === key) {
        return { ...item, opened: !item.opened };
      } else return { ...item, opened: false };
    });
    setListData(newArr);
  };
  return (
    <main className={classes.root}>
      <Helmet>
        <title>Occupational | Kenosha UrgiCare</title>
        <meta
          name="description"
          content="Occupational health is a field of medicine dedicated to the health and safety of workers and employees in the workplace."
        />
        <link rel="canonical" href="/occupational-health-services" />
      </Helmet>

      <Container maxWidth={false}>
        <div className={classes.muiLimit}>
          <Grid container spacing={isSmall ? 2 : 10}>
            <Grid item container spacing={5} xs={12} md={6}>
              <Grid item xs={12}>
                <Article
                  title={jsonObj.articlesArray[0].title}
                  pargraphArr={jsonObj.articlesArray[0].textArr}
                />
                <List component="ul">
                  {articleList.map(item => (
                    <ListItem>
                      <ListItemText>{item}</ListItemText>
                    </ListItem>
                  ))}
                </List>
              </Grid>
              <Grid item>
                <GetDirectionsBtn />
              </Grid>
            </Grid>

            <Grid item container spacing={2} xs={12} md={6}>
              <Grid item xs={12}>
                <Typography color="primary" component="h4" variant="h3">
                  {jsonObj.dropArticles.title}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <List component="ul">
                  {listData.map((mainItem: any, index) => (
                    <React.Fragment key={index}>
                      <Divider />
                      <ListItem
                        button
                        onClick={() => handleItemClick(mainItem.title)}
                      >
                        <ListItemText primary={mainItem.title} />
                        {mainItem.opened ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse
                        in={mainItem.opened}
                        timeout="auto"
                        unmountOnExit
                      >
                        {mainItem.header && (
                          <Typography
                            className={classes.nestedText}
                            component="p"
                            variant="body1"
                          >
                            {mainItem.header}
                          </Typography>
                        )}
                        <List
                          className={classes.nestedList}
                          component="div"
                          disablePadding
                        >
                          {mainItem.list.map((subItem: any) => (
                            <ListItem>
                              <ListItemText>{`- ${subItem}`}</ListItemText>
                            </ListItem>
                          ))}
                        </List>
                        {mainItem.footer && (
                          <Typography
                            className={classes.nestedText}
                            component="p"
                            variant="body1"
                          >
                            {mainItem.footer}
                          </Typography>
                        )}
                      </Collapse>
                    </React.Fragment>
                  ))}
                </List>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Container>
    </main>
  );
};

export default OccupationalPage;
