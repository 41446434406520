import React from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  Grid,
  Typography,
  CardContent,
  Card,
} from '@material-ui/core';
import clsx from 'clsx';

interface Props {
  title: string;
  description: string;
  imgLink: any;
  className?: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    title: {
      textAlign: 'center',
    },
    centerText: {
      textAlign: 'center',
    },
    ourTeamCardItem: {
      maxWidth: '390px',
    },
    ourTeamCardTitle: {
      fontSize: '21px',
      color: theme.palette.primary.dark,
    },
    ourTeamCardDesc: {
      fontSize: '16px',
      color: theme.palette.primary.dark,
    },
  }),
);

const OffersCard: React.FC<Props> = ({
  className,
  title,
  description,
  imgLink,
}) => {
  const classes = useStyles();
  return (
    <Card className={clsx(classes.ourTeamCardItem, className)}>
      <CardContent>
        <Grid container>
          <Grid item xs={2}>
            <img src={imgLink} alt="icon" />
          </Grid>
          <Grid item xs={10}>
            <Typography
              className={classes.ourTeamCardTitle}
              component="p"
              variant="h6"
            >
              {title}
            </Typography>
            <Typography
              className={classes.ourTeamCardDesc}
              component="p"
              variant="h6"
            >
              {description}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default OffersCard;
