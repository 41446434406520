import React, { useState} from 'react';
import {
  Typography,
  makeStyles,
  Link,
  createStyles,
  Theme,
  Grid,
  useMediaQuery,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { Formik, Field, Form, FormikHelpers } from 'formik';
import { useStyles as useSignupStyles } from '../LoginForm';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { CustomButton, CustomInput } from '../../components';
import validationSchema from './validation.schema';
import FirebaseUtil from '../../config/firebase';
import { handleAuthErrors } from '../../utilities';
import DoctorsSvg from '../../SVG/dcotorsSVGJSX';

interface FormValues {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    haveAccount: {
      marginTop: '1rem',
    },
    signupForm: {
        marginTop:'2rem',
      marginLeft: '5rem',
      height: 'fit-content',
      maxWidth: '650px',
      [theme.breakpoints.down('md')]: {
        marginLeft: '0 !important',
      },
    },
    root: {
        padding:'1rem',
      paddingTop: '6rem',
      background: '#F7F6F1',
      paddingBottom: '3rem',
    },
    formHeader: {
      textAlign: 'center',
      marginBottom: '0.5rem',
    },
    footerText: {
      textAlign: 'end',
      marginTop: '1rem',
    },
    svgDoctors: {
      maxWidth: '1020px',
      width: '100%',
    },
  }),
);

const SignupForm: React.FC = () => {
  const classes1 = useSignupStyles();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [error, setError] = useState('');
  const showSVG = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  const handleSubmit = async (
    values: FormValues,
    helpers: FormikHelpers<FormValues>,
  ) => {
    const { name, email, password } = values;
    try {
      const { user } = await FirebaseUtil.signUp(email, password);
      if (user) {
        await Promise.all([
          FirebaseUtil.firestore.doc(`users/${user.uid}`).set({
            name,
            email,
            addresses: [],
            patientsIds: [],
            insurances: [],
          }),

          user.sendEmailVerification(),
        ]);
        helpers.resetForm();
        enqueueSnackbar(
          'Signed Up successfully, please verify your email before login',
          {
            variant: 'success',
            autoHideDuration: 3000,
            preventDuplicate: true,
            anchorOrigin: { horizontal: 'right', vertical: 'top' },
          },
        );
        history.push('/login');

        await FirebaseUtil.auth.signOut();
      }
    } catch (e) {

      const errMsg: string = handleAuthErrors(e.code);
      setError(errMsg);
    }
  };

  return (
    <section>
      <Grid container className={classes.root} justify="center">
        <Grid
          className={classes.signupForm}
          item
          spacing={2}
          xs={12}
          md={12}
          lg={6}
          container
        >
          <Grid item xs={12}>
            <Typography component="h2" variant="h2">
              Sign Up
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography component="p" variant="h6">
              Please Sign Up to continue
            </Typography>
          </Grid>
          <Grid className={classes1.errWrapper} item xs={12}>
            {error && (
              <Typography
                className={classes1.errorMsg}
                color="error"
                component="p"
                variant="h6"
              >
                {error}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} container>
            <Formik
              validationSchema={validationSchema}
              initialValues={{
                name: '',
                email: '',
                password: '',
                confirmPassword: '',
              }}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, isSubmitting, handleSubmit }) => (
                <Form className={classes1.form} onSubmit={handleSubmit}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Field
                        fullWidth
                        className={classes1.input}
                        variant="filled"
                        label="Name *"
                        name="name"
                        as={CustomInput}
                        error={touched.name && errors.name && true}
                        helperText={touched.name && errors.name}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        fullWidth
                        className={classes1.input}
                        variant="filled"
                        label="Email *"
                        name="email"
                        as={CustomInput}
                        error={touched.email && errors.email && true}
                        helperText={touched.email && errors.email}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        fullWidth
                        className={classes1.input}
                        type="password"
                        variant="filled"
                        label="Password *"
                        name="password"
                        as={CustomInput}
                        error={touched.password && errors.password && true}
                        helperText={touched.password && errors.password}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        fullWidth
                        className={classes1.input}
                        type="password"
                        variant="filled"
                        label="Confirm Password *"
                        name="confirmPassword"
                        as={CustomInput}
                        error={
                          touched.confirmPassword &&
                          errors.confirmPassword &&
                          true
                        }
                        helperText={
                          touched.confirmPassword && errors.confirmPassword
                        }
                      />
                    </Grid>
                    <Grid item xs>
                      <CustomButton
                        disabled={isSubmitting}
                        loading={isSubmitting}
                        type="submit"
                        color="primary"
                        size="small"
                        variant="contained"
                        disableElevation
                      >
                        Sign Up
                      </CustomButton>
                    </Grid>
                    <Grid
                      item
                      container
                      className={classes.haveAccount}
                      alignItems="center"
                      xs={12}
                    >
                      <Typography component="p" variant="h6">
                        do you have an account ?{' '}
                        <Link component={RouterLink} to="/login">
                          Sign in
                        </Link>
                      </Typography>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
        {showSVG && (
          <Grid item container justify="flex-end" xs>
            <DoctorsSvg className={classes.svgDoctors} />
          </Grid>
        )}
      </Grid>
    </section>
  );
};

export default SignupForm;
