import React, { useState } from 'react';
import {
  makeStyles,
  createStyles,
  ListItem,
  ListItemText,
  List,
  ListItemIcon,
  Button,
  Grid,
  CardMedia,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import LogoutIcon from '@material-ui/icons/ExitToAppOutlined';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Logo from '../../assets/logo.png';
import { useHistory, useRouteMatch } from 'react-router-dom';
import publicSiteContent from './publicSiteContent';
import userPortalContent from './userPortalContent';
import FirebaseUtil from '../../config/firebase';

interface DrawerItem {
  title: string;
  route: string;
  icon?: any;
  current: boolean;
  [x: string]: any;
}

export const drawerWidth = 240;

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100%',
      marginTop: '5rem',
    },
    logoWrapper: {
      height: '120px',
      marginTop: '3rem',
    },
    logo: {
      width: '190px',
      height: 'fit-content',
        cursor:'pointer'
    },

    logoCard: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '100%',
    },
    avatar: {
      marginBottom: '0.75rem',
    },
    textColor: {
      color: '#fff',
    },
    current: {
      fontWeight: 'bold',
    },

    websiteBtn: {
      marginTop: 'auto',
      marginBottom: '1rem',
    },
  }),
);
interface Props {
  onClose?: () => void;
}

const DrawerContent: React.FC<Props> = ({ children, onClose }) => {
  const classes = useStyles();
  const history = useHistory();
  const { url } = useRouteMatch();

  const [drawerItems, setDrawerItems] = useState<DrawerItem[]>(() => {
    return url.includes('dashboard') ? userPortalContent : publicSiteContent;
  });

  const handleClick = (ele: DrawerItem) => {
    setDrawerItems(
      drawerItems.map(i => {
        return { ...i, current: i.route === ele.route };
      }),
    );
    history.push(`${url !== '/' ? url : ''}${ele.route}`);
    if (onClose) onClose();
  };

  return (
    <Grid className={classes.root} container>
      <Grid item container xs={12} justify="center">
        <Grid item xs={12} container justify="center">
          <CardMedia className={classes.logo} component="img" image={Logo} onClick={()=>history.push('/')}/>
        </Grid>
        <Grid item xs={12}>
          <List>
            {drawerItems.map(ele => (
              <ListItem
                button
                key={ele.title}
                className={clsx(
                  classes.textColor,
                  ele.current && classes.current,
                )}
                onClick={() => handleClick(ele)}
              >
                {ele.icon && (
                  <ListItemIcon>
                    <ele.icon className={classes.textColor} />
                  </ListItemIcon>
                )}
                <ListItemText
                  classes={{
                    primary: ele.current ? classes.current : undefined,
                  }}
                  primary={ele.title}
                />
              </ListItem>
            ))}
            {url.includes('dashboard') && (
              <ListItem
                button
                key="logout"
                className={classes.textColor}
                onClick={() => FirebaseUtil.signOut()}
              >
                <ListItemIcon>
                  <LogoutIcon className={classes.textColor} />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </ListItem>
            )}
          </List>
          {children}
        </Grid>
      </Grid>

        {url.includes('dashboard') ?
            <Grid item xs={12} container justify="center" alignItems="flex-end">
                <Grid item xs={12}>
                    <Button
                        className={classes.websiteBtn}
                        component={RouterLink}
                        to="/"
                        color="secondary"
                    >
                        <ArrowBackIosIcon />
                        Return to the WebSite
                    </Button>
                </Grid>
            </Grid>
        :null}

    </Grid>
  );
};

export default DrawerContent;
