import ScheduleIcon from '@material-ui/icons/Schedule';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import AssignmentIcon from '@material-ui/icons/AssignmentOutlined';
import AccountBillingIcon from '@material-ui/icons/AccountBalanceOutlined';
import {
  APPOINTMENT,
  INSURANCE,
  PATIENT,
  PAYMENT,
} from '../../containers/UserDashboard/constants';

const drawerData = [
  {
    title: 'Appointments ',
    route: APPOINTMENT,
    icon: ScheduleIcon,
    current: false,
  },
  {
    title: 'Insurances ',
    route: INSURANCE,
    icon: AssignmentIcon,
    current: false,
  },
  {
    title: 'Patients ',
    route: PATIENT,
    icon: PersonOutlineOutlinedIcon,
    current: false,
  },
  {
    title: 'Payments ',
    route: PAYMENT,
    icon: AccountBillingIcon,
    current: false,
  },
];

export default drawerData;
