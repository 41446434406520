import React, { useState } from 'react';

import {
	Typography,
	makeStyles,
	createStyles,
	Grid,
	Button,
	Link,
	useMediaQuery,
	Theme,
} from '@material-ui/core';

import { Formik, Field, Form } from 'formik';
import { Link as RouterLink } from 'react-router-dom';
import CustomInput from '../CustomInput';
import { handleAuthErrors } from '../../utilities';
import { emailSchema, schema } from './validation.schema';
import FirebaseUtil from '../../config/firebase';
import PasswordResetDialog from '../PasswordResetDialog';
import DoctorsSvg from '../../SVG/dcotorsSVGJSX';
import { CustomButton } from '..';
import VerificationWrapper from '../verification-wrapper/verification-wrapper';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			marginTop: '5rem',
			background: '#F7F6F1',
			[theme.breakpoints.down('md')]: {
				padding: '2rem',
			},
		},
		loginForm: {
			marginLeft: '5rem',
			marginTop: '6rem',
			height: 'fit-content',
			maxWidth: '650px',
			[theme.breakpoints.down('md')]: {
				marginLeft: '0 !important',
				marginTop: '3rem',
			},
		},
		input: { minHeight: '82px' },
		form: { width: '100%' },
		uppercase: {
			textTransform: 'uppercase',
		},
		signupText: {
			marginTop: '10px',
		},
		errorMsg: {
			fontSize: '14px',
			background: '#FFC199',
			padding: '0.25rem 0.5rem',
			borderRadius: '3px',
		},
		errWrapper: {
			minHeight: '45px',
		},
		svgDoctors: {
			maxWidth: '1020px',
			width: '100%',
		},
		emailVerfyBtn: {
			fontSize: '17.5px',
			fontWeight: 'normal',
			textTransform: 'capitalize',
		},
	})
);

interface FormValues {
	email: string;
	password: string;
}

const LoginForm: React.FC = () => {
	const classes = useStyles();
	const [showPassReset, setShowPassReset] = useState(false);
	const [error, setError] = useState<string | undefined>(undefined);
	const [showSendEmail, setShowSendEmail] = useState(false);
	const showSVG = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

	const handleLoginEmail = async (values: { email: string }) => {
		try {
			await FirebaseUtil.auth.signInWithEmailLink(
				values.email,
				window.location.href
			);
			window.localStorage.setItem('emailSignIn', 'true');
		} catch (e) {
			setError(e.message);
		}
	};

	const closeVerificationDialog = () => {
		setShowSendEmail(false);
		setError(undefined);
	};

	const handleSubmit = async (values: FormValues) => {
		try {
			const { user } = await FirebaseUtil.signIn(values.email, values.password);
			if (!user) setError('User not found');
			else if (user && !user.emailVerified) {
				setError('Please verify your email');
				setShowSendEmail(true);
				await FirebaseUtil.signOut();
			}
		} catch (e) {
			const errMsg: string = handleAuthErrors(e.code);
			setError(errMsg);
		}
	};
	return (
		<div>
			{FirebaseUtil.auth.isSignInWithEmailLink(window.location.href) ? (
				<Grid
					className={classes.root}
					container
					justify={showSVG ? 'flex-start' : 'center'}>
					<Grid className={classes.loginForm} item container xs={12} lg={6}>
						<Grid item xs={12}>
							<Typography component='h2' variant='h2'>
								Sign IN
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography component='p' variant='h6'>
								Please sign in to continue
							</Typography>
						</Grid>
						<Grid className={classes.errWrapper} item xs={12}>
							{error && (
								<>
									<Typography
										className={classes.errorMsg}
										color='error'
										component='p'
										variant='h6'>
										{error}
									</Typography>
								</>
							)}
						</Grid>
						<Grid xs={12} container>
							<Formik
								validationSchema={emailSchema}
								initialValues={{
									email: '',
									password: '',
								}}
								onSubmit={handleLoginEmail}>
								{({ errors, touched, isSubmitting, handleSubmit }) => (
									<Form className={classes.form} onSubmit={handleSubmit}>
										<Grid className={classes.input} item xs={12}>
											<Field
												fullWidth
												variant='filled'
												label='Email *'
												name='email'
												as={CustomInput}
												error={touched.email && errors.email && true}
												helperText={touched.email && errors.email}
											/>
										</Grid>
										<Grid
											item
											container
											spacing={2}
											direction='row'
											justify='flex-start'
											alignItems='center'
											xs={12}>
											<Grid item xs>
												<CustomButton
													disabled={isSubmitting}
													loading={isSubmitting}
													type='submit'
													color='primary'
													size='small'
													variant='contained'
													disableElevation>
													Sign in
												</CustomButton>
											</Grid>
										</Grid>
									</Form>
								)}
							</Formik>
						</Grid>
					</Grid>
					{showSVG && (
						<Grid item container justify='flex-end' xs>
							<DoctorsSvg className={classes.svgDoctors} />
						</Grid>
					)}
				</Grid>
			) : (
				<>
					{showPassReset && (
						<PasswordResetDialog
							open={showPassReset}
							onClose={() => setShowPassReset(false)}
							handleClose={() => setShowPassReset(false)}
						/>
					)}

					<Grid
						className={classes.root}
						container
						justify={showSVG ? 'flex-start' : 'center'}>
						<Grid className={classes.loginForm} item container xs={12} lg={6}>
							<Grid item xs={12}>
								<Typography component='h2' variant='h2'>
									Sign IN
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography component='p' variant='h6'>
									Please sign in to continue
								</Typography>
							</Grid>
							<Grid className={classes.errWrapper} item xs={12}>
								{error && (
									<>
										<Typography
											className={classes.errorMsg}
											color='error'
											component='p'
											variant='h6'>
											{error}
										</Typography>
										<>
											{showSendEmail && (
												<VerificationWrapper
													open={showSendEmail}
													closeDialog={closeVerificationDialog}
												/>
											)}
										</>
									</>
								)}
							</Grid>
							<Grid item xs={12} container>
								<Formik
									validationSchema={schema}
									initialValues={{
										email: '',
										password: '',
									}}
									onSubmit={handleSubmit}>
									{({ errors, touched, isSubmitting, handleSubmit }) => (
										<Form className={classes.form} onSubmit={handleSubmit}>
											<Grid className={classes.input} item xs={12}>
												<Field
													fullWidth
													variant='filled'
													label='Email *'
													name='email'
													as={CustomInput}
													error={touched.email && errors.email && true}
													helperText={touched.email && errors.email}
												/>
											</Grid>
											<Grid className={classes.input} item xs={12}>
												<Field
													fullWidth
													variant='filled'
													label='Password *'
													name='password'
													type='password'
													as={CustomInput}
													error={touched.password && errors.password && true}
													helperText={touched.password && errors.password}
												/>
											</Grid>

											<Grid
												item
												container
												spacing={2}
												direction='row'
												justify='flex-start'
												alignItems='center'
												xs={12}>
												<Grid item xs>
													<CustomButton
														disabled={isSubmitting}
														loading={isSubmitting}
														type='submit'
														color='primary'
														size='small'
														variant='contained'
														disableElevation>
														Sign in
													</CustomButton>
												</Grid>
												<Grid item xs>
													<Button
														variant='text'
														onClick={() => {
															setShowPassReset(true);
														}}>
														<Typography
															className={classes.uppercase}
															component='p'
															variant='h6'>
															forgot password?
														</Typography>
													</Button>
												</Grid>
											</Grid>
										</Form>
									)}
								</Formik>
							</Grid>
							<Grid className={classes.signupText} item xs={12}>
								<Typography component='p' variant='h6'>
									Don't have an account?
									<Link component={RouterLink} to='/signup'>
										{' '}
										Sign Up now
									</Link>
								</Typography>
							</Grid>
						</Grid>
						{showSVG && (
							<Grid item container justify='flex-end' xs>
								<DoctorsSvg className={classes.svgDoctors} />
							</Grid>
						)}
					</Grid>
				</>
			)}
		</div>
	);
};
export default LoginForm;
