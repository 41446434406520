import React from 'react';
import RoomIcon from '@material-ui/icons/Room';
import GoogleMapReact from 'google-map-react';
import { blue } from '@material-ui/core/colors';

const Marker: React.FC<{ text: string; [x: string]: any }> = ({ text }) => (
  <RoomIcon
    style={{
      color: blue[500],
    }}
    width="100px"
    height="100px"
  />
);

interface MapProps {
  [x: string]: any;
}

const GoogleMap: React.FC<MapProps> = ({
  center = {
    lat: 42.576215235236134,
    lng: -87.88628295959946,
  },
  zoom = 11,
}) => {
  return (
    <div>
      <div style={{ height: '500px', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyDA-_cBTB9jkPqqlaJX_1xZjl7zhH_3adw' }}
          defaultCenter={center}
          defaultZoom={zoom}
        >
          <Marker lat={center.lat} lng={center.lng} text="My Marker" />
        </GoogleMapReact>
      </div>
    </div>
  );
};

export default GoogleMap;
