import React from 'react';
import { Avatar, AvatarProps } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { deepOrange } from '@material-ui/core/colors';
import clsx from 'clsx';
interface Props extends AvatarProps {
  userName: string;
  avatarUrl?: string;
  [x: string]: any;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    orange: {
      color: theme.palette.getContrastText(deepOrange[500]),
      backgroundColor: deepOrange[500],
    },
  }),
);

const UserAvatar: React.FC<Props> = ({
  userName,
  avatarUrl,
  className,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <Avatar
      className={clsx(classes.orange, className)}
      alt={userName}
      src={avatarUrl}
      {...rest}
    >
      {!avatarUrl && userName.slice(0, 1)}
    </Avatar>
  );
};

export default UserAvatar;
