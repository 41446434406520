import React from 'react';
import {
  Card,
  IconButton,
  makeStyles,
  createStyles,
  Theme,
  Grid,
  Typography,
  CardActionArea,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import clsx from 'clsx';

import getCardIcon from './getCardIcon';

interface Props {
  brand: string;
  last4: string;
  exp_month: string;
  exp_year: string;
  selected?: boolean;
  handleDelete?: () => any;
  onClick?: () => any;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding:'8px 12px',
      marginBottom: '1rem',
      cursor:'pointer',
      maxWidth: '370px',
      width: '100%',
    },
    icon: {
      width: '50px',
    },
    notBold: {
      fontWeight: 'normal',
    },
    iconWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    hiddenDigits: {
      letterSpacing: '0',
      marginLeft: theme.spacing(1),
    },
    last4: {
      marginLeft: theme.spacing(1),
    },
    date: {
      marginLeft: theme.spacing(3),
    },
    clickable: {},
    selected: {
      color: 'green',
      padding: '0.25rem',
    },
  }),
);
const PaymentCard: React.FC<Props> = ({
  onClick,
  selected,
  brand,
  last4,
  exp_month,
  exp_year,
  handleDelete,
}) => {
  const classes = useStyles();

  const formatMonth = () => {
    const monthStr = String(exp_month);
    if (monthStr.length === 1) return 0 + monthStr;
    else return monthStr;
  };
  const formatYear = () => {
    const yearStr = String(exp_year);
    if (yearStr.length === 4) {
      return yearStr.slice(2);
    } else return yearStr;
  };

  return (
    <Card
      color="primary"
      onClick={onClick}
      variant={selected ? 'elevation' : 'outlined'}
      className={clsx(
        classes.root,
        onClick && classes.clickable,
        selected && classes.selected,
      )}
    >
      <CardActionArea >
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item container xs={8}>
            <div className={classes.iconWrapper}>
              <img
                className={classes.icon}
                src={getCardIcon(brand)}
                alt="card"
              />
            </div>
            <Grid item>
              <Typography
                className={clsx(classes.notBold, classes.hiddenDigits)}
                component="span"
                variant="h5"
              >
                &bull;&bull;&bull;&bull;
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className={clsx(classes.notBold, classes.last4)}
                component="span"
                variant="h6"
              >
                {last4}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className={clsx(classes.notBold, classes.date)}
                component="span"
                variant="h6"
              >
                {formatMonth() + '/' + formatYear()}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            {handleDelete && (
              <IconButton
                onClick={e => {
                  e.stopPropagation();
                  handleDelete();
                }}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
};

export default PaymentCard;
