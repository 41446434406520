import React, { useContext, useEffect, useState } from 'react';
import { useSnackbar, OptionsObject as SnackbarOptions } from 'notistack';
import { UserContext } from '../../context/UserProvider';
import AvatarEditPadge from '../../components/AvatarEditPadge';
import EditAvatarDialog from '../../components/EditAvatarDialog';
import getCroppedImg from '../../components/EditAvatarDialog/utils';
import FirebaseUtil from '../../config/firebase';

const snackbarCommonOpt: SnackbarOptions = {
  autoHideDuration: 3000,
  preventDuplicate: true,
  anchorOrigin: { horizontal: 'right', vertical: 'top' },
};

const EditableAvatar: React.FC = () => {
  const { user } = useContext(UserContext);
  const [showDialog, setShowDialog] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [file, setFile] = useState<any>();
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null);
  const { enqueueSnackbar } = useSnackbar();

  const cropAndUpload = async () => {
    setSubmitting(true);

    const uploadRef = FirebaseUtil.storage
        .ref(`/profilePictures/${user?.id}.jpeg`)

    let cropedImage: any;
    if (croppedAreaPixels) {
      cropedImage = await getCroppedImg(file, croppedAreaPixels);

      //let blob = new Blob([cropedImage], { type:"image/jpeg" });

      await uploadRef.put(cropedImage);
      const url = await uploadRef.getDownloadURL();

      await FirebaseUtil.firestore
          .collection('users')
          .doc((user as any).id)
          .set(
              {
                profilePicture: url,
              },
              {merge: true},
          );
      setSubmitting(false);

      enqueueSnackbar('User Image Updated Successfully', {
        variant: 'success',
        ...snackbarCommonOpt,
      });
      setShowDialog(false);
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {

    const files = e.target.files;

    if (files && files[0]) {
      setFile(URL.createObjectURL(files[0]));
    } else setFile(undefined);
  };

  useEffect(() => {
    if (file) setShowDialog(true);
  }, [file]);

  const removePicture=async()=>{
    await FirebaseUtil.firestore
        .collection('users')
        .doc((user as any).id)
        .set(
            {
              profilePicture: "",
            },
            {merge: true},
        );
  }

  return (
    <>
      <AvatarEditPadge
        file={file}
        onChange={handleFileChange}
        userName={(user as any).name}
        avatarUrl={user?.profilePicture}
        removePicture={removePicture}
      />
      {showDialog && (
        <EditAvatarDialog
          open={showDialog}
          image={file}
          onClose={() => {
            setShowDialog(false)
          }}
          getCropedArea={setCroppedAreaPixels}
          onSubmit={cropAndUpload}
          isSubmitting={isSubmitting}
        />
      )}
    </>
  );
};
export default EditableAvatar;
