import React from 'react';

import {
  Grid,
  makeStyles,
  createStyles,
  Typography,
  CardMedia,
} from '@material-ui/core';

import HealthyImg from '../../../assets/healthy.jpg';
import NutImg from '../../../assets/nut.jpg';
import HeartImg from '../../../assets/Heart.jpg';
import PysicalImg from '../../../assets/physicalHealth.jpg';

import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import articlesText from '../ArticlesPage/text.json';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginTop: '5.1rem',
      height: '100%',
    },
    centerText: {
      textAlign: 'center',
    },
    textWrapper: {
      marginTop: '6.5rem',
      marginBottom: '4rem',
    },
    title: {
      marginBottom: '2rem',
    },
    notBold: {
      fontWeight: 'normal',
    },
    container: {},
  }),
);

const getImage = (section: string) => {
  switch (section) {
    case 'Healthy':
      return HealthyImg;
    case 'Heart':
      return HeartImg;
    case 'Pyisical Activity':
      return PysicalImg;
    case 'Nutrition':
      return NutImg;
    default:
      return NutImg;
  }
};

const ArticlePage: React.FC = () => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const route = pathname.split('/articles')[1];

  let title = '';
  let image: any = '';
  let paragraphArr: string[] = [];

  Object.entries(articlesText).forEach(([key, value]) => {
    value.forEach(
      ({ route: _route, title: _title, paragraphArr: _paragraphArr }) => {
        if (route === _route) {
          title = _title;
          paragraphArr = [..._paragraphArr];
          image = getImage(key);
        }
      },
    );
  });

  return (
    <main className={classes.root}>
      <Grid className={classes.container} container justify="center">
        <Grid item xs={12}>
          <CardMedia component="img" image={image} height="400" />
        </Grid>
        <Grid
          item
          className={classes.textWrapper}
          container
          justify="center"
          xs={8}
        >
          <Grid item xs={12}>
            <Typography
              className={clsx(
                classes.notBold,
                classes.title,
                classes.centerText,
              )}
              component="h3"
              variant="h2"
              color="primary"
            >
              {title}
              Hypertension Is Your Heart’s Enemy: What Doctors Provide
            </Typography>
          </Grid>
          <Grid item container xs={12}>
            {paragraphArr.map((p, index) => (
              <Grid key={index} item xs={12}>
                <Typography
                  key={index}
                  className={classes.notBold}
                  component="p"
                  variant="h6"
                  gutterBottom
                >
                  {p}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </main>
  );
};

export default ArticlePage;
