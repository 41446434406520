import { firestore } from 'firebase/app';

export interface Insurance {
  id: string;
  name: string;
  groupNo: string;
  memberId: string;
  effectiveDate: firestore.Timestamp;
  phone: string;
  responsibleParty: string;
  address1: string;
  address2?: string;
  state: string;
  city: string;
  zipCode: string;
  image:string;
}

export interface Address {
  id: string;
  address1: string;
  address2?: string;
  state: string;
  city: string;
  zipCode: string;
}

export default class User {
  id: string;
  name: string;
  
  email: string;
  profilePicture?: string;
  addresses?: Address[];
  insurances?: Insurance[];

  constructor(
    id: string,
    name: string,
    
    email: string,
    profilePicture?: string,
    addresses?: Address[],
    insurances?: Insurance[],
  ) {
    this.id = id;
    this.name = name;
    
    this.email = email;
    this.addresses = addresses;
    this.insurances = insurances;
    this.profilePicture = profilePicture;
  }
}
