import React from 'react';

import { Chip, ChipProps } from '@material-ui/core';

interface Props extends ChipProps {
  clicked: boolean;
}

const ClickableChip: React.FC<Props> = ({ clicked, ...rest }) => {
  return (
    <Chip
      color="primary"
      clickable
      variant={clicked ? 'default' : 'outlined'}
      {...rest}
    />
  );
};

export default ClickableChip;
