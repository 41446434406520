import React from 'react';

import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: '20vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginLeft: '2%',
      maxWidth: '70%',
      [theme.breakpoints.down('md')]: {
        maxWidth: '90%',
      },
    },
  }),
);

const ArticleLayout: React.FC = ({ children }) => {
  const classes = useStyles();
  return <section className={classes.root}>{children}</section>;
};

export default ArticleLayout;
