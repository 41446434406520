import React from 'react';
import { Checkbox, FormControlLabel, CheckboxProps } from '@material-ui/core';

interface Props extends CheckboxProps {
  label: string;
}

const CheckBoxWithLabel: React.FC<Props> = ({ label,value, ...rest }) => {
  return (
    <FormControlLabel
      label={label}
      control={<Checkbox color="primary" {...rest} />}
    />
  );
};

export default CheckBoxWithLabel;
