import React, { useState, useContext } from 'react';
import { AppBar, IconButton, Typography, Toolbar } from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import MobileDrawer from '../MobileDrawer';
import DawerContent, { drawerWidth } from '../DrawerContent';
import AvatarWithMenu from '../AvatarWithMenu';
import { UserContext } from '../../context/UserProvider';
import * as urlsConstants from '../../containers/UserDashboard/constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'flex-end',
      backgroundColor: '#fff',
      width: `calc(100% - ${drawerWidth}px)`,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    appBarWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    menuIcon: {
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    userControl: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
);

const slicePageName = (pathname: string) => {
  const url = pathname.split('/dashboard')[1];
  switch (url) {
    case urlsConstants.APPOINTMENT:
      return urlsConstants.AppointmentPageName;
    case urlsConstants.NEW_APPOINTMENT:
      return urlsConstants.addAppointmentPageName;
    case urlsConstants.PATIENT:
      return urlsConstants.PatientsPageName;
    case urlsConstants.INSURANCE:
      return urlsConstants.InsurnacesPageName;
    case urlsConstants.PAYMENT:
      return urlsConstants.PaymentPageName;
    default:
      return '';
  }
};

const ApplicationBar: React.FC = () => {
  const { pathname } = useLocation();
  const { user } = useContext(UserContext);

  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const classes = useStyles();

  const handleDrawerClose = () => {
    setShowMobileMenu(false);
  };

  return (
    <>
      <div className={classes.appBarWrapper}>
        <AppBar
          className={classes.root}
          position="static"
          elevation={undefined}
        >
          <Toolbar className={classes.toolbar}>
            <IconButton
              className={classes.menuIcon}
              onClick={() => setShowMobileMenu(c => !c)}
              edge="start"
              color="primary"
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" color="primary">
              {slicePageName(pathname)}
            </Typography>
            <AvatarWithMenu
              userName={(user as any).name}
              avatarUrl={user?.profilePicture}
            />
          </Toolbar>
        </AppBar>
      </div>
      {showMobileMenu && (
        <MobileDrawer open={showMobileMenu} onClose={handleDrawerClose}>
          <DawerContent onClose={handleDrawerClose} />
        </MobileDrawer>
      )}
    </>
  );
};

export default ApplicationBar;
