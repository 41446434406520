import React, { useContext, useState } from 'react';
import { UserContext } from '../../context/UserProvider';
import {
  Typography,
  Grid,
  Button,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import FirebaseUtil,{auth} from '../../config/firebase';
import { CustomInput } from '..';
import DashboardDialog from '../DashboardDialog';
import { useSnackbar, OptionsObject } from 'notistack';

interface Props {
  newEmail?: string;
  handleErrors: (msg: Record<string, any>) => void;
  onClose: any;
}

const useStyles = makeStyles(() =>
  createStyles({
    autoHeight: {
      height: 'auto !important',
    },
    container: {
      marginTop: '3rem',
      marginBottom: '2rem',
    },
    title: {
      textAlign: 'center',
    },
    desc: {
      textAlign: 'center',
    },
  }),
);

const snackbarCommonOpt: OptionsObject = {
  autoHideDuration: 3000,
  preventDuplicate: true,
  anchorOrigin: { horizontal: 'right', vertical: 'top' },
};
const ReauthDialog: React.FC<Props> = ({
  onClose,
  newEmail,
  handleErrors,
}) => {
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const [password, setPassword] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const reAuthinticate = async () => {
    if( password=='') {
      enqueueSnackbar('Please enter your password', {
        variant: 'error',
        ...snackbarCommonOpt,
      });
      return;
    }
    const currentUser = FirebaseUtil.auth.currentUser;

    if(newEmail === currentUser?.email){
      enqueueSnackbar('New email can\'t be the same as the current email', {
        variant: 'error',
        ...snackbarCommonOpt,
      });
      return;
    }
    setIsSubmitting(true);

    const credntial = auth.EmailAuthProvider.credential(
      (user as any).email,
      password,
    );

    try {
      const updatedFields: any = {};

      await currentUser?.reauthenticateWithCredential(credntial);

      if (newEmail) {
        await currentUser?.updateEmail(newEmail);
        updatedFields.email = newEmail;
        await currentUser?.sendEmailVerification();
      }

      await FirebaseUtil.firestore
        .collection('users')
        .doc(user?.id)
        .set(updatedFields, { merge: true });
      setIsSubmitting(false);
      enqueueSnackbar('Email Updated Successfully', {
        variant: 'success',
        ...snackbarCommonOpt,
      });
      onClose();
    } catch (e) {
      setIsSubmitting(false);

      switch (e.code) {
        case 'auth/email-already-in-use':
          return handleErrors({ email: 'Email already in use.' });
        case 'auth/wrong-password':
          enqueueSnackbar('You entered a wrong password', {
            variant: 'error',
            ...snackbarCommonOpt,
          });
          return;
        default:
          return handleErrors({ error: 'Something went wrong' });
      }
    }
  };

  const handlePasswordChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const { value } = e.target;
    setPassword(value);
  };
  return (
    <DashboardDialog
      classes={{
        paperWidthSm: classes.autoHeight,
      }}
      open={true}
      onClose={onClose}
    >
      <Grid
        className={classes.container}
        justify="center"
        container
        spacing={3}
      >
        <Grid item xs={8}>
          <Typography
            className={classes.title}
            color="primary"
            component="p"
            variant="h4"
          >
            Enter Passowrd
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography className={classes.desc} component="p" variant="h6">
            To change your email, please type you password again
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <CustomInput
            fullWidth
            type="password"
            name="password"
            variant="filled"
            label="Password"
            value={password}
            onChange={handlePasswordChange}
          />
        </Grid>
        <Grid item container justify="center" xs={12}>
          <Button
            disabled={isSubmitting}
            onClick={reAuthinticate}
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </DashboardDialog>
  );
};

export default ReauthDialog;
