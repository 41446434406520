import React, { useContext } from 'react';
import { RouteProps, Redirect, Route } from 'react-router-dom';

import { UserContext } from '../../context/UserProvider';

const NoAuthRoute: React.FC<RouteProps> = ({ component, ...rest }) => {
  const { user } = useContext(UserContext);
  if (user) {
    return <Redirect to="/dashboard" />;
  } else return <Route component={component} {...rest} />;
};

export default NoAuthRoute;
