import React from 'react';
import { Grid, Typography, Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

const BlogFooter: React.FC<{ className?: any }> = ({ className }) => {
  return (
    <Grid className={className} container spacing={3}>
      <Grid item xs={12}>
        <Typography component="p" variant="h6">
          Published on KenoshaNews.com{' '}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Link component={RouterLink} to="/urgent-care-vs-emergency-room">
          <Typography component="p" variant="h6">
            Learn When Urgent Care is the Best Option.
          </Typography>
        </Link>
      </Grid>
    </Grid>
  );
};

export default BlogFooter;
