import React from 'react';
import {
  Grid,
  CardMedia,
  Typography,
  makeStyles,
  createStyles,
  Theme,
  Link,
} from '@material-ui/core';
import { Link as RouterLink, useRouteMatch } from 'react-router-dom';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import articlesRefs from './text.json';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: '5.1rem',
    },
    notBold: { fontWeight: 'normal' },
    headTextWrapper: {
      marginTop: theme.spacing(1),
      paddingLeft: theme.spacing(3),
    },
    normalWeight: {
      fontWeight: 'normal',
    },
    accordion: {
      borderRadius: '4px',
      '&$expanded': {
        margin: 'auto',
      },
    },
    accordionSum: {
      background: '#AE3431',
      color: '#fff',
    },
    accordionDetails: {
      paddingTop: '2rem',
      paddingLeft: '2rem',
    },
    accordionsContainer: {
      marginTop: '5.1rem',
      marginBottom: '8rem',
    },
  }),
);

const ArticlesPage: React.FC = () => {
  const classes = useStyles();
  const { url } = useRouteMatch();
  return (
    <main className={classes.root}>
      <section>
        <Grid container>
          <Grid item container alignItems="center" direction="row" xs={12}>
            <Grid
              className={classes.headTextWrapper}
              spacing={1}
              container
              item
              xs={5}
            >
              <Grid item xs={12}>
                <Typography
                  component="h2"
                  variant="h1"
                  color="primary"
                >
                  Articles for Improving
                  <br /> Health & Avoiding Disease
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Typography
                  className={classes.notBold}
                  component="p"
                  variant="h5"
                  color="textSecondary"
                >
                  “A good laugh and a long sleep are the best cures in the
                  doctor’s book.”
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs>
              <CardMedia
                component="img"
                image={require('../../../assets/svg/doctorAndLabtop.svg')}
              />
            </Grid>
          </Grid>
          <Grid
            className={classes.accordionsContainer}
            item
            xs={12}
            container
            justify="center"
          >
            <Grid item container spacing={3} xs={10}>
              {Object.entries(articlesRefs).map(([key, value]) => (
                <Grid item key={key} xs={12}>
                  <Accordion className={classes.accordion}>
                    <AccordionSummary
                      classes={{
                        expanded: classes.accordionSum,
                      }}
                      expandIcon={<ExpandMoreIcon />}
                    >
                      <Typography>{key}</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                      <Grid container>
                          <ul>
                        {value.map(({ route, title }, index) => (
                            <li>
                          <Grid key={index} item xs={12}>
                            <Typography
                              className={classes.notBold}
                              component="span"
                              variant="h6"
                              gutterBottom
                            >
                              <Link
                                component={RouterLink}
                                to={`${url}${route}`}
                              >
                                {title}
                              </Link>
                            </Typography>
                          </Grid>
                            </li>
                        ))}
                          </ul>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </section>
    </main>
  );
};

export default ArticlesPage;
