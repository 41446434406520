import React from 'react';
import { Switch } from 'react-router-dom';
import { WebsiteNav, Footer, LoginForm, SignupForm } from '../../components';
import KnoshoaCorona from './KnoshoaCorona';
import { PublicRoute, NoAuthRoute } from '../../components/Routes';
import Home from './Home';
import NewTelehelthPage from './NewTelehelthPage';
import ServicesPage from './ServicesPage';
import OccupationalPage from './OccupationalPage';
import BillingAndInsurancePage from './BillingAndInsurancePage';
import OurTeamPage from './OurTeam';
import UrgentVsEmergencyPage from './UrgentVsEmergencyPage';
import SpecialOffersPage from './SpecialOffersPage';
import MidAugestOption from './MidAugestOption';
import PageNotFound from '../PageNotFound';
import ContactUs from './ContactPage';
import ArticlesPage from './ArticlesPage';
import ArticlePage from './ArticlePage';
import { useScrollTop } from '../../hooks';

import {
  HOME,
  LOGIN,
  SIGNUP,
  CORONA_UPDATE,
  SERVICES,
  NEW_TELEHELTH_SERVICE,
  OCCUPATIONAL,
  BILLING_AND_INSURANCE,
  OUR_TEAM,
  CONTACT_US,
  URGENT_CARE_VS_EMERGENCY_ROOM,
  SPECIAL_OFFER,
  IN_THE_NEWS,
} from './constants';
import HolidayBanner from '../../components/Banner/HolidayBanner';

const PublicWebsite: React.FC = () => {
  useScrollTop();
  return (
    <>
      <WebsiteNav transparent={false} />
      {/*<HolidayBanner/>*/}
      <div>
        <Switch>
          <PublicRoute exact path={HOME} component={Home} />
          <NoAuthRoute path={LOGIN} component={LoginForm} />
          <NoAuthRoute exact path={SIGNUP} component={SignupForm} />
          <PublicRoute exact path={CORONA_UPDATE} component={KnoshoaCorona} />
          <PublicRoute exact path={NEW_TELEHELTH_SERVICE} component={NewTelehelthPage} />
          <PublicRoute exact path={SERVICES} component={ServicesPage} />
          <PublicRoute exact path={OCCUPATIONAL} component={OccupationalPage} />
          <PublicRoute exact path={BILLING_AND_INSURANCE} component={BillingAndInsurancePage} />
          <PublicRoute exact path={OUR_TEAM} component={OurTeamPage} />
          <PublicRoute exact path={CONTACT_US} component={ContactUs} />
          <PublicRoute
            exact
            path={URGENT_CARE_VS_EMERGENCY_ROOM}
            component={UrgentVsEmergencyPage}
          />
          <PublicRoute exact path={SPECIAL_OFFER} component={SpecialOffersPage} />
          <PublicRoute exact path={IN_THE_NEWS} component={MidAugestOption} />
          <PublicRoute exact path="/articles" component={ArticlesPage} />
          <PublicRoute path="/articles" component={ArticlePage} />
          <PublicRoute path="/" component={PageNotFound} />
        </Switch>
      </div>
      <Footer />
    </>
  );
};

export default PublicWebsite;
