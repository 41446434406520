import { useState, useEffect } from 'react';
import FirebaseUtil from '../config/firebase';
import { User } from '../models';

interface AuthState {
  user: User | undefined;
  authStateLoading: boolean;
}

const useAuth = (): AuthState => {
  const [loading, setLoading] = useState(true);

  const [user, setUser] = useState<User | undefined>(undefined);

  const fetchUserData = async (uid: string): Promise<User> => {
    const userRef = await FirebaseUtil.firestore
      .collection('users')
      .doc(uid)
      .get();

    const { name, email, profilePicture ,addresses} = userRef.data() as any;
    return new User(uid, name, email, profilePicture,addresses);
  };

  useEffect(() => {
    const unsubscribe = FirebaseUtil.auth.onAuthStateChanged(currentUser => {
      setLoading(true);
      if (currentUser) {

        const { uid, emailVerified } = currentUser;
        if (!emailVerified) {

          setLoading(false);
          return;
        }

        (async () => {
          const fetchedUser = await fetchUserData(uid);

          setUser({ ...fetchedUser });
          setLoading(false);
          FirebaseUtil.firestore
            .collection('users')
            .doc(uid)
            .onSnapshot(snapShot => {
              const { name, email, profilePicture } = snapShot.data() as any;
              setUser({
                ...new User(snapShot.id, name, email, profilePicture),
              });
            });
        })();
      } else {
        setUser(undefined);
        setLoading(false);
        FirebaseUtil.signOut();
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return { user, authStateLoading: loading };
};

export default useAuth;
