import React, {useEffect, useState} from 'react';

import { AvatarProps, MenuItem, Menu, Button } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from 'material-ui-popup-state/hooks';
import FirebaseUtil from '../../config/firebase';
import UserAvatar from '../UserAvatar';
interface Props extends AvatarProps {
  userName: string;
  showName?: boolean;
  avatarUrl?: string;
}

const AvatarWithMenu: React.FC<Props> = ({
  children,
  userName,
  avatarUrl,
  showName = true,
  ...rest
}) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const popupState = usePopupState({ variant: 'popover', popupId: 'demoMenu' });
  const [itemName,setItem]=useState("View Dashboard")

  const handleViewProfile = () => {
    popupState.close();
    if (pathname.startsWith('/dashboard')) history.push('/dashboard/setting');
    else history.push('/dashboard');
  };

  useEffect(()=>{
    if (pathname.startsWith('/dashboard'))setItem("Settings")
  },[])

  const handleLogout = () => {
    popupState.close();
    FirebaseUtil.signOut();
  };
  return (
    <>
      <Button
        variant="text"
        startIcon={
          <UserAvatar userName={userName} avatarUrl={avatarUrl}/>
        }
        {...bindTrigger(popupState)}
      >
        {showName && userName}
      </Button>
      <Menu {...bindMenu(popupState)}>
        <MenuItem onClick={handleViewProfile}>{itemName}</MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
};

export default AvatarWithMenu;
