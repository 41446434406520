import React from 'react';
import {
  Grid,
  makeStyles,
  createStyles,
  Typography,
  Theme,
  Container,
} from '@material-ui/core';
import { Helmet } from 'react-helmet';

import GetDirectionsBtn from '../../../components/GetDirectionsBtn';
import jsonObj from './text.json';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: '20vh',
    },
    nested: {
      marginLeft: theme.spacing(1),
    },
  }),
);
const OurTeamPage: React.FC = () => {
  const classes = useStyles();
  return (
    <main className={classes.root}>
      <Helmet>
        <title>Our Team | Kenosha UrgiCare</title>
        <link rel="canonical" href="/meet-our-medical-team" />
      </Helmet>
      <Container maxWidth="xl">
        <Grid container>
          <Grid item container spacing={4} xs={12} md={6}>
            <Grid item xs={12}>
              <Typography color="primary" component="h3" variant="h2">
                MEET OUR MEDICAL TEAM
              </Typography>
            </Grid>
            <Grid item container spacing={2} xs={12}>
              <Grid item xs={12}>
                <Typography color="primary" component="h4" variant="h5">
                  {jsonObj.APNP.title}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography component="p" variant="h6">
                  {jsonObj.APNP.text}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container spacing={2} xs={12}>
              <Grid item xs={12}>
                <Typography color="primary" component="h4" variant="h5">
                  {jsonObj.education.title}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {jsonObj.education.list.map((i, index) => (
                  <Typography
                    className={classes.nested}
                    key={index}
                    component="p"
                    variant="body1"
                  >
                    {i}
                  </Typography>
                ))}
              </Grid>
            </Grid>
            <Grid item container spacing={2} xs={12}>
              <Grid item xs={12}>
                <Typography color="primary" component="h4" variant="h5">
                  {jsonObj.experience.title}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                {jsonObj.experience.list.map((i, index) => (
                  <Typography
                    className={classes.nested}
                    key={index}
                    component="p"
                    variant="body1"
                  >
                    {i}
                  </Typography>
                ))}
              </Grid>
            </Grid>
            <Grid item container spacing={2} xs={12}>
              <Grid item xs={12}>
                <Typography color="primary" component="h4" variant="h5">
                  {jsonObj.inWords.title}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography component="p" variant="h6">
                  {jsonObj.inWords.text}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <GetDirectionsBtn />
            </Grid>
          </Grid>
          <Grid
            item
            container
            justify="flex-end"
            alignItems="flex-start"
            alignContent="flex-start"
            xs={12}
            md={6}
          >
            <Grid item xs={12}>
              <img
                src={require('../../../assets/svg/showcase.svg')}
                width="100%"
                height="auto"
                alt="our team alias"
              />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </main>
  );
};

export default OurTeamPage;
