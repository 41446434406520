import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { OutlinedInputProps } from '@material-ui/core/OutlinedInput';
import { InputProps as _InputProps } from '@material-ui/core/Input';

interface _Props {
  InputProps?: Partial<_InputProps> | Partial<OutlinedInputProps> | undefined;
}

type Props = _Props & TextFieldProps & { [x: string]: any };

export const useStylesReddit = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: '1.25px solid #ccc',
      overflow: 'hidden',
      borderRadius: 4,
      backgroundColor: '#fff',
      height: '54px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '& label.Mui-focused': {
        color: theme.palette.primary.dark,
      },

      '&:hover': {
        backgroundColor: '#fff !important',
      },
      // '&$focused': {
      //   backgroundColor: '#fff',
      //   boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      //   borderColor: theme.palette.primary.main,
      // },
    },
    input: {
      padding: '21px 22px 17px 18px',
      fontFamily: "'Cairo',sans-serif",
      fontWeight: 'bold',
      fontSize: '15px',

    },
  }),
);

const RedditTextField: React.FC<Props> = React.forwardRef(
  ({ InputProps, ...rest }: Props, ref) => {
    const classes = useStylesReddit();

    return (
      <TextField
        inputRef={ref}
        InputProps={
          { classes, disableUnderline: true, ...InputProps } as Partial<
            OutlinedInputProps
          >
        }
        {...rest}
      />
    );
  },
);

export default RedditTextField;
