import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Field, FormikTouched, FormikErrors, FormikValues } from 'formik';

import CustomInput from '../CustomInput';

interface Props {
  values: FormikValues;
  touched: FormikTouched<any>;
  errors: FormikErrors<any>;
  setFieldValue?: any;
}

const useStyles = makeStyles(() =>
  createStyles({
    input: {
      minHeight: '50px',
    },
    MUILimitation: {
      padding: '4px !important',
    },
  }),
);

const FormikAddressForm: React.FC<Props> = ({
  values,
  errors,
  touched,
  setFieldValue,
}) => {
  const classes = useStyles();
  const handleZipChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length <= 5) setFieldValue('zipCode', e.target.value);
  };
  return (
    <div className={classes.MUILimitation}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Field
                fullWidth
                className={classes.input}
                name="address1"
                as={CustomInput}
                label="Address line 1 *"
                variant="filled"
                error={touched.address1 && errors.address1 && true}
                helperText={touched.address1 && errors.address1}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                fullWidth
                className={classes.input}
                name="address2"
                as={CustomInput}
                label="Address line 2"
                variant="filled"
                error={touched.address2 && errors.address2 && true}
                helperText={touched.address2 && errors.address2}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                fullWidth
                className={classes.input}
                name="state"
                as={CustomInput}
                label="State *"
                variant="filled"
                error={touched.state && errors.state && true}
                helperText={touched.state && errors.state}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Field
                fullWidth
                className={classes.input}
                name="city"
                as={CustomInput}
                label="City *"
                variant="filled"
                error={touched.city && errors.city && true}
                helperText={touched.city && errors.city}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                fullWidth
                className={classes.input}
                name="zipCode"
                value={values.zipCode}
                onChange={handleZipChange}
                as={CustomInput}
                label="Zip *"
                variant="filled"
                error={touched.zipCode && errors.zipCode && true}
                helperText={touched.zipCode && errors.zipCode}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default FormikAddressForm;
