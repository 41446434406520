import React from 'react';
import { Dialog, DialogProps } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
interface Props extends DialogProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      left: '240px  !important',
      [theme.breakpoints.down('sm')]: {
        left: '0 !important',
      },
    },
    dialogPaperWidthSm: {
      maxWidth: '800px',
      height: '85vh',
      overflowX: 'hidden',
      minWidth: '70vw',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    backdrop: {
      left: '240px !important',
      [theme.breakpoints.down('sm')]: {
        left: '0 !important',
      },
    },
  }),
);

const DashboardDialog: React.FC<Props> = ({
  children,
  classes: dialogClasses,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Dialog
      {...rest}
      classes={{
        ...dialogClasses,
        root: clsx(classes.root, dialogClasses?.root),
        paperWidthSm: clsx(
          classes.dialogPaperWidthSm,
          dialogClasses?.paperWidthSm,
        ),
      }}
      BackdropProps={{
        classes: { root: classes.backdrop },
      }}
    >
      {children}
    </Dialog>
  );
};

export default DashboardDialog;
