export default (brand: string) => {
  if (brand === 'Visa') {
    return require('../../assets/payment/visa.jpg');
  } else if (brand === 'MasterCard') {
    return require('../../assets/payment/mastercard.jpg');
  } else if (brand === 'JCB') {
    return require('../../assets/payment/jcb.jpg');
  } else if (brand === 'Discover') {
    return require('../../assets/payment/discover.jpg');
  } else if (brand === 'UnionPay') {
    return require('../../assets/payment/unionpay.jpg');
  } else if (brand === 'American Express') {
    return require('../../assets/payment/amex.jpg');
  } else {
    return require('../../assets/payment/generic.jpg');
  }
};
