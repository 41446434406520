import React, { lazy, Suspense } from 'react';
import { Switch, useLocation, Redirect } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/core/styles';

import UserProvider from './context/UserProvider';
import PublicWebsite from './containers/PublicWebsite';
import { ClientDashboardSpinner } from './components';
import { PublicRoute } from './components/Routes';
import theme from './theme';
import FirebaseUtil from './config/firebase';

import './index.css';

const LazyDash = lazy(() => import('./containers/UserDashboard'));

const App: React.FC = () => {
  if (process.env.NODE_ENV !== 'production') {
    (window as any).FirebaseUtil = FirebaseUtil;
    (window as any).moment = moment;
  }

  const { pathname } = useLocation();

  return (
    <div className="App">
      <UserProvider>
        <ThemeProvider theme={theme}>
          <SnackbarProvider maxSnack={1}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Suspense fallback={<ClientDashboardSpinner />}>
                <Switch>
                  <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
                  <PublicRoute path="/dashboard" component={LazyDash} />
                  <PublicRoute path="/" component={PublicWebsite} />
                </Switch>
              </Suspense>
            </MuiPickersUtilsProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </UserProvider>
    </div>
  );
};

export default App;
