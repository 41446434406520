import React from 'react';
import {
  Grid,
  Typography,
  Link,
  makeStyles,
  CardMedia,
  createStyles,
  Theme,
} from '@material-ui/core';
import { BsArrowRight } from 'react-icons/bs';

import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.down('xs')]: {
        marginBottom: '1rem',
      },
    },
    textContainer: {
      padding: '2rem 4rem',
      paddingBottom: '0.5rem',
      [theme.breakpoints.down('sm')]: {
        padding: '1rem 2rem',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '0.5rem 1rem',
      },
    },
    imageGrid: {
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    title: {
      fontWeight: 'bold',
      marginBottom: '3rem',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '1rem',
      },
    },
    descriptionItem: {
      fontWeight: 'normal',
      lineHeight: '1.6',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
      },
    },
    descriptionWrapper: {
      marginBottom: '1rem',
    },
    readMore: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);

interface Props {
  imgLink: any;
  title: string;
  descriptionArr: string[];
  link: string;
  direction?: 'picLeft' | 'pickRight';
}

const Story: React.FC<Props> = ({
  imgLink,
  title,
  descriptionArr,
  link,
  direction,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid
        container
        direction={direction === 'pickRight' ? 'row-reverse' : 'row'}
      >
        <Grid className={classes.imageGrid} item xs={12} md={6}>
          <CardMedia
            component="img"
            alt="Contemplative Reptile"
            height="100%"
            image={imgLink}
            title="Contemplative Reptile"
          />
        </Grid>
        <Grid item xs={12} sm>
          <Grid className={classes.textContainer} container>
            <Grid item xs={12}>
              <Typography
                className={classes.title}
                component="p"
                variant="h4"
                color="primary"
              >
                {title}
              </Typography>
            </Grid>
            <Grid className={classes.descriptionWrapper} item xs={12}>
              {descriptionArr.map((d, index) => (
                <div>
                  <Typography
                  key={index}
                  className={classes.descriptionItem}
                  component="p"
                  variant="h5"
                >
                  {d}
                </Typography>
                  <br/>
                </div>
              ))}
            </Grid>
            <Grid item xs={12}>
              <Link component={RouterLink} to={link}>
                <Typography
                  className={classes.readMore}
                  component="p"
                  variant="h6"
                >
                  Read More <BsArrowRight />
                </Typography>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Story;
