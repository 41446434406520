import * as yup from 'yup';

export const schema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter a valid email')
    .required('Email is a Required Field'),
  password: yup.string().min(6, 'Password must be at least 8 characters').required(),
});


export const emailSchema = yup.object().shape({
  email: yup
      .string()
      .email('Please enter a valid email')
      .required('Email is a Required Field'),

});


