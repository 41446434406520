import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      height: '100vh',
    },

    spinner: {
      display: 'block',
      margin: 'auto',
      width: '180px !important',
      height: 'auto !important',
    },
  }),
);

const ClientDashboardSpinner: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CircularProgress className={classes.spinner} />
    </div>
  );
};

export default ClientDashboardSpinner;
