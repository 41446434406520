import React from 'react';
import {
  makeStyles,
  createStyles,
  Button,
  ButtonProps,
  CircularProgress,
} from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    loader: {
      width: 'initial !important',
      height: 'initial !important',
      marginRight: '6px',
      '& svg': {
        height: '27px !important',
      },
      display:'flex'
    },

  }),
);

type Props = ButtonProps & {
  loading?: boolean;
};

const CustomButton = ({ loading, children, ...restBtnProps }: Props) => {
  const classes = useStyles();
  return (
    <Button {...restBtnProps} >
      <div>{loading && <CircularProgress className={classes.loader} />}</div>
      {children}
    </Button>
  );
};

export default CustomButton;
