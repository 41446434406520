import * as yup from 'yup';
import FirebaseUtil from '../../config/firebase';

const schema = yup.object().shape({
  oldPassword: yup
    .string()
    .min(6,'Please enter your current password.')
    .test(
      'Correct password',
      'Please enter your current password.',
      async (value: string | null | undefined) => {
        if (!value) return false;
        try {
          await FirebaseUtil.reauthUser(value);
          return true;
        } catch (e) {
          return false;
        }
      },
    )
    .required(),
  newPassword: yup.string().min(6,'Password must be at least 6 characters').required("Please enter new password."),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref('newPassword')], 'The passwords your entered do not match')
    .required("Please rewrite new password"),
});

export default schema;