import firebase from 'firebase/app';
import config from './index';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';

class FirebaseUtil {
  firestore: firebase.firestore.Firestore;
  auth: firebase.auth.Auth;
  storage: firebase.storage.Storage;

  constructor() {
    firebase.initializeApp(config.firebase);
    this.firestore = firebase.firestore();
    this.auth = firebase.auth();
    this.storage = firebase.storage();
  }

  signUp = (email: string, password: string) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  signIn = (email: string, password: string) =>
    this.auth.signInWithEmailAndPassword(email, password);

  signOut = () => this.auth.signOut();

  forgotPassword = (email: string) => {
    this.auth.sendPasswordResetEmail(email);
  };

  reauthUser = (password: string) => {
    const currentUser = this.auth.currentUser;
    const cred = firebase.auth.EmailAuthProvider.credential(
      currentUser?.email as string,
      password,
    );
    return currentUser?.reauthenticateWithCredential(cred);
  };

  findUser = (uid: string) => this.firestore.doc(`users/${uid}`);
}
export const firestore = firebase.firestore;
export const auth = firebase.auth;
export default new FirebaseUtil();
