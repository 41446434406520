import React, { useContext } from 'react';
import { RouteProps, Redirect, Route } from 'react-router-dom';

import { UserContext } from '../../context/UserProvider';

const PrivateRoute: React.FC<RouteProps> = ({ component, ...rest }) => {
  const { user } = useContext(UserContext);
  if (user) return <Route component={component} {...rest} />;
  else return <Redirect to="/login" />;
};

export default PrivateRoute;
