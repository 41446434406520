import React from 'react';

import { CircularProgress, CircularProgressProps } from '@material-ui/core';

const TableSpiner: React.FC<CircularProgressProps> = props => {
  return (
    <CircularProgress
      color="primary"
      style={{ display: 'block', margin: 'auto' }}
      {...props}
    />
  );
};

export default TableSpiner;
