const { config } = require(process.env.REACT_APP_ENV
  ? `./${process.env.REACT_APP_ENV}.ts`
  : './development.ts');

/*import { config } from './development';*/

type Config = {
  firebase: object;
  stripeKey: string;
  baseUrl: string;
};

export default config as Config;
