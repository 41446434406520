import React from 'react';
import {
  Typography,
  makeStyles,
  createStyles,
  Theme,
  Grid,
  Container,
} from '@material-ui/core';
import { Helmet } from 'react-helmet';
import GoogleMap from '../../../components/GoogleMap';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: '20vh',
    },
    mainHead: {
      fontWeight: 'normal',
      marginBottom: theme.spacing(2),
    },
    openingTime: {
      marginBottom: '0.25rem',
    },
    bottomMargin: {
      marginBottom: '2rem',
    },
  }),
);

const openingTimes = {
  Monday: '8:00 am - 7:00 pm',
  Tuesday: '8:00 am - 7:00 pm',
  Wednesday: '8:00 am - 7:00 pm',
  Thursday: '8:00 am - 7:00 pm',
  Friday: '8:00 am - 12:00 pm, 2:00 pm - 7:00 pm',
  Saturday: '10:00 am - 3:00 pm',
   Sunday: 'Closed',
};

const ContactUsPage: React.FC = () => {
  const classes = useStyles();
  return (
    <main className={classes.root}>
      <Helmet>
        <link rel="canonical" href="/contact-us" />
        <title>Contact Kenosha Urgicare | Visit Kenosha Urgent Care"</title>
        <meta
          name="description"
          content="Don’t delay getting the help you need. Whether you’re dealing with an injury or illness, need a health or sports physical, or just have questions you can’t answer on your own, Kenosha Urgicare is ready to see you."
        />
      </Helmet>
      <Container maxWidth="xl">
        <Grid
          className={classes.bottomMargin}
          container
          spacing={5}
          justify="space-around"
        >
          <Grid
            item
            container
            justify="space-around"
            spacing={3}
            xs={12}
            md={5}
          >
            <Grid item xs={12}>
              <Typography
                className={classes.mainHead}
                color="primary"
                component="h2"
                variant="h3"
              >
                Come Visit Us at Kenosha Urgicare
              </Typography>
              <Typography component="p" variant="h6">
                Don’t delay getting the help you need. Whether you’re dealing
                with an injury or illness, need a health or sports physical, or
                just have questions you can’t answer on your own, Kenosha
                Urgicare is ready to see you.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography component="p" variant="h6">
              <a href="https://www.google.com/maps/place/6430+Green+Bay+Rd,+Kenosha,+WI+53142,+USA/@42.5761772,-87.8885366,17z/data=!3m1!4b1!4m5!3m4!1s0x880560fa2fd8d38b:0xdcf7bf58faba92b!8m2!3d42.5761733!4d-87.8863479" target="_blanck" style={{ color: "black", textDecoration: "underline" }}> 
                6430 Green Bay Road
                <br />
                Kenosha, WI 53142
                </a>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography component="p" variant="h6">
              <a
            href="tel:2629250535"
            style={{ color: "black", textDecoration: "underline" ,  }}
          >   Phone: 262-925-0535</a>
              
                <br />
                Fax: 262-925-0538
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12} md={5}>
            <Grid item xs={12}>
              <Typography color="primary" component="h4" variant="h4">
                Clinic Hours
              </Typography>
            </Grid>
            <Grid item container spacing={2} xs={12}>
              {Object.entries(openingTimes).map(([key, value]) => (
                <Grid className={classes.openingTime} item container xs={12}>
                  <Grid item xs={6}>
                    <Typography component="p" variant="h6">
                      {key}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    {value.split(',').map(time => (
                      <Typography component="p" variant="h6">
                        {time}
                      </Typography>
                    ))}
                  </Grid>
                </Grid>
              ))}
                <Grid item xs={12}>
                <Typography component="p" variant="h6">
               The last appointment will be 30 minutes prior to closing.
              </Typography>
                </Grid>
            </Grid>
          </Grid>
          <Grid item container justify="center" xs={12}>
            <Grid item xs={8}>
              <GoogleMap />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </main>
  );
};

export default ContactUsPage;
